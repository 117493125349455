import React, { useEffect } from 'react';

const ToastMessage = ({ id, isOpen, message }) => {
	const divId = id ? id : 'toast_message_div';

	useEffect(() => {
		const popupDiv = document.getElementById(divId);
		if (isOpen) {
			popupDiv.style.display = 'flex';
		} else {
			setTimeout(() => {
				popupDiv.style.display = 'none';
			}, 500);
		}
	}, [isOpen])

	return (
		<div id={divId} className={`fixed inset-0 z-[100] hidden items-end justify-center`}>
			<div className={`${isOpen ? 'toast-ease-in ' : 'toast-ease-out '} mb-6 bg-white-500 dark:bg-gray-dark opacity-80 border border-white-gray-400 dark:border-gray max-w-[450px] drop-shadow-xl rounded-lg z-10`}>
				<p className='text-gray-dark dark:text-white text-center text-sm p-3'>{message}</p>
			</div>
		</div>
	)
}

export default ToastMessage;