import { useEffect } from "react";
import Cookies from "js-cookie";
import { useStore } from "./store/AppContext";

const AppWrapper = (props) => {
    const { state, dispatch } = useStore();

    useEffect(() => {
        if (!state.token && Cookies.get('token') && Cookies.get("user")) {
            dispatch({
                type: "LOGIN",
                payload: {
                    token: Cookies.get("token"),
                    user: JSON.parse(Cookies.get("user"))
                }
            })
        }
    }, [])

    return props.children;
}

export default AppWrapper;
