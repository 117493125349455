/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from 'react'
import { InputWrapper } from '../InputWrapper'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import TooltipWrapper from '../TooltipWrapper'
import { useStore } from '../../store/AppContext'
import { CheckBox } from '../CheckBox'
import { useDbSearch } from '../../hooks/useDbSearch'

export const SidePanelDBSearch = ({isOpen, setIsOpen, handleNewChat, setSelectedTable}) => {
    const [sourceList, setSourceList] = useState([])
    const [searchList, setSearchList] = useState([])
    const [sourceTab, setSourceTab] = useState('all');
	const [selectedResourceList, setSelectedResourceList] = useState([]);
    const [searchText, setSearchText] = useState('');
    const dbSearchHook = useDbSearch();
    const { state } = useStore();

    useEffect(() => {
        dbSearchHook.getDbResourcesByUser();
    }, [])

    useEffect(() => {
        if (state.dbResourcesByUser?.length > 0) {
            setSourceList(state.dbResourcesByUser)
        }
    }, [state.dbResourcesByUser])

    const searchFunction = (query, data) => {
        const lowerCaseQuery = query.toLowerCase();
        const searchResults = data.filter(item => {
            return (
                item.database_name.toLowerCase().includes(lowerCaseQuery)
            );
        });

        setSearchList(searchResults);
    };

    useEffect(() => {
        searchFunction(searchText, sourceList)
    }, [searchText])

    const setSelected = (source) => {
		const index = selectedResourceList.indexOf(source);
		if (index > -1) {
			selectedResourceList.splice(index, 1);
		} else
			selectedResourceList.push(source);
		setSelectedResourceList([...selectedResourceList]);
        setSelectedTable(selectedResourceList)
	}

    return (
        <div className={`bg-white dark:bg-gray-dark border-white-gray-dark-500 dark:border-gray-medium text-white ${isOpen ? 'px-4 translate-x-0 border-r-2' : '-translate-x-full border-r-0   px-0 '} transition-all ease-in-out duration-300`}>
            <div className={`${isOpen ? 'block' : 'hidden'}`}>
                <div className={`text-gray-dark dark:text-white`}>
                    <div className='flex items-center mb-3 justify-start'>
                        <button disabled={state.isDBSearchStreaming || state.loading} className={`flex justify-center items-center bg-blue round  border-none text-white hover:text-white hover:bg-blue-400 font-bold px-6 py-2 rounded-lg disabled:bg-gray w-full mr-6`} onClick={handleNewChat}>
                            <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 1.5C6.65625 1.5 4.53125 2.75 3.34375 4.75C2.1875 6.78125 2.1875 9.25 3.34375 11.25C4.53125 13.2812 6.65625 14.5 9 14.5C11.3125 14.5 13.4375 13.2812 14.625 11.25C15.7812 9.25 15.7812 6.78125 14.625 4.75C13.4375 2.75 11.3125 1.5 9 1.5ZM9 16C6.125 16 3.5 14.5 2.0625 12C0.625 9.53125 0.625 6.5 2.0625 4C3.5 1.53125 6.125 0 9 0C11.8438 0 14.4688 1.53125 15.9062 4C17.3438 6.5 17.3438 9.53125 15.9062 12C14.4688 14.5 11.8438 16 9 16ZM8.25 10.75V8.75H6.25C5.8125 8.75 5.5 8.4375 5.5 8C5.5 7.59375 5.8125 7.25 6.25 7.25H8.25V5.25C8.25 4.84375 8.5625 4.5 9 4.5C9.40625 4.5 9.75 4.84375 9.75 5.25V7.25H11.75C12.1562 7.25 12.5 7.59375 12.5 8C12.5 8.4375 12.1562 8.75 11.75 8.75H9.75V10.75C9.75 11.1875 9.40625 11.5 9 11.5C8.5625 11.5 8.25 11.1875 8.25 10.75Z" fill="currentColor" />
                            </svg>
                            <div className='ml-2 text-base font-bold'>
                                {'New Query'}
                            </div>
                        </button>
                        <button className={`text-gray-dark dark:text-white ${!isOpen && 'hidden'} w-6`} onClick={() => setIsOpen(!isOpen)}>
                            <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.75 2H7.875V15.5H15.75C16.3477 15.5 16.875 15.0078 16.875 14.375V3.125C16.875 2.52734 16.3477 2 15.75 2ZM2.25 2C1.61719 2 1.125 2.52734 1.125 3.125V14.375C1.125 15.0078 1.61719 15.5 2.25 15.5H6.75V2H2.25ZM2.25 0.875H15.75C16.9805 0.875 18 1.89453 18 3.125V14.375C18 15.6406 16.9805 16.625 15.75 16.625H2.25C0.984375 16.625 0 15.6406 0 14.375V3.125C0 1.89453 0.984375 0.875 2.25 0.875ZM2.8125 3.125H5.0625C5.34375 3.125 5.625 3.40625 5.625 3.6875C5.625 4.00391 5.34375 4.25 5.0625 4.25H2.8125C2.49609 4.25 2.25 4.00391 2.25 3.6875C2.25 3.40625 2.49609 3.125 2.8125 3.125ZM2.25 5.9375C2.25 5.65625 2.49609 5.375 2.8125 5.375H5.0625C5.34375 5.375 5.625 5.65625 5.625 5.9375C5.625 6.25391 5.34375 6.5 5.0625 6.5H2.8125C2.49609 6.5 2.25 6.25391 2.25 5.9375ZM2.8125 7.625H5.0625C5.34375 7.625 5.625 7.90625 5.625 8.1875C5.625 8.50391 5.34375 8.75 5.0625 8.75H2.8125C2.49609 8.75 2.25 8.50391 2.25 8.1875C2.25 7.90625 2.49609 7.625 2.8125 7.625Z" fill="currentColor" />
                            </svg>
                        </button>
                    </div>
                    <InputWrapper value={searchText} setValue={setSearchText} name={"search"} label={"Search"} rightIcon={<FontAwesomeIcon icon={faSearch} className='text-gray-dark-700 dark:text-white' />} />
                </div>
                <div className="mt-6">
                    <div className='flex justify-start gap-4 pb-2'>
                        <button onClick={() => setSourceTab("all")} className={`border-b pb-1 text-sm hover:text-gray-700 hover:border-gray-700 ${sourceTab === 'all' ? "text-gray-dark dark:text-white border-gray-dark dark:border-white" : "text-white-gray-300 dark:text-gray border-b-0"}`}>
                            All Databases
                        </button>
                    </div>
                    <nav id={'doc_search_sources_view'} className='w-[254px] relative side-bar-source-container overflow-y-auto'>
                        {sourceTab === 'all' && searchText.length > 0 ?
                            searchList && searchList.length > 0 && searchList.map((source, key) => {
                                return (
                                    source.status === 'Completed' && // source.integration === state.selectedIntegration['DB_SEARCH'].id &&  
                                    <TooltipWrapper key={key} content={source.database_name} className={'w-max'} tootTipStyle={'flex w-[240px] z-50 overflow-x-auto'}>
                                        <CheckBox 
                                            key = {key}
                                            setValue={() => setSelected(source)}
                                            value={selectedResourceList.includes(source)}
                                            disabled={state.isDBSearchStreaming} 
                                            label={source.database_name} 
                                            className='flex-row w-[240px] py-2 px-2 font-light overflow-hidden text-ellipsis text-nowrap outline-none rounded-lg text-left text-sm text-gray-dark dark:text-white hover:bg-white-gray-500 hover:dark:bg-gray-black' 
                                        />
                                    </TooltipWrapper>
                                )
                            })
                            :
                            (sourceList && sourceList.length > 0 ?
                                sourceList.map((source, key) => {
                                    return (
                                        source.status === 'Completed' && // source.integration === state.selectedIntegration['DB_SEARCH'].id &&  
                                        <TooltipWrapper key = {key} content={source.database_name} className={'w-max'} tootTipStyle={'flex w-[240px] z-50 overflow-x-auto'}>
                                            <CheckBox 
                                                key = {key}
                                                setValue={() => setSelected(source)}
                                                value={selectedResourceList.includes(source)}
                                                disabled={state.isDBSearchStreaming} 
                                                label={source.database_name} 
                                                className='flex-row w-[240px] py-2 px-2 font-light overflow-hidden text-ellipsis text-nowrap outline-none rounded-lg text-left text-sm text-gray-dark dark:text-white hover:bg-white-gray-500 hover:dark:bg-gray-black' 
                                            />
                                        </TooltipWrapper>
                                    )
                                })
                                :
                                <p className="w-[240px] h-[95%] text-gray-dark dark:text-white flex justify-center mt-4">Sources are not uploaded</p>
                            )
                        }
                    </nav>
                </div>
            </div>
        </div>
    )
}

