import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { InputWrapper2 } from '../InputWrapper';
import { useStore } from '../../store/AppContext';
import { useConfig } from '../../hooks/useConfig';
import { Button } from '../Button';
import { ROUTES_ID } from '../../config';

const InstructionsModal = ({ isOpen, onClose }) => {
	const PDFchatContainerRef = useRef(null);
	const configHook = useConfig();
	const { state } = useStore()
	const [docSearchConfig, setDocSearchConfig] = useState(state.docSearchConfig ? state.docSearchConfig : {});
	const [editState, setEditState] = useState(false);

	useEffect(() => {
		configHook.getConfig(ROUTES_ID.DOCUMENT_SEARCH);
	}, [])

	useEffect(() => {
		const popupDiv = document.getElementById('instructions_popup_div');
		if (isOpen) {
			popupDiv.style.display = 'flex';
		} else {
			setTimeout(() => {
				popupDiv.style.display = 'none';
			}, 500);
		}
	}, [isOpen])

	useEffect(() => {
		if (state.docSearchConfig && Object.keys(state.docSearchConfig).length > 0) {
			setDocSearchConfig(state.docSearchConfig);
		}
	}, [state.docSearchConfig])

	const handleClose = () => {
		onClose();
		setEditState(false);
	}

	const onInstrustionsChange = (e) => {
		setDocSearchConfig((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
	}

	const handleSave = () => {
		handleClose();
		configHook.updateConfig(ROUTES_ID.DOCUMENT_SEARCH, docSearchConfig, true);
	}

	const formatMessageText = (text) => {
		let outputString = text;

		return outputString.split('\n').map((line, index) => (
			<React.Fragment key={index}>
				<li>{line}</li>
				<br />
			</React.Fragment>
		))
	}

	return (
		<div id={'instructions_popup_div'} className={`fixed inset-0 z-50 hidden items-center justify-center`}>
			<div className={`fixed inset-0 bg-white dark:bg-black ${isOpen ? 'popup-ease-in-opacity ' : 'popup-ease-out-opacity '} opacity-50`} onClick={handleClose} />
			<div className={`${isOpen ? 'popup-ease-in ' : 'popup-ease-out '} bg-white dark:bg-gray-dark border-2 border-white-gray-400 dark:border-gray w-1/3 drop-shadow-xl p-6 rounded-lg z-10`}>
				<button className="text-gray-dark dark:text-white absolute top-2 mt-4" onClick={handleClose}>
					<FontAwesomeIcon icon={faClose} />
				</button>
				<h1 className='text-gray-dark dark:text-white text-center font-semibold text-lg'>Instructions</h1>
				<div ref={PDFchatContainerRef} className='text-gray-dark dark:text-white font-light overflow-y-auto'>
					{!editState ?
						<p className='p-4 text-sm font-light '>
							{docSearchConfig.instructions ? formatMessageText(docSearchConfig.instructions) : 'Instructions are not configured yet. To update the instructions click "Edit" button.'}
						</p>
						:
						<form onChange={onInstrustionsChange} className='p-4'>
							<InputWrapper2 value={docSearchConfig.instructions} name={"instructions"} type={"textarea"} placeholder={`Enter your instructions here... \nEx: Please extract policy_ID from table_name if it matches the {email_id} of the tables email_id\nPrint "User Policy:" + policy_ID\nExtract answers from API given the following parameters [param1, param2]\nPrint param1 param2, {response}\nLook up information from {Upload PDF} on Policy_ID`} rows={10} />
						</form>
					}
				</div>
				<Button handleFunction={() => { setEditState(!editState) }} type={editState ? 'outline' : ""} className={`ml-2 my-2 py-[10px]`} name={!editState ? "Edit" : "Cancel"} />
				{editState && <Button handleFunction={handleSave} className={'ml-2 my-2 py-[10px]'} name={"Save"} />}
			</div>
		</div>
	)
}

export default InstructionsModal;
