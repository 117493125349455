import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const DropdownWrapper = ({ setValue, value, label, name, options, placeholder }) => {
	const dropdownRef = useRef(null);
	const dropdownOptionsRef = useRef(null);
	const [showOptions, setShowOptions] = useState(false);

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target) && dropdownOptionsRef.current && !dropdownOptionsRef.current.contains(event.target)) {
				setShowOptions(false);
			} 
		}

		document.addEventListener("mousedown", handleClickOutside);
		return () => document.removeEventListener("mousedown", handleClickOutside);
	}, [dropdownRef, dropdownOptionsRef])

	const onDropdownClick = () => {
		setShowOptions(prev => !prev);
	}

	const onDropdownSelect = (option) => {
		setValue && setValue(option); 
		setShowOptions(false)
	}

	return (
		<div className="mb-4 block relative">
			<label className="block text-gray-dark dark:text-white mb-4 text-lg font-bold " htmlFor="textarea">
				{label}
			</label>
			<div ref={dropdownRef} name={name} onClick={onDropdownClick} className={`w-full flex flex-row items-center border p-[14px] py-[10px] cursor-pointer rounded-lg focus:outline-none ${value ? 'text-gray-dark-500 dark:text-white-800' : 'text-gray-400'} border-gray-700 dark:border-gray border-1 bg-white dark:bg-gray-dark hover:border-gray-light-700 focus:border-blue-dark focus:dark:border-blue`}>
				<FontAwesomeIcon className="text-base font-normal mr-2" icon={faChevronDown} />
				<p className="text-base font-normal">{value ? value : placeholder}</p>
			</div>
			{showOptions &&
				<div ref={dropdownOptionsRef} className={`block w-full max-h-60 p-3 z-10 absolute rounded-xl bg-white-gray-500 dark:bg-gray-black hide-scrollbar overflow-auto`}>
					{options.map((option, index) => {
						return <div id={option.id} onClick={() => onDropdownSelect(option)}  className={`text-gray-dark dark:text-white w-full flex cursor-pointer justify-start ${(index < options.length - 1) ? '' : ''} p-2 hover:bg-white-gray-900 hover:dark:bg-gray-200`} key={index} value={option.label}>{option.label}</div>
					})}
				</div>
			}
		</div>
	)
}

export default DropdownWrapper;
