import React, { useState } from 'react';
import { useStore } from '../../store/AppContext';
import { useIntegration } from '../../hooks/useIntegration';

const DEFAULT_LOGO = <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
	<g clipPath="url(#clip0_3203_77785)">
		<path d="M29.8965 59.5862C46.4069 59.5862 59.7931 46.2414 59.7931 29.7931C59.7931 13.3448 46.3862 0 29.8965 0C13.4069 0 0 13.3448 0 29.7931C0 46.2414 13.3862 59.5862 29.8965 59.5862Z" fill="#2CA01C" />
		<path d="M19.9242 18.2069C13.5104 18.2069 8.29663 23.4 8.29663 29.7931C8.29663 36.1862 13.4897 41.3793 19.9242 41.3793H21.5794V37.0759H19.9242C15.8897 37.0759 12.6208 33.8069 12.6208 29.7931C12.6208 25.7793 15.8897 22.5104 19.9242 22.5104H23.9173V45.0207C23.9173 47.4 25.8415 49.3242 28.2415 49.3242V18.2069H19.9242ZM39.8484 41.3793C46.2621 41.3793 51.4759 36.1862 51.4759 29.7931C51.4759 23.4 46.2828 18.2069 39.8484 18.2069H38.1932V22.5104H39.8484C43.8828 22.5104 47.1518 25.7793 47.1518 29.7931C47.1518 33.8069 43.8828 37.0759 39.8484 37.0759H35.8552V14.5655C35.8552 12.1862 33.9311 10.2621 31.5311 10.2621V41.3793H39.8484Z" fill="white" />
	</g>
	<defs>
		<clipPath id="clip0_3203_77785">
			<rect width="60" height="60" fill="white" />
		</clipPath>
	</defs>
</svg>;

const COMING_SOON_LOGO = <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
	<g clipPath="url(#clip0_3203_77788)">
		<path d="M29.8965 59.5862C46.4069 59.5862 59.7931 46.2414 59.7931 29.7931C59.7931 13.3448 46.3862 0 29.8965 0C13.4069 0 0 13.3448 0 29.7931C0 46.2414 13.3862 59.5862 29.8965 59.5862Z" fill="#B7C2D2" />
		<path d="M19.9242 18.2069C13.5104 18.2069 8.29663 23.4 8.29663 29.7931C8.29663 36.1862 13.4897 41.3793 19.9242 41.3793H21.5794V37.0759H19.9242C15.8897 37.0759 12.6208 33.8069 12.6208 29.7931C12.6208 25.7793 15.8897 22.5104 19.9242 22.5104H23.9173V45.0207C23.9173 47.4 25.8415 49.3242 28.2415 49.3242V18.2069H19.9242ZM39.8484 41.3793C46.2621 41.3793 51.4759 36.1862 51.4759 29.7931C51.4759 23.4 46.2828 18.2069 39.8484 18.2069H38.1932V22.5104H39.8484C43.8828 22.5104 47.1518 25.7793 47.1518 29.7931C47.1518 33.8069 43.8828 37.0759 39.8484 37.0759H35.8552V14.5655C35.8552 12.1862 33.9311 10.2621 31.5311 10.2621V41.3793H39.8484Z" fill="white" />
	</g>
	<defs>
		<clipPath id="clip0_3203_77788">
			<rect width="60" height="60" fill="white" />
		</clipPath>
	</defs>
</svg>;

const Quickbooks = ({ comingSoon }) => {
	const integrationHook = useIntegration();
	const { state } = useStore();
	const [enableRevokeIcon, setEnableRevokeIcon] = useState(false);

	const handleRevoke = () => {
		integrationHook.revokeIntegrationData('quickbooks', setEnableRevokeIcon);
	}

	return (
		<div>
			<button disabled={comingSoon} className={`flex flex-col relative justify-center items-center w-[150px] h-[150px] rounded-xl px-2 py-5 gap-5 ${comingSoon ? 'bg-white-gray dark:bg-gray-light-200' : 'bg-white-gray-600 dark:bg-black'}`}>
				{state.config && state.config.integrations && state.config.integrations['quickbooks'] &&
					<div className='w-[150px] h-[150px] absolute flex justify-end items-start pr-2 pt-2'>
						<div className='dark:text-gray-dark text-white' onClick={() => enableRevokeIcon && handleRevoke()} onMouseOver={() => setEnableRevokeIcon(true)} onMouseLeave={() => setEnableRevokeIcon(false)}>
							{enableRevokeIcon ?
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="-4 4 48 48">
									<path fill="#e75670" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path>
									<path fill="currentColor" d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z"></path>
									<path fill="currentColor" d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z"></path>
								</svg>
								:
								<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<rect width="20" height="20" rx="10" fill="#2BB86C" />
									<path d="M15.0859 6.91406C15.2969 7.14844 15.2969 7.5 15.0859 7.71094L8.89844 13.8984C8.66406 14.1328 8.3125 14.1328 8.10156 13.8984L4.91406 10.7109C4.67969 10.5 4.67969 10.1484 4.91406 9.9375C5.125 9.70312 5.47656 9.70312 5.6875 9.9375L8.47656 12.7266L14.2891 6.91406C14.5 6.70312 14.8516 6.70312 15.0625 6.91406H15.0859Z" fill="currentColor" />
								</svg>
							}
						</div>
					</div>
				}
				{comingSoon ? COMING_SOON_LOGO : DEFAULT_LOGO}
				<div className='flex flex-col justify-center items-center'>
					<p className={`${comingSoon ? 'text-gray-light' : 'text-gray-dark dark:text-white'} font-medium`}>Quickbooks</p>
					{comingSoon && <p className='text-gray-dark dark:text-white text-xs font-normal mt-1'>Coming Soon</p>}
				</div>
			</button>
		</div>
	)
}

export default Quickbooks;
