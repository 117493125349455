import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { useStore } from '../store/AppContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faMicrophone } from "@fortawesome/free-solid-svg-icons";
import { useSpeechRecognition } from "react-speech-kit";

export const InputWrapper = ({ setValue, value, label, name, type, disabled, containerStyle, rightIcon, onEnterKey, autoComplete }) => {
	const { state } = useStore();
	const [isFocused, setIsFocused] = useState(false);

	const handleFocus = () => {
		setIsFocused(true);
	}

	const handleBlur = () => {
		!value && setIsFocused(false);
	}

	return (
		<div className={`flex flex-row input-container ${isFocused || value ? 'focused' : ''} ${containerStyle}`}>
			<label htmlFor={name} className={`block bg-none text-gray-600  ${isFocused || value ? 'bg-white dark:bg-gray-dark' : ''}`}>
				{label}
			</label>
			<input
				autoComplete={autoComplete ? autoComplete : "off"}
				onFocus={handleFocus}
				onBlur={handleBlur}
				disabled={disabled}
				type={type}
				id={name}
				name={name}
				value={value}
				style={{
					WebkitBoxShadow: state.isDarkMode ? '0 0 0 30px #242b33 inset' : '0 0 0 30px #ffffff inset',
					WebkitTextFillColor: state.isDarkMode ? 'rgb(255, 255, 255, var(--tw-text-opacity))' : 'rgb(36 43 51 / var(--tw-text-opacity))',
				}}
				onChange={(e) => setValue && setValue(e.target.value)}
				onKeyDown={onEnterKey}
				className="w-full dark:border-2 border px-[14px] py-[10px] rounded-lg focus:outline-none text-gray-dark dark:text-white-800 border-white-gray-dark-400 dark:border-gray border-1 bg-white dark:bg-gray-dark hover:border-gray-light-700 focus:border-blue-dark focus:dark:border-blue disabled:border-gray-light-600 disabled:pointer-events-none"
			/>
			{rightIcon &&
				<div className='absolute right-3 flex self-center'>
					{rightIcon}
				</div>
			}
		</div>
	)
}

export const TextArea = ({ label, name, className, placeholder, value, onChange, clearButton, rows }) => {
	return (
		<div className='relative' >
			<label className="block text-gray-dark dark:text-white mb-4 text-lg font-bold" htmlFor={name}>
				<div className='flex'>
					{label}
					{clearButton &&
						<div className='ml-8 cursor-pointer text-sm mt-1 text-blue-dark dark:text-blue' onClick={clearButton}>Clear Text</div>
					}
				</div>
			</label>
			<textarea
				id={name}
				name={name}
				className={`w-full p-4 overflow-auto rounded-lg focus:outline-none text-gray-dark dark:text-white-800 border-gray-800 dark:border-gray-500 border-2 bg-white dark:bg-gray-dark focus:border-blue-dark focus:dark:border-blue-300 ${className}`}
				placeholder={placeholder}
				value={value}
				onChange={onChange}
				rows={rows}
			/>
		</div>
	)
}

export const InputWrapper2 = ({ setValue, value, label, name, type, placeholder, rows, rightIcon }) => {
	const { state } = useStore();
	const [isFocused, setIsFocused] = useState(false);

	const handleFocus = () => {
		setIsFocused(true);
	}

	const handleBlur = () => {
		!value && setIsFocused(false);
	}

	return (
		<div className={`flex flex-row input-container ${isFocused ? 'focused' : ''}`}>
			<label htmlFor={name} className="block text-gray-600 mb-2">
				{label}
			</label>
			{type === 'textarea' ?
				<textarea
					onFocus={handleFocus}
					onBlur={handleBlur}
					rows={rows}
					id={name}
					name={name}
					value={value}
					onChange={(e) => setValue && setValue(e.target.value)}
					className="w-full border p-2 rounded focus:outline-none text-gray-dark-200 dark:text-white-800 border-gray border-1 bg-white dark:bg-gray-dark focus:border-blue-dark-300 focus:dark:border-blue-300"
					placeholder={placeholder || `Enter your ${label}`} />
				:
				<div className='flex flex-row'>
					<input
						onFocus={handleFocus}
						onBlur={handleBlur}
						type={type}
						id={name}
						name={name}
						value={value}
						style={{
							WebkitBoxShadow: state.isDarkMode ? '0 0 0 30px #242b33 inset' : '0 0 0 30px #f3f6f9 inset',
							WebkitTextFillColor: state.isDarkMode ? 'rgb(255, 255, 255, var(--tw-text-opacity))' : 'rgb(36 43 51 / var(--tw-text-opacity))',
						}}
						onChange={(e) => setValue && setValue(e.target.value)}
						className={`w-full border p-4 rounded-xl focus:outline-none text-white-800 border-gray border-1 bg-gray focus:ring focus:border-blue-300 ${rightIcon ? 'pr-10' : ''}`}
						placeholder={placeholder || `Enter your ${label}`} />
					{rightIcon &&
						<button className='absolute right-3 flex self-center'>
							{/*{rightIcon}*/}
						</button>
					}
				</div>
			}
		</div>
	)
}

export const ChatInput = ({ name, type, placeholder, disabled, containerStyle, handleSend, userPrompt, onClose }) => {
	const [message, setMessage] = useState('');
	const [textareaHeight, setTextareaHeight] = useState(24);
	const maxTextareaHeight = 120;
	const { state } = useStore();

	useEffect(() => {
		userPrompt && setMessage(userPrompt)
	},[userPrompt])

	const { listen, listening, stop } = useSpeechRecognition({
		onResult: (result) => {
			setMessage(result);
		},
	});

	const handleMessageChange = (e) => {
		setMessage(e.target.value);
		const newHeight = Math.min(e.target.scrollHeight, maxTextareaHeight);
		setTextareaHeight(newHeight);
	}

	const handleSendMessage = () => {
		let user = JSON.parse(Cookies.get('user'));
		const newChat = {
			text: message,
			user_id: user.id,
			user_name: user.username,
			conversation_id: state.docSearchCurrentConversation != null ? state.docSearchCurrentConversation : null,
			chat_id: 1
		};
		handleSend(newChat);
		setMessage('');
		setTextareaHeight(24);
		stop();
	}

	const handleKeyDown = (e) => {
		if ((e.key === 'Enter') && !e.shiftKey) {
			e.preventDefault();
			!disabled && message && handleSendMessage();
		}
	}

	const onClickMic = () => {
		listening ? stop() : listen()
	}

	return (
		<div className={`flex relative flex-row w-full border px-[14px] bg-white dark:bg-gray-dark py-[10px] border-gray-700 dark:border-gray rounded-lg focus:outline-none text-gray-dark-500 dark:text-white-800 hover:border-gray-light-700 focus:border-blue disabled:border-gray-light-600 disabled:pointer-events-none ${containerStyle}`}>
			<textarea
				type={type}
				id={name}
				name={name}
				placeholder={placeholder}
				className="w-full resize-none h-6 bg-white dark:bg-gray-dark outline-none pr-8 overflow-y-auto"
				value={message}
				onChange={handleMessageChange}
				onKeyDown={handleKeyDown}
				style={{ height: `${textareaHeight}px` }}
			/>
			<button disabled={disabled} onClick={message ? handleSendMessage : onClickMic} className='absolute right-4 flex self-center cursor-pointer disabled:cursor-default'>
				{message ?
					<img src={disabled ? "/assets/images/chevron-down.svg" : "/assets/images/chevron-down-blue.svg"} alt="" />
					:
					<FontAwesomeIcon icon={faMicrophone} className={`${disabled ? "text-gray-light" : (listening ? "text-blue dark:text-blue" : "text-gray-dark dark:text-white")} w-4 h-4`} />
				}
			</button>
			{userPrompt && <FontAwesomeIcon icon={faClose} className = "absolute right-10 flex self-center text-gray-dark dark:text-white cursor-pointer w-4 h-4" onClick={onClose}/>}
		</div>
	)
}
