import React, { useState } from 'react';
import { useStore } from '../../store/AppContext';
import { useIntegration } from '../../hooks/useIntegration';

const DEFAULT_LOGO = <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M19.3923 39.2307H14.2692C6.53846 39.2307 1 34.5 1 27.5769H28.5308C29.9615 27.5769 30.8846 28.5923 30.8846 30.023V57.7384C24.0077 57.7384 19.3923 52.1538 19.3923 44.4V39.2538V39.2307ZM32.9846 25.4769H27.8615C20.1308 25.4769 14.5923 20.8384 14.5923 13.8923H42.1231C43.5538 13.8923 44.5692 14.8154 44.5692 16.2461V43.9615C37.6923 43.9615 32.9846 38.3769 32.9846 30.623V25.4769ZM46.6692 11.7923H41.5462C33.8154 11.7923 28.2769 7.0615 28.2769 0.138428H55.8077C57.2385 0.138428 58.1615 1.15381 58.1615 2.51535V30.2307C51.2846 30.2307 46.6692 24.6461 46.6692 16.8923V11.8154V11.7923Z" fill="#357DE8" />
</svg>;

const COMING_SOON_LOGO = <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M19.3923 39.2307H14.2692C6.53846 39.2307 1 34.5 1 27.5769H28.5308C29.9615 27.5769 30.8846 28.5923 30.8846 30.023V57.7384C24.0077 57.7384 19.3923 52.1538 19.3923 44.4V39.2538V39.2307ZM32.9846 25.4769H27.8615C20.1308 25.4769 14.5923 20.8384 14.5923 13.8923H42.1231C43.5538 13.8923 44.5692 14.8154 44.5692 16.2461V43.9615C37.6923 43.9615 32.9846 38.3769 32.9846 30.623V25.4769ZM46.6692 11.7923H41.5462C33.8154 11.7923 28.2769 7.0615 28.2769 0.138428H55.8077C57.2385 0.138428 58.1615 1.15381 58.1615 2.51535V30.2307C51.2846 30.2307 46.6692 24.6461 46.6692 16.8923V11.8154V11.7923Z" fill="#B7C2D2" />
</svg>;

const Jira = ({ comingSoon }) => {
	const integrationHook = useIntegration();
	const { state } = useStore();
	const [enableRevokeIcon, setEnableRevokeIcon] = useState(false);

	const handleRevoke = () => {
		integrationHook.revokeIntegrationData('jira', setEnableRevokeIcon);
	}

	return (
		<div>
			<button disabled={comingSoon} className={`flex flex-col relative justify-center items-center w-[150px] h-[150px] rounded-xl px-2 py-5 gap-5 ${comingSoon ? 'bg-white-gray dark:bg-gray-light-200' : 'bg-white-gray-600 dark:bg-black'}`}>
				{state.config && state.config.integrations && state.config.integrations['jira'] &&
					<div className='w-[150px] h-[150px] absolute flex justify-end items-start pr-2 pt-2'>
						<div className='dark:text-gray-dark text-white' onClick={() => enableRevokeIcon && handleRevoke()} onMouseOver={() => setEnableRevokeIcon(true)} onMouseLeave={() => setEnableRevokeIcon(false)}>
							{enableRevokeIcon ?
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="-4 4 48 48">
									<path fill="#e75670" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path>
									<path fill="currentColor" d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z"></path>
									<path fill="currentColor" d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z"></path>
								</svg>
								:
								<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<rect width="20" height="20" rx="10" fill="#2BB86C" />
									<path d="M15.0859 6.91406C15.2969 7.14844 15.2969 7.5 15.0859 7.71094L8.89844 13.8984C8.66406 14.1328 8.3125 14.1328 8.10156 13.8984L4.91406 10.7109C4.67969 10.5 4.67969 10.1484 4.91406 9.9375C5.125 9.70312 5.47656 9.70312 5.6875 9.9375L8.47656 12.7266L14.2891 6.91406C14.5 6.70312 14.8516 6.70312 15.0625 6.91406H15.0859Z" fill="currentColor" />
								</svg>
							}
						</div>
					</div>
				}
				{comingSoon ? COMING_SOON_LOGO : DEFAULT_LOGO}
				<div className='flex flex-col justify-center items-center'>
					<p className={`${comingSoon ? 'text-gray-light' : 'text-gray-dark dark:text-white'} font-medium`}>Jira</p>
					{comingSoon && <p className='text-gray-dark dark:text-white text-xs font-normal mt-1'>Coming Soon</p>}
				</div>
			</button>
		</div>
	)
}

export default Jira;
