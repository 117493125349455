import axios from "axios";
import Cookies from "js-cookie";
import { API_URL, API_KEY } from "../config";
import { useStore } from '../store/AppContext';

export const useUserGroup = () => {
    const { dispatch } = useStore();
    return {
        getUserGroupList: (pageNum, pageSize) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })
            axios
                .get(`${API_URL}/user-groups?type=pagination&pageNum=${pageNum}&pageSize=${pageSize}`, {
                    headers: {
                        Authorization: Cookies.get("token"),
                        apikey: API_KEY,
                    },
                })
                .then((res) => {
                    console.log(res.data)
                    dispatch({
                        type: "SET_USER_GROUP_INFO",
                        payload: res.data,
                    })
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Data fetch Failed",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                })
        },
        getAllUserGroupsWithUsersList: () => {
            dispatch({
                type: "LOADING",
                payload: true,
            })
            axios
                .get(`${API_URL}/user-groups-with-users`, {
                    headers: {
                        Authorization: Cookies.get("token"),
                        apikey: API_KEY,
                    },
                })
                .then((res) => {
                    dispatch({
                        type: "SET_USER_GROUP_LIST",
                        payload: res.data,
                    })
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Data fetch Failed",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                })
        }
    }
}
