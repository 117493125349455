import axios from "axios";
import Cookies from "js-cookie";
import { API_URL, API_KEY } from "../config";
import { useStore } from '../store/AppContext';

export const useUser = () => {
    const { dispatch } = useStore();
    return {
        getUser: (username) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })
            axios
                .get(`${API_URL}/user/${username}`, {
                    headers: {
                        Authorization: Cookies.get("token"),
                        apikey: API_KEY,
                    },
                })
                .then((res) => {
                    dispatch({
                        type: "SET_USER",
                        payload: res.data,
                    })
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Data fetch Failed",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                })
        },
        updateUser: (userInfo, handleClose) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })
            axios
                .post(`${API_URL}/user/update`,
                    { userInfo: userInfo },
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        },
                    })
                .then((res) => {
                    Cookies.set("user", JSON.stringify(res.data));
                    dispatch({
                        type: "SET_USER",
                        payload: res.data,
                    })
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "success",
                            title: "Update Successfully",
                            subtitle: "Your profile have been uploaded successfully"
                        }
                    })
                    handleClose && handleClose();
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Update Failed",
                            subtitle: err && err.response && err.response.data && err.response.data.message ? err.response.data.message : 'Please try again!'
                        }
                    })
                })
        },
        uploadProfileImage: (imageData, handleClose) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })
            axios
                .post(`${API_URL}/user/upload_profile_image`,
                    imageData,
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                            'Content-Type': 'multipart/form-data',
                        },
                    })
                .then((res) => {
                    Cookies.set("user", JSON.stringify(res.data));
                    dispatch({
                        type: "SET_USER",
                        payload: res.data,
                    })
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    handleClose && handleClose();
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Upload Failed",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                })
        },
        removeProfileImage: (handleClose) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })
            axios
                .post(`${API_URL}/user/remove_profile_image`,
                    {},
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        },
                    })
                .then((res) => {
                    Cookies.set("user", JSON.stringify(res.data));
                    dispatch({
                        type: "SET_USER",
                        payload: res.data,
                    })
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    handleClose && handleClose();
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Remove Failed",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                })
        },
        updateUserConfig: (config, configKey, configValue, navigate = null, navLink = null) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })
            axios
                .post(`${API_URL}/user/update_user_config`,
                    { userConfig: config },
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        },
                    })
                .then((res) => {
                    dispatch({
                        type: `SET_${configKey}_CONFIG`,
                        payload: configValue,
                    })
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "success",
                            title: "Update Successfully",
                            subtitle: "Your profile have been uploaded successfully"
                        }
                    })
                    navLink && navigate(navLink);
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Update Failed",
                            subtitle: err && err.response && err.response.data && err.response.data.message ? err.response.data.message : 'Please try again!'
                        }
                    })
                })
        },
        getUserConfig: () => {
            dispatch({
                type: "LOADING",
                payload: true,
            })
            axios
                .get(`${API_URL}/users/get_user_config`,
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        },
                    })
                .then((res) => {
                    dispatch({
                        type: `SET_USER_CONFIG`,
                        payload: res.data && res.data.user_config ? res.data.user_config : {}
                    })
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Data fetch Failed",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                })
        },
        getAllUsers: (pageNum, pageSize) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })
            axios
                .get(`${API_URL}/users?pageNum=${pageNum}&pageSize=${pageSize}`,
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        },
                    })
                .then((res) => {
                    dispatch({
                        type: `GET_ALL_USERS`,
                        payload: res.data
                    })
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Data fetch Failed",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                })
        },
    }
}
