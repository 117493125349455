import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useStore } from '../../store/AppContext';
import Sidebar from './Sidebar';
import Topbar from './Topbar';
import DashboardPage from '../../pages/DashboardPage';
import CoPilotPage from '../../pages/co-pilot';
import DocSearchPage from '../../pages/doc-search';
import DbSearchPage from '../../pages/db-search';
import Authentication from '../../pages/Authendication';
import { useIntegration } from '../../hooks/useIntegration';
import { USER_ROLE } from '../../config';
import ApplicationsPermissionWrapper from '../ApplicationsPermissionWrapper';

const Home = () => {
	const integrationHook = useIntegration();
	const { state } = useStore();
	const [isSidebarOpen, setSidebarOpen] = useState(true);
	const [sectionTitle, setSectionTitle] = useState("");
	const [activeVDB, setActiveVDB] = useState("");
	const [activeLLM, setActiveLLM] = useState("");
	const [activeTabId, setActiveTabId] = useState("");

	useEffect(() => {
		integrationHook.getIntegration();
	}, [])

	const handleToggleSidebar = () => {
		setSidebarOpen(!isSidebarOpen);
	}

	return (
		<div className="flex h-screen bg-white dark:bg-gray-dark">
			<Sidebar isOpen={isSidebarOpen} onToggleSidebar={handleToggleSidebar} sectionTitle={sectionTitle} setActiveTabId={setActiveTabId} setSectionTitle={setSectionTitle} setActiveVDB={setActiveVDB} setActiveLLM={setActiveLLM} activeTabId={activeTabId} />
			<div className={`flex-1 ${isSidebarOpen ? 'pl-80' : 'pl-0'} transition-all duration-300 w-full`}>
				<Topbar isOpen={isSidebarOpen} onToggleSidebar={handleToggleSidebar} activeTabId={activeTabId} sectionTitle={sectionTitle} activeVDB={activeVDB} activeLLM={activeLLM} />
				<main className="w-full xl:max-w-full mx-auto">
					<Routes>
						<Route path="/" element={<DashboardPage />} />
						<Route path="/dashboard" element={<DashboardPage />} />
						<Route path="/service-pilot/*" element={<CoPilotPage />} />
						<Route path="/db-search/*" element={<DbSearchPage />} />
						<Route path="/doc-search/*" element={<DocSearchPage isSidebarOpen={isSidebarOpen} activeLLM={activeLLM}/>} />
						{[USER_ROLE.SUPER_ADMIN].includes(state.userRole) &&
							<>
								<Route path="/auth/*" element={<Authentication />} />
								<Route path="/users/*" element={<ApplicationsPermissionWrapper />} />
							</>
						}
					</Routes>
				</main>
			</div>
		</div>
	)
}

export default Home;
