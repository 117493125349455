import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { InputWrapper2 } from "../InputWrapper";
import { Button } from '../Button';

const GetInput = ({ parameters, isOpen, onClose, onSubmit }) => {
    const [input, setInput] = useState(null);

    useEffect(() => {
        const popupDiv = document.getElementById('workflow_input_popup_div');
        if (isOpen) {
            popupDiv.style.display = 'flex';
        } else {
            setTimeout(() => {
                popupDiv.style.display = 'none';
                setInput(null);
            }, 500);
        }
    }, [isOpen])

    const onEnter = () => {
        parameters.input = input;
        onSubmit(parameters, 'input');
    }

    const handleClose = () => {
        onClose();
    }

    return (
        <div id={'workflow_input_popup_div'} className={`absolute inset-0 z-[100] hidden items-center justify-center`}>
            <div className={`fixed inset-0 bg-white dark:bg-black ${isOpen ? 'popup-ease-in-opacity ' : 'popup-ease-out-opacity '} opacity-50`} />
            <div className={`${isOpen ? 'popup-ease-in ' : 'popup-ease-out '} bg-white-500 dark:bg-gray-dark w-2/3 border-2 border-white-gray-400 dark:border-gray drop-shadow-xl p-8 rounded-3xl z-10`}>
                <div className='flex flex-row justify-between'>
                    <div>
                        {parameters.question && <p className='font-medium text-gray-dark dark:text-white'>{parameters.question}</p>}
                    </div>
                    <button className="flex text-gray-dark dark:text-white" onClick={handleClose} >
                        <FontAwesomeIcon className='w-6 h-6' icon={faClose} />
                    </button>
                </div>
                <div className='w-full pt-8'>
                    <InputWrapper2 value={input ? input : ""} setValue={setInput} name={"inputValue"} type={"textarea"} placeholder={parameters.description ? parameters.description : `Enter your answer here`} rows={4} />
                    <div className='flex w-full justify-end'>
                        <Button disabled={!input} className={"self-center mt-4 py-[8px] flex flex-row items-center justify-center content-center"} handleFunction={onEnter} name={"Next"} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default GetInput;