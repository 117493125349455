import React, { useEffect, useState } from 'react'
import { useStore } from '../../store/AppContext';
import './doc.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus, faPlay, faStop, faPencil } from '@fortawesome/free-solid-svg-icons';
import { SourceCheckbox } from '../CheckBox';
import { Button } from '../Button';
import { useDocSearch } from '../../hooks/useDocSearch';
import { scrollToBottom } from './utils';
import Cookies from 'js-cookie';
import { useSpeechSynthesis } from 'react-speech-kit';
import Lottie from 'react-lottie';
import * as animationData from '../../assets/animations/Generate-stars.json';
import { faFile } from "@fortawesome/free-regular-svg-icons";
import { ROUTES_ID } from '../../config';
import { ChatInput } from '../InputWrapper';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

export const AskPDFInfoMessage = ({ text }) => {
    return (
        <div className='flex pb-8 px-4'>
            <div className='w-16 pb-4'>
                <img className='w-8 h-8' src={"/assets/images/ai-twinkle.svg"} alt="" />
            </div>
            <div className='text-gray-black dark:text-white w-full pb-4 border-b border-white-gray-dark-500 dark:border-gray'>
                <div className='w-11/12'>
                    <p>Ask a question about the source: {text}</p>
                </div>
            </div>
        </div>
    )
}

export const ChatLoading = () => {
    return (
        <div className='flex pb-8 px-4 relative'>
            <div className='flex items-start w-16 pb-4 pr-4'>
                <Lottie options={defaultOptions} height={40} width={40} isStopped={false} />
            </div>
        </div>
    )
}

export const ChatMessage = ({ChatArray, setChatArray, prevChat, data, chatContainerRef, chatID, editChatIndex, setEditChatIndex, isSidebarOpen, isSidePanelOpen, handleMsgSend }) => {
    const [sourceOpen, setSourceOpen] = useState(false);
    const [feedback, setFeedback] = useState(data['bot'] ? data['bot'].human_feedback : 0);
    const [selectedOption, setSelectedOption] = useState(null);
    const [copied, setCopied] = useState(false);
    const docSearchHook = useDocSearch();
    const { state } = useStore();

    useEffect(() => {
        setSourceOpen(false);
        setSelectedOption(null);
    }, [state.docSearchCurrentConversation])

    const onEnd = () => {
        console.log("ON speach end ")
    }

    const { speak, speaking, cancel } = useSpeechSynthesis({ onEnd });

    const handleFeedback = (value) => {
        setFeedback(value);
        let user = JSON.parse(Cookies.get("user"));
        let requestData = {
            "user_id": user.username,
            "conversation_id": state.docSearchCurrentConversation,
            "text": prevChat['user'] ? prevChat['user'].mssg : "",
            "llm_output": data['bot'] ? data['bot'].mssg : "",
            "feedback_val": value
        }
        docSearchHook.updateDocSearchUserFeedback(requestData);
    }

    const handleCopyUI = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 3000)
    }

    const handleSource = () => {
        setSourceOpen(!sourceOpen);
    }

    const onEditClose = () => {
        setEditChatIndex(-1)
    }

    const handleSummary = () => {
        let chat = { mssg: `Give me the summary for the ${selectedOption.name}` }
        setChatArray((prevChats) => [
            ...prevChats, { user: chat }
        ])
        scrollToBottom(chatContainerRef);

        const req = {
            text: chat.mssg,
            conversation_id: state.docSearchCurrentConversation,
            user_id: state.user && state.user.username ? state.user.username : null,
            username: state.user && state.user.username ? state.user.username : null,
            file_paths: selectedOption.path,
            file_name: selectedOption.name,
            streaming: true,
            integration: state.selectedIntegration[ROUTES_ID.DOCUMENT_SEARCH].id
        }
        docSearchHook.getSummary(req, ChatArray.length);
    }

    const handleCheckboxChange = (optionId) => {
        setSelectedOption(optionId);
    }

    function copyText(element) {
        const copyTarget = document.getElementById(element);

        if (navigator.clipboard) {
            const textToCopy = copyTarget.innerText;
            navigator.clipboard.writeText(textToCopy);
            handleCopyUI();
        } else {
            const range = document.createRange();
            range.selectNode(copyTarget);
            window.getSelection().removeAllRanges();
            window.getSelection().addRange(range);

            try {
                document.execCommand('copy');
                handleCopyUI();
            } catch (err) {
                console.error('Unable to copy text to clipboard:', err);
            }
            window.getSelection().removeAllRanges();
        }
    }

    function convertToSemibold(inputString) {
        var regex = /\*(.*?)\*/g;
        var result = inputString.replace(regex, '<b style="font-weight:500">$1</b>');

        return result;
    }

    function convertToBold(inputString) {
        const regex = /\*\*(.*?)\*\*/g;
        const result = inputString.replace(regex, '<b style="font-weight:700">$1</b>');
        return result;
    }

    function removeDoubleStar(inputString) {
        const result = inputString.replace("**", '');
        return result;
    }

    function removeTriBraces(inputString) {
        const result = inputString.replace("<>", '');
        return result;
    }

    function removeHash(inputString) {
        const regex = /\#/g;
        const result = inputString.replace(regex, '');
        return result;
    }

    function hasHTMLElement(text) {
        const htmlRegex = /<\/?[a-z][\s\S]*>/i;
        return htmlRegex.test(text);
    }

    const replaceOrderedList = text => {
        return text.replace(/(?<=^|\n)(\d+\.\s.*?)(?=\n|$)/gs, '<li>$1</li>');
    }

    const replaceFollowUpQ = text => {
        var modifiedText = text.replace(/(Possible follow-up questions:)/gi, '<strong style="margin-top:18px; display:block; text-transform: capitalize;">$1</strong>');
        modifiedText = modifiedText.replace(/(Possible follow-up questions could be:)/gi, '<strong style="margin-top:18px; display:block; text-transform: capitalize;">$1</strong>');
        return modifiedText;
    }

    const replaceUnorderedList = text => {
        return text.replace(/(-\s.*?)(?=-\s|\n|$)/gs, '<li style="margin-left: 20px;">$1</li>');
    }

    const onPlay = (chatContent) => {
        speak({ text: chatContent.mssg || '' })
    }

    const formatMessageText = (text) => {
        let removeText = ["```", "html\n", "html", "<body>\n", "</body>\n", "```", "```\n"];
        let outputString = text;

        try {
            outputString = decodeURIComponent(escape(text));
        } catch (error) {
            console.log(error)
        }

        if (outputString.length > 0) {
            const ulOlRegex = /<([ou]l)[^>]*>[\s\S]*?<\/\1>/gi;
            const ListMatches = outputString.match(ulOlRegex);
            if (ListMatches == null) {
                outputString = replaceOrderedList(outputString);
                outputString = replaceUnorderedList(outputString);
            }
            outputString = convertToBold(outputString)
            outputString = convertToSemibold(outputString)
            outputString = replaceFollowUpQ(outputString)

            const tableRegex = /<table\b[^>]*>[\s\S]*?<\/table>/i;
            const tableMatch = outputString.match(tableRegex);
            if (hasHTMLElement(text)) {
                removeText.forEach(element => {
                    outputString = outputString.replace(element, "");
                });
                outputString = removeDoubleStar(outputString)
                outputString = removeTriBraces(outputString)
                outputString = removeHash(outputString)
                let remainText = outputString;
                if (tableMatch != null) {
                    const splitResult = outputString.split(tableMatch[0]);
                    const restOfText = splitResult[1].trim();
                    remainText = restOfText.split('\n').map((line, index) => (
                        <React.Fragment key={index}>
                            <div dangerouslySetInnerHTML={{ __html: line }} />
                        </React.Fragment>
                    ))

                    return (
                        <React.Fragment >
                            <div className={ListMatches == null ? 'custom_list' : ''}>
                                {tableMatch != null ? <div>
                                    <div dangerouslySetInnerHTML={{ __html: tableMatch }} />
                                    {remainText}
                                </div> :
                                    <div dangerouslySetInnerHTML={{ __html: remainText }} />
                                }
                            </div>
                        </React.Fragment>
                    )
                } else {
                    return outputString.split('\n').map((line, index) => (
                        <React.Fragment key={index}>
                            <div className={ListMatches == null ? 'custom_list' : ''}>
                                <div dangerouslySetInnerHTML={{ __html: line }} />
                            </div>
                        </React.Fragment>
                    ));
                }
            } else {
                return outputString.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                        <div className={ListMatches == null ? 'custom_list' : ''}>
                            <div dangerouslySetInnerHTML={{ __html: line }} />
                        </div>
                    </React.Fragment>
                ));
            }
        }
    }

    if (data['user']) {
        let chatContent = data['user'];
        return (
            <div className='flex pb-8 px-4'>
                {state.user && state.user.user_image && state.user.user_image !== 'null' ?
                    <div className='w-16'>
                        <img className='w-10 h-10 rounded-full' src={state.user.user_image} alt="" />
                    </div>
                    :
                    <div className='w-16'>
                        <div className='w-10 h-10 flex justify-center items-center text-gray dark:text-white-gray-dark bg-white-gray-600 dark:bg-gray-black rounded-full p-2'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path fillRule="evenodd" clipRule="evenodd" d="M10.7945 4.36364C10.7945 5.97028 9.49202 7.27273 7.88538 7.27273C6.27873 7.27273 4.97629 5.97028 4.97629 4.36364C4.97629 2.75699 6.27873 1.45455 7.88538 1.45455C9.49202 1.45455 10.7945 2.75699 10.7945 4.36364ZM12.249 4.36364C12.249 6.77361 10.2953 8.72727 7.88538 8.72727C5.47541 8.72727 3.52174 6.77361 3.52174 4.36364C3.52174 1.95367 5.47541 0 7.88538 0C10.2953 0 12.249 1.95367 12.249 4.36364ZM14.3396 15.5539C14.4784 15.8203 14.7471 16 15.0474 16C15.569 16 15.9317 15.4835 15.6988 15.0168C14.2681 12.1505 11.3067 10.1818 7.88538 10.1818C4.4641 10.1818 1.50263 12.1505 0.0719854 15.0168C-0.160924 15.4835 0.20177 16 0.723307 16C1.02368 16 1.29237 15.8203 1.43114 15.5539C2.64377 13.226 5.07894 11.6364 7.88538 11.6364C10.6918 11.6364 13.127 13.226 14.3396 15.5539Z" fill="currentColor" />
                            </svg>
                        </div>
                    </div>
                }
                <div className='w-full pb-4 border-b border-gray-700 dark:border-gray'>
                    <div className='w-full'>
                        {editChatIndex > -1 && editChatIndex === chatID ?
                            <div className={`flex relative group ${isSidebarOpen ? (isSidePanelOpen ? 'chat-input-with-side-bar-and-side-panel-container' : 'chat-input-with-side-bar-container') : (isSidePanelOpen ? 'chat-input-with-side-panel-container' : 'chat-input-container')} flex items-center`}>
                                <ChatInput name={"Doc_chat"} disabled={state.isDocSearchStreaming} handleSend={handleMsgSend} userPrompt={chatContent.mssg} type={"text"} onClose={onEditClose} />
                            </div>
                            :
                            <div className={`flex relative group ${isSidebarOpen ? (isSidePanelOpen ? 'chat-element-with-side-bar-and-side-panel-container' : 'chat-element-with-side-bar-container') : (isSidePanelOpen ? 'chat-element-with-side-panel-container' : 'chat-element-container')} overflow-x-auto p-3 rounded-xl bg-white-gray-600 dark:bg-gray-black`}>
                                <p className='text-gray-black dark:text-white w-[95%]'>{chatContent.mssg}</p>
                                {!state.isDocSearchStreaming && !state.isWorkFlowRunning && 
                                    <p className='text-gray-black dark:text-white w-[5%] hidden group-hover:block' onClick={() => setEditChatIndex(chatID)}>
                                        <FontAwesomeIcon className='mx-3' icon={faPencil} />
                                    </p>
                                }
                            </div>
                        }
                        {chatContent.file &&
                            <p className='text-gray-black dark:text-white'><b>Source file: </b>
                                <FontAwesomeIcon className='pr-3 ml-2' icon={faFile} />
                                {chatContent.file}
                            </p>
                        }
                    </div>
                </div>
            </div>
        )
    } else if ((data['bot'])) {
        let chatContent = data['bot'];
        return (
            <div className='flex pb-8 px-4 relative'>
                <div className='flex items-start w-16 pb-4 pr-4'>
                    <Lottie options={defaultOptions} height={40} width={40} isStopped={!chatContent.isStreaming} />
                </div>
                <div className='w-full pb-4 border-b border-gray-700 dark:border-gray'>
                    <div className='w-full'>
                        <div className='text-black dark:text-white chatHTML' id={`${chatID}_bot`}>{formatMessageText(chatContent.mssg ? chatContent.mssg : "Unable to generate your response. Please try again!")}</div>
                        <div className='mt-4'>
                            {chatContent.sources && chatContent.sources.length > 0 &&
                                <div>
                                    <button onClick={handleSource} className='text-gray-500 dark:text-gray font-semibold outline-none mb-2 hover:text-gray-800 hover:dark:text-white'>{!sourceOpen ? "Show Source" : "Hide Source"} <FontAwesomeIcon className='ml-4' icon={!sourceOpen ? faPlus : faMinus} /></button>
                                    {sourceOpen &&
                                        <>
                                            <SourceCheckbox options={chatContent.sources} selectedOption={selectedOption} onChange={handleCheckboxChange} type={'radio'} />
                                            {selectedOption &&
                                                <Button disabled={state.isDocSearchStreaming} handleFunction={handleSummary} className={'text-sm ml-2 my-1 px-[8px] py-[6px]'} name={"Summary"} />
                                            }
                                        </>
                                    }
                                </div>
                            }
                        </div>
                        {!chatContent.isStreaming &&
                            <div className='text-gray-black dark:text-white flex mt-2 gap-2'>
                                <button onClick={() => copyText(chatID + "_bot")} className={`px-2 w-8 h-8 py-1 rounded-md hover:bg-white-gray-500 hover:dark:bg-gray-300 ${copied ? 'bg-white-gray-600 dark:bg-gray-300' : ''}`}>
                                    <svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className="icon-sm">
                                        <path fillRule="evenodd" clipRule="evenodd" d="M12 4C10.8954 4 10 4.89543 10 6H14C14 4.89543 13.1046 4 12 4ZM8.53513 4C9.22675 2.8044 10.5194 2 12 2C13.4806 2 14.7733 2.8044 15.4649 4H17C18.6569 4 20 5.34315 20 7V19C20 20.6569 18.6569 22 17 22H7C5.34315 22 4 20.6569 4 19V7C4 5.34315 5.34315 4 7 4H8.53513ZM8 6H7C6.44772 6 6 6.44772 6 7V19C6 19.5523 6.44772 20 7 20H17C17.5523 20 18 19.5523 18 19V7C18 6.44772 17.5523 6 17 6H16C16 7.10457 15.1046 8 14 8H10C8.89543 8 8 7.10457 8 6Z" fill="currentColor"></path>
                                    </svg>
                                </button>
                                {chatContent.type !== "workflow" && <>
                                    <button onClick={() => handleFeedback(1)} className={`px-2 w-8 h-8 py-1 rounded-md hover:bg-white-gray-500 hover:dark:bg-gray-300 ${feedback === 1 && "bg-white-gray-600 dark:bg-gray-300"}`}>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M9 2.5625L8.875 3.09375C8.53125 4.34375 7.71875 5.4375 6.625 6.125L6.53125 6.1875C6.28125 6.34375 6.0625 6.5625 5.875 6.8125C5.71875 7.03125 5.40625 7.09375 5.1875 6.9375C4.96875 6.75 4.90625 6.4375 5.0625 6.21875C5.3125 5.875 5.625 5.59375 6 5.34375L6.09375 5.28125C6.96875 4.71875 7.625 3.84375 7.90625 2.8125L8.03125 2.3125C8.3125 1.375 9.25 0.8125 10.1875 1.0625C11.125 1.34375 11.6875 2.28125 11.4375 3.21875L11.2812 3.75C11.1562 4.1875 11 4.59375 10.8125 5H14C15.0938 5 16 5.90625 16 7C16 7.5625 15.75 8.0625 15.4062 8.4375C15.4688 8.625 15.5 8.8125 15.5 9C15.5 9.59375 15.2188 10.125 14.8438 10.5C14.9375 10.7188 15 11 15 11.25C15 12 14.5938 12.6562 14 13C14 14.125 13.0938 15 12 15H9.1875C8.5 15 7.8125 14.8125 7.25 14.4375L6.03125 13.625C5.65625 13.3438 5.3125 13.0312 5.0625 12.625C4.90625 12.4062 4.96875 12.0938 5.21875 11.9375C5.4375 11.7812 5.75 11.8438 5.90625 12.0938C6.09375 12.375 6.3125 12.5938 6.59375 12.7812L7.8125 13.5938C8.21875 13.875 8.6875 14 9.1875 14H12C12.5312 14 13 13.5625 13 13C13 12.9375 12.9688 12.875 12.9688 12.8125C12.9062 12.5625 13.0625 12.3125 13.3125 12.2188C13.6875 12.0938 14 11.7188 14 11.2812C14 11.0312 13.9062 10.8125 13.7812 10.6562C13.6875 10.5312 13.6562 10.4062 13.6875 10.25C13.7188 10.0938 13.8125 9.96875 13.9375 9.90625C14.2812 9.75 14.5 9.40625 14.5 9.03125C14.5 8.84375 14.4375 8.65625 14.3438 8.53125C14.2188 8.28125 14.3125 8 14.5312 7.875C14.8125 7.6875 15 7.375 15 7.03125C15 6.46875 14.5312 6.03125 14 6.03125H9.96875C9.78125 6.03125 9.625 5.90625 9.53125 5.75C9.4375 5.59375 9.4375 5.40625 9.53125 5.25C9.875 4.71875 10.1562 4.09375 10.3125 3.46875L10.4688 2.96875C10.5625 2.5625 10.3438 2.15625 9.9375 2.03125C9.53125 1.9375 9.125 2.15625 9 2.5625ZM1 7V14H3V7H1ZM0 7C0 6.46875 0.4375 6 1 6H3C3.53125 6 4 6.46875 4 7V14C4 14.5625 3.53125 15 3 15H1C0.4375 15 0 14.5625 0 14V7Z" fill="currentColor" />
                                        </svg>
                                    </button>
                                    <button onClick={() => handleFeedback(-1)} className={`px-2 w-8 h-8 py-1 rounded-md hover:bg-white-gray-500 hover:dark:bg-gray-300 ${feedback === -1 && "bg-white-gray-600 dark:bg-gray-300"}`}>
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M9 12.4688C9.125 12.875 9.53125 13.0938 9.9375 13C10.3438 12.875 10.5625 12.4688 10.4688 12.0625L10.3125 11.5625C10.1562 10.9062 9.875 10.3125 9.53125 9.78125C9.4375 9.625 9.4375 9.4375 9.53125 9.28125C9.625 9.09375 9.78125 9 9.96875 9H14C14.5312 9 15 8.5625 15 8C15 7.65625 14.8125 7.34375 14.5312 7.15625C14.3125 7.03125 14.2188 6.71875 14.3438 6.5C14.4375 6.34375 14.5 6.1875 14.5 6C14.5 5.625 14.2812 5.28125 13.9375 5.125C13.8125 5.0625 13.7188 4.9375 13.6875 4.78125C13.6562 4.625 13.6875 4.5 13.7812 4.375C13.9062 4.1875 14 4 14 3.75C14 3.3125 13.6875 2.9375 13.3125 2.8125C13.0625 2.71875 12.9062 2.46875 12.9688 2.21875C12.9688 2.15625 13 2.09375 13 2C13 1.46875 12.5312 1 12 1H9.1875C8.6875 1 8.21875 1.15625 7.8125 1.4375L6.59375 2.25C6.3125 2.4375 6.09375 2.65625 5.90625 2.9375C5.75 3.1875 5.4375 3.25 5.21875 3.09375C4.96875 2.9375 4.90625 2.625 5.0625 2.40625C5.3125 2 5.65625 1.65625 6.03125 1.40625L7.25 0.59375C7.8125 0.21875 8.5 0 9.1875 0H12C13.0938 0 14 0.90625 14 2V2.03125C14.5938 2.375 15 3.03125 15 3.75C15 4.03125 14.9375 4.3125 14.8438 4.53125C15.2188 4.90625 15.5 5.4375 15.5 6C15.5 6.21875 15.4688 6.40625 15.4062 6.59375C15.75 6.96875 16 7.46875 16 8C16 9.125 15.0938 10 14 10H10.8125C11 10.4375 11.1562 10.8438 11.2812 11.2812L11.4375 11.8125C11.6875 12.75 11.125 13.6875 10.1875 13.9688C9.25 14.2188 8.3125 13.6562 8.03125 12.7188L7.90625 12.2188C7.625 11.1875 6.96875 10.3125 6.09375 9.75L6 9.6875L6.25 9.28125L6 9.6875C5.625 9.4375 5.3125 9.15625 5.0625 8.8125C4.90625 8.5625 4.96875 8.25 5.1875 8.09375C5.40625 7.9375 5.71875 8 5.875 8.21875C6.0625 8.46875 6.28125 8.6875 6.53125 8.84375L6.625 8.90625C7.71875 9.59375 8.53125 10.6875 8.875 11.9375L9 12.4688ZM1 10H3V3H1V10ZM0 10V3C0 2.46875 0.4375 2 1 2H3C3.53125 2 4 2.46875 4 3V10C4 10.5625 3.53125 11 3 11H1C0.4375 11 0 10.5625 0 10Z" fill="currentColor" />
                                        </svg>
                                    </button>
                                </>}
                                {!speaking ?
                                    <button onClick={() => onPlay(chatContent)} className={`px-2 w-8 h-8 py-1 rounded-md hover:bg-white-gray-500 hover:dark:bg-gray-300`}>
                                        <FontAwesomeIcon icon={faPlay} className={`text-gray-dark dark:text-white w-4 h-4 font-bold`} />
                                    </button> :
                                    <button onClick={() => cancel()} className={`px-2 w-8 h-8 py-1 rounded-md hover:bg-white-gray-500 hover:dark:bg-gray-300`}>
                                        <FontAwesomeIcon icon={faStop} className={`text-gray-dark dark:text-white w-4 h-4 font-bold`} />
                                    </button>
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    } else {
        return null
    }
}

