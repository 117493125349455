import React, { useEffect, useState } from 'react';
import { Button } from '../Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputWrapper } from '../InputWrapper';
import { useConfig } from '../../hooks/useConfig';
import { useStore } from '../../store/AppContext';

const AuthInfo = ({ integrationId, data, isOpen, onClose }) => {
    const { state } = useStore();
    const configHook = useConfig();
    const [canSave, setCanSave] = useState(false);
    const [appFields, setAppFields] = useState(data.fields);

    useEffect(() => {
        const popupDiv = document.getElementById(`${integrationId}_intergrations_auth_popup_div`);
        if (isOpen) {
            popupDiv.style.display = 'flex';
        } else {
            setTimeout(() => {
                popupDiv.style.display = 'none';
            }, 500);
        }
    }, [isOpen])

    useEffect(() => {
        let canSave = true;
        appFields.forEach(f => {
            canSave = (![null, undefined, ''].includes(f.value));

            return f;
        });
        setCanSave(canSave);
    }, [appFields])


    const handleClose = () => {
        onClose();
    }

    const handleChange = (e) => {
        let newFiled = appFields.map(f => {
            if (f.key === e.target.name)
                f.value = e.target.value;

            return f;
        });

        setAppFields(newFiled);
    }

    const handleSave = () => {
        let integrationConfig = {};
        appFields.forEach(f => {
            integrationConfig[f.key] = f.value;

            return f;
        })

        let config = {
            ...state.config,
            integrations: {
                ...state.config.integrations,
                [integrationId]: integrationConfig
            }
        }
        configHook.updateConfig(config, true, null, null, handleClose);
    }

    return (
        <div id={`${integrationId}_intergrations_auth_popup_div`} className={`fixed inset-0 z-[100] hidden items-center justify-center`}>
            <div className={`fixed inset-0 bg-white dark:bg-black ${isOpen ? 'popup-ease-in-opacity ' : 'popup-ease-out-opacity '} opacity-50`} onClick={handleClose} />
            <div className={`${isOpen ? 'popup-ease-in ' : 'popup-ease-out '}text-gray-dark dark:text-white bg-white-500 dark:bg-gray-dark border border-white-gray-400 dark:border-gray w-[400px] drop-shadow-xl p-6 rounded-2xl z-10`}>
                <div className='font-light relative p-4'>
                    <button className="fixed right-8 top-4 text-2xl focus:outline-none" onClick={handleClose}>
                        <FontAwesomeIcon icon="xmark" />
                    </button>
                    <div className='flex flex-col items-center justify-center my-6'>
                        {data.logo}
                        <h4 className='text-xl text-center mt-4 mb-6'>{data.title}</h4>
                    </div>
                    <div className='mt-4'>
                        <form onChange={handleChange}>
                            {data && data.fields.map((field, index) =>
                                <InputWrapper key={index} containerStyle={"mb-3"} value={field.value} name={field.key} label={field.label} type={field.type} />
                            )}
                        </form>
                    </div>
                    <div className='pt-4 flex justify-center'>
                        <Button className={"w-full"} handleFunction={handleSave} disabled={!canSave} name={"Integrate"} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AuthInfo;
