import React from 'react';
import { Button } from '../Button';

const BottomBar = ({ ButtonList ,Component }) => {
	return (
		<div className="bg-white dark:bg-gray-dark z-50 w-full h-20 right-0 border-white-gray-dark-500 dark:border-gray-medium border-t-2">
			<div className="z-50 px-6 py-4 flex justify-end gap-4">
				{Component &&
					Component
				}
				{ButtonList && ButtonList.map((item, key) => (
					<Button disabled={item.disabled} handleFunction={item.handleFunction} type={item.type} className={`py-[10px] ${item.className}`} name={item.name} key={key} />
				))}
			</div>
		</div>
	)
}

export default BottomBar;
