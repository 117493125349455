import React, { useEffect, useState } from 'react';
import { InputWrapper } from './InputWrapper';
import DropdownWrapper from './DropdownWrapper';
import FileUpload from './DocSearch/FileUpload';
import { ROUTES_ID } from '../config';

const DBConfigWrapper = ({ title, index, dbConfig, setDBConfig }) => {
    const [databaseTypes, setDatabaseTypes] = useState([
        { id: 'csv', label: 'CSV' },
        // { id: 'bigquery', label: 'Big Query' }
    ])
    const [databaseType, setDatabaseType] = useState();
    const [config, setConfig] = useState([{}]);
    const [databaseName, setDatabaseName] = useState('')

    useEffect(() => {
        if (config && Object.keys(config).length > 0) {
            let prevDBConfig = dbConfig.database ? dbConfig.database : [{}];
            prevDBConfig[index] = config;
            setDBConfig((prevData) => ({ ...prevData, database: prevDBConfig }));
        }
    }, [config])

    useEffect(() => {
        databaseType && setConfig((prevData) => ({ ...prevData, databaseType: databaseType }));
    }, [databaseType])

    const onChange = (e) => {
        setConfig((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
    }

    const onDatabaseNameChanged = (value) =>{
        setDatabaseName(value)
        setConfig((prevData) => ({ ...prevData, databaseName: value }));
    }

    return (
        <div className='mb-2'>
            <h5 className="text-gray-dark dark:text-white font-bold">{title}</h5>
            <form onChange={onChange}>
                <DropdownWrapper
                    placeholder="Select a database"
                    options={databaseTypes}
                    value={databaseType ? databaseType.label : ''} setValue={setDatabaseType} name={'databaseType'} type={"text"}
                />
                {databaseType && databaseType.label &&
                    ((databaseType.id === 'bigquery') ?
                        <>
                            <InputWrapper value={config['projectName'] ? config['projectName'] : ''} label={"Project Name"} name={'projectName'} type={"text"} containerStyle={'mb-4'} />
                            <InputWrapper value={config['serviceAccountFile'] ? config['serviceAccountFile'] : ''} label={"Service Account File"} name={'serviceAccountFile'} type={"text"} containerStyle={'mb-4'} />
                            <InputWrapper value={config['databaseName'] ? config['databaseName'] : ''} label={"Database Name"} name={'databaseName'} type={"text"} containerStyle={'mb-4'} />
                            <p className='text-gray -mt-4 text-sm'>For more information on retrieving your Big Query Database details, please visit the following <a href='https://cloud.google.com/bigquery/docs/tables-intro' target="_blank" className='text-blue underline'>link</a>.</p>
                        </>
                        :
                        ((databaseType.id === 'csv') ?
                            <>
                                <InputWrapper setValue={(value) => onDatabaseNameChanged(value)}  value={databaseName} label={"Database Name"} name={'databaseName'} type={"text"} containerStyle={'mb-4'} />
                                <FileUpload disabled={databaseName===""} appId={ROUTES_ID.DATABASE_SEARCH} name={'Upload a file'} acceptFiles={{ 'text/csv': ['.csv'] }} allowMultiple={false} folder={false} path={'db_search'} dbConfig={dbConfig} setDBConfig={setDBConfig}/>
                            </>
                            :
                            <>
                                <InputWrapper value={config['databaseURL'] ? config['databaseURL'] : ''} label={"Database URL"} name={'databaseURL'} type={"text"} containerStyle={'mb-4'} />
                                <InputWrapper value={config['databaseName'] ? config['databaseName'] : ''} label={"Database name"} name={'databaseName'} type={"text"} containerStyle={'mb-4'} />
                                <InputWrapper value={config['username'] ? config['username'] : ''} label={"Username"} name={'username'} type={"text"} containerStyle={'mb-4'} />
                                <InputWrapper value={config['password'] ? config['password'] : ''} label={"Password"} name={'password'} type={"password"} containerStyle={'mb-4'} />
                                <p className='text-gray -mt-4 text-sm'>For more information on retrieving your MySql Database details, please visit the following <a href='https://dev.mysql.com/doc/refman/8.0/en/database-use.html' target="_blank" className='text-blue underline'>link</a>.</p>
                            </>
                        )
                    )
                }
            </form>
        </div>
    )
}

export default DBConfigWrapper;
