import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import { ChatInput } from '../InputWrapper';
import { useDocSearch } from '../../hooks/useDocSearch';
import { useStore } from '../../store/AppContext';
import Cookies from "js-cookie";
import { faFile } from "@fortawesome/free-regular-svg-icons";
import { ROUTES_ID } from '../../config';

const AskQuestionModal = ({ isOpen, onClose, data, setChatHistory, ChatHistory }) => {
	const PDFchatContainerRef = useRef(null);
	const [PDFChatHistory, setPDFChatHistory] = useState([]);
	const docSearchHook = useDocSearch();
	const { state, dispatch } = useStore();

	useEffect(() => {
		const popupDiv = document.getElementById('ask_question_popup_div');
		if (isOpen) {
			popupDiv.style.display = 'flex';
		} else {
			setTimeout(() => {
				popupDiv.style.display = 'none';
			}, 500);
		}
	}, [isOpen])

	useEffect(() => {
		if (state.docSearchPDFChatResponse && state.docSearchCurrentConversation) {
			let index = state.docSearchPDFChatResponse.totalChat + 1
			setPDFChatHistory(prevData => {
				if (index >= 0 && index < prevData.length) {
					let newData = prevData.map((item, i) => (i === index && item.bot ? { ...item, bot: { ...item.bot, mssg: state.docSearchPDFChatResponse.mssg, isStreaming: state.docSearchPDFChatResponse.isStreaming } } : item));
					return newData;				
				} else {
					return [...prevData, { bot: state.docSearchPDFChatResponse }];
				}
			});
		}

		return () => {
			dispatch({
				type: "SET_DOC_PDF_RESPONSE",
				payload: null
			})
		}
	}, [state.docSearchPDFChatResponse])

	useEffect(() => {
		scrollToBottom();
	}, [PDFChatHistory]);

	const handleMsgSend = (chatData) => {
		const user = JSON.parse(Cookies.get("user"));
		setChatHistory((prevChats) => {
			return [...prevChats, { user: { mssg: chatData.text, file: data.fileName } }]
		})
		const totalChat = ChatHistory.length;
		const newChat = {
			text: chatData.text,
			user_id: user.username,
			username: user.username,
			conversation_id: state.docSearchCurrentConversation,
			web_search: false,
			streaming: true,
			userGroup: user.user_groups,
			source_path: data.filePath,
			integration: state.selectedIntegration[ROUTES_ID.DOCUMENT_SEARCH].id
		};
		docSearchHook.getSingleQA(newChat, totalChat, false);
		handleClose()
	}

	const handleClose = () => {
		setPDFChatHistory([]);
		onClose();
	}

	const scrollToBottom = () => {
		if (PDFchatContainerRef.current) {
			PDFchatContainerRef.current.scrollTop = PDFchatContainerRef.current.scrollHeight;
		}
	}

	return (
		<div id={'ask_question_popup_div'} className="fixed inset-0 z-50 hidden items-center justify-center">
			<div className={`fixed inset-0 bg-white dark:bg-black ${isOpen ? 'popup-ease-in-opacity ' : 'popup-ease-out-opacity '} opacity-50`} onClick={handleClose} />
			<div className={`${isOpen ? 'popup-ease-in ' : 'popup-ease-out '} bg-white dark:bg-gray-dark border-2 border-white-gray-400 dark:border-gray w-3/6 drop-shadow-xl p-6 rounded-lg z-10`}>
				<button className="text-gray-dark dark:text-white absolute top-2 right-4" onClick={handleClose} >
					<FontAwesomeIcon icon={faClose} />
				</button>
				<h1 className='text-gray-dark dark:text-white font-semibold text-lg'>Ask a question about a source</h1>
				<div className='text-gray-black dark:text-white w-full pb-4 mt-5'>
					<div className='w-full mt-5'>
						<p>
							<b>Source file: </b>
							<FontAwesomeIcon className='pr-3 ml-2' icon={faFile} />
							{data.fileName}
						</p>
					</div>
				</div>
				<div className=' w-full'>
					<ChatInput disabled={state.isDocSearchStreaming} name={"pdf_chat"} handleSend={handleMsgSend} placeholder={"Type here..."} type={"text"} />
				</div>
			</div>
		</div>
	)
}

export default AskQuestionModal;
