import React, { useEffect, useState } from 'react';
import Table from '../../components/Table';
import { useStore } from '../../store/AppContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faTrashAlt, faUserAlt } from '@fortawesome/free-solid-svg-icons';
import UserGroupAccessModal from '../../components/DocSearch/UserGroupAccessModal';
import { useNavigate } from "react-router-dom";
import BottomBar from "../../components/Home/BottomBar";
import { useUserGroup } from "../../hooks/useUserGroup";
import ConfirmPopup from "../../components/ConfirmPopup";
import FileUpload from "../../components/DocSearch/FileUpload";
import { useCoPilot } from "../../hooks/useCoPilot";
import TooltipWrapper from "../../components/TooltipWrapper";
import { Button } from "../../components/Button";
import { CheckBox } from "../../components/CheckBox";
import { ROUTES_ID } from '../../config';
import Cookies from "js-cookie";

const ManageDocuments = () => {
	const coPilotHook = useCoPilot();
	const userGroupHook = useUserGroup();
	const navigate = useNavigate();
	const { state } = useStore();
	const [isOpen, setIsOpen] = useState(false);
	const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
	const [selectedResourceData, setSelectedResourceData] = useState({});
	const [selectedResourceList, setSelectedResourceList] = useState([]);
	const [selectedAll, setSelectedAll] = useState(false);
	const [isMultiDeleteConfirmOpen, setIsMultiDeleteConfirmOpen] = useState(false);

	useEffect(() => {
		userGroupHook.getAllUserGroupsWithUsersList();
	}, [])

	const onDelete = () => {
		const user = JSON.parse(Cookies.get('user'));
		coPilotHook.deleteDocuments({ file_info: [{ path: selectedResourceData.filePath, integration: selectedResourceData.integration }], username: user.username });
		setIsDeleteConfirmOpen(false);
	}

	const onEdit = (data) => {
		setIsOpen(true);
		setSelectedResourceData(data);
	}

	const handleDelete = (data) => {
		setIsDeleteConfirmOpen(true);
		setSelectedResourceData(data);
	}

	const actionComponent = (data) => {
		return (
			<div className="flex gap-4 cursor-default">
				<FontAwesomeIcon onClick={() => (data.user_ids && data.user_ids.includes(state.user.username)) && onEdit(data)} icon={faUserAlt} className={`${(data.user_ids && data.user_ids.includes(state.user.username)) ? "text-gray-dark dark:text-white cursor-pointer" : "text-gray dark:text-gray cursor-default"} font-bold self-center my-2`} />
				<FontAwesomeIcon onClick={() => (data.user_ids && data.user_ids.includes(state.user.username)) && handleDelete(data)} icon={faTrashAlt} className={`${(data.user_ids && data.user_ids.includes(state.user.username)) ? "text-gray-dark dark:text-white cursor-pointer" : "text-gray dark:text-gray cursor-default"} font-bold self-center my-2`} />
			</div>
		)
	}

	const closeModal = () => {
		setIsOpen(false);
		setSelectedResourceData({});
	}

	const onMultiDelete = () => {
		let selectedFilePaths = [];
		selectedResourceList.forEach(data => {
			selectedFilePaths.push({
				path: data.filePath,
				integration: data.integration
			});
		})
		const user = JSON.parse(Cookies.get('user'));
		coPilotHook.deleteDocuments({ file_info: selectedFilePaths, username: user.username });
		setIsMultiDeleteConfirmOpen(false);
		setSelectedAll(false);
		setSelectedResourceList([]);
	}

	const setSelected = (data) => {
		const index = selectedResourceList.indexOf(data);
		if (index > -1) {
			selectedResourceList.splice(index, 1);
		} else
			selectedResourceList.push(data);
		setSelectedResourceList([...selectedResourceList]);
	}

	const checkboxComponent = (data) => {
		return (
			<CheckBox
				value={selectedResourceList.includes(data)}
				setValue={() => setSelected(data)}
				name={"isSelect"}
				label={''}
				className={'mt-3 mb-3 ml-3 mr-1'}
			/>
		)
	}

	const onSelectAll = () => {
		selectedAll ? setSelectedResourceList([]) : setSelectedResourceList([...state.coPilotResourcesByUser]);
		setSelectedAll(!selectedAll);
	}

	const selectAllCheckboxComponent = () => {
		return (
			<CheckBox
				value={selectedAll}
				setValue={onSelectAll}
				name={"isSelectAll"}
				label={''}
				className='mt-1 mb-1 ml-3 mr-4'
			/>
		)
	}

	const columns = [
		{
			id: "CUSTOM_COMPONENT",
			titleComponent: selectAllCheckboxComponent,
			component: checkboxComponent,
			textAlign: 'center',
			flex: 0.05
		},
		{
			id: "fileName",
			title: "Name",
			flex: 0.7
		},
		{
			id: "status",
			title: "Status",
			flex: 0.1,
			textAlign: 'center',
			headerClassName: 'items-center justify-center',
			dataClassName: 'items-center justify-center'
		},
		{
			id: "integration",
			title: "Integration",
			flex: 0.1,
			textAlign: 'center',
			headerClassName: 'items-center justify-center',
			dataClassName: 'items-center justify-center'
		},
		{
			id: "CUSTOM_COMPONENT",
			title: "Action",
			component: actionComponent,
			flex: 0.08,
			textAlign: 'center',
			headerClassName: 'items-center justify-center',
			dataClassName: 'items-center justify-center'
		}
	]

	const handleBack = () => {
		navigate('/service-pilot/config?tabId=settings')
	}

	const ButtonList = [
		{
			"name": "Done",
			"handleFunction": () => navigate('/service-pilot/config?tabId=settings'),
			"disabled": false
		}
	]

	useEffect(() => {
		coPilotHook.getDocResourcesByUser();
	}, [])

	const onSave = (request) => {
		coPilotHook.updateDocUserPermission(request);
	}

	const onCloseConfirm = () => {
		setIsDeleteConfirmOpen(false);
		setIsMultiDeleteConfirmOpen(false);
	}

	return (
		<>
			<div className="manage-doc-content-container">
				<button onClick={handleBack} className="flex flex-row text-gray-dark dark:text-white justify-center w-18 ml-6 mb-4 mr-2 mt-1 items-center">
					<FontAwesomeIcon className="w-5 mr-2" icon={faArrowLeft} />
					<p className="text-base font-medium">Back</p>
				</button>
				<main className=" px-6">
					<div className='flex flex-row justify-between pb-3 h-12'>
						<div className='flex flex-row items-center  w-1/2'>
							<h2 className='font-semibold text-xl text-gray-dark dark:text-white'>Document List</h2>
							<TooltipWrapper content={"Uploaded files list"} tootTipStyle={'w-[119px] mt-[15px]'} autoHide={false} isShowArrow={true}>
								<div className='flex ml-1 items-center justify-center'>
									<svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M9 2C6.65625 2 4.53125 3.25 3.34375 5.25C2.1875 7.28125 2.1875 9.75 3.34375 11.75C4.53125 13.7812 6.65625 15 9 15C11.3125 15 13.4375 13.7812 14.625 11.75C15.7812 9.75 15.7812 7.28125 14.625 5.25C13.4375 3.25 11.3125 2 9 2ZM9 16.5C6.125 16.5 3.5 15 2.0625 12.5C0.625 10.0312 0.625 7 2.0625 4.5C3.5 2.03125 6.125 0.5 9 0.5C11.8438 0.5 14.4688 2.03125 15.9062 4.5C17.3438 7 17.3438 10.0312 15.9062 12.5C14.4688 15 11.8438 16.5 9 16.5ZM7.75 11H8.5V9H7.75C7.3125 9 7 8.6875 7 8.25C7 7.84375 7.3125 7.5 7.75 7.5H9.25C9.65625 7.5 10 7.84375 10 8.25V11H10.25C10.6562 11 11 11.3438 11 11.75C11 12.1875 10.6562 12.5 10.25 12.5H7.75C7.3125 12.5 7 12.1875 7 11.75C7 11.3438 7.3125 11 7.75 11ZM9 6.5C8.4375 6.5 8 6.0625 8 5.5C8 4.96875 8.4375 4.5 9 4.5C9.53125 4.5 10 4.96875 10 5.5C10 6.0625 9.53125 6.5 9 6.5Z" fill="#788493" />
									</svg>
								</div>
							</TooltipWrapper>
						</div>
						{selectedResourceList && selectedResourceList.length > 0 &&
							<Button name={"Delete"} handleFunction={() => setIsMultiDeleteConfirmOpen(true)} className={'w-24 h-10 bg-red-medium hover:bg-red-medium dark:bg-red-medium border-red-medium dark:border-red-medium dark:text-white pt-2'} />
						}
					</div>
					<Table columns={columns} data={state.coPilotResourcesByUser} className={'manage-documents-content-container'} />
					<UserGroupAccessModal id={ROUTES_ID.SERVICE_PILOT} isOpen={isOpen} onClose={closeModal} onSave={onSave} data={selectedResourceData} userGroupList={state.userGroupList} />
				</main>
			</div>
			<BottomBar
				ButtonList={ButtonList}
				Component={
					<div className='flex gap-4 w-1/2'>
						<FileUpload appId={ROUTES_ID.SERVICE_PILOT} allowMultiple={true} folder={true} path={"co_pilot"} />
						<FileUpload appId={ROUTES_ID.SERVICE_PILOT} allowMultiple={true} path={"co_pilot"} />
					</div>
				} />
			<ConfirmPopup id={'sp_multi_delete_popup_div'} title={`Are you sure you want to delete ${selectedResourceList.length} file${selectedResourceList.length > 1 ? 's' : ''}?`} confirmLabel={"Delete"} isOpen={isMultiDeleteConfirmOpen} onConfirm={onMultiDelete} onClose={onCloseConfirm} />
			<ConfirmPopup id={'sp_delete_popup_div'} title={"Confirm Delete"} message={"Are you sure you want to delete this file?"} confirmLabel={"Delete"} isOpen={isDeleteConfirmOpen} onConfirm={onDelete} onClose={onCloseConfirm} />
		</>
	)
}

export default ManageDocuments;
