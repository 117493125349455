import React, { useEffect, useRef, useState } from 'react';
import { useStore } from '../../store/AppContext';
import ContentPicker from './ContentPicker';
import axios from 'axios';
import { API_KEY, API_URL, INTEGRATION_CREDENTIALS } from '../../config';
import { getPopupDimension } from './utils';
import { useIntegration } from '../../hooks/useIntegration';
import Cookies from "js-cookie";

const DEFAULT_LOGO = <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path fillRule="evenodd" clipRule="evenodd" d="M16.7876 38.2197C16.7876 34.7574 19.4064 31.9682 22.6598 31.9682C25.9132 31.9682 28.532 34.7574 28.532 38.2197V53.6083C28.532 57.0714 25.9132 59.8607 22.6598 59.8607C19.4064 59.8607 16.7876 57.0714 16.7876 53.6091V38.2197Z" fill="#E01E5A" />
	<path fillRule="evenodd" clipRule="evenodd" d="M22.6598 32.6073C19.7966 32.6073 17.4268 35.0721 17.4268 38.2197V53.6091C17.4268 56.7568 19.7966 59.2215 22.6598 59.2215C25.523 59.2215 27.8929 56.7568 27.8929 53.6083V38.2197C27.8929 35.0721 25.523 32.6073 22.6598 32.6073ZM16.1484 38.2197C16.1484 34.4428 19.0162 31.329 22.6598 31.329C26.3034 31.329 29.1712 34.4428 29.1712 38.2197V53.6083C29.1712 57.386 26.3034 60.4998 22.6598 60.4998C19.0162 60.4998 16.1484 57.3861 16.1484 53.6091V38.2197Z" fill="#E01E5A" />
	<path fillRule="evenodd" clipRule="evenodd" d="M37.7196 43.7126C34.2573 43.7126 31.468 41.0938 31.468 37.8404C31.468 34.587 34.2573 31.9682 37.7196 31.9682H53.109C56.5713 31.9682 59.3605 34.587 59.3605 37.8404C59.3605 41.0938 56.5713 43.7126 53.109 43.7126H37.7196Z" fill="#ECB22D" />
	<path fillRule="evenodd" clipRule="evenodd" d="M37.7196 32.6073C34.5719 32.6073 32.1072 34.9772 32.1072 37.8404C32.1072 40.7036 34.5719 43.0734 37.7196 43.0734H53.109C56.2566 43.0734 58.7214 40.7036 58.7214 37.8404C58.7214 34.9772 56.2566 32.6073 53.109 32.6073H37.7196ZM30.8289 37.8404C30.8289 34.1968 33.9426 31.329 37.7196 31.329H53.109C56.8859 31.329 59.9997 34.1968 59.9997 37.8404C59.9997 41.484 56.8859 44.3518 53.109 44.3518H37.7196C33.9426 44.3518 30.8289 41.484 30.8289 37.8404Z" fill="#ECB22D" />
	<path fillRule="evenodd" clipRule="evenodd" d="M31.468 7.39087C31.468 3.92859 34.0868 1.13934 37.3402 1.13934C40.5936 1.13934 43.2124 3.92859 43.2124 7.39087V22.7803C43.2124 26.2426 40.5936 29.0318 37.3402 29.0318C34.0868 29.0318 31.468 26.2426 31.468 22.7803V7.39087Z" fill="#2FB67C" />
	<path fillRule="evenodd" clipRule="evenodd" d="M37.3402 1.7785C34.477 1.7785 32.1072 4.24322 32.1072 7.39087V22.7803C32.1072 25.928 34.477 28.3927 37.3402 28.3927C40.2034 28.3927 42.5733 25.928 42.5733 22.7803V7.39087C42.5733 4.24322 40.2034 1.7785 37.3402 1.7785ZM30.8289 7.39087C30.8289 3.61395 33.6966 0.500175 37.3402 0.500175C40.9838 0.500175 43.8516 3.61396 43.8516 7.39087V22.7803C43.8516 26.5572 40.9838 29.671 37.3402 29.671C33.6966 29.671 30.8289 26.5572 30.8289 22.7803V7.39087Z" fill="#2FB67C" />
	<path fillRule="evenodd" clipRule="evenodd" d="M6.8907 29.0323C3.42841 29.0323 0.63916 26.4131 0.63916 23.1601C0.63916 19.9071 3.42841 17.2879 6.8907 17.2879H22.2801C25.7424 17.2879 28.5317 19.9067 28.5317 23.1601C28.5317 26.4135 25.7424 29.0323 22.2801 29.0323H6.8907Z" fill="#36C5F1" />
	<path fillRule="evenodd" clipRule="evenodd" d="M6.8907 17.927C3.74307 17.927 1.27832 20.2972 1.27832 23.1601C1.27832 26.0229 3.74307 28.3931 6.8907 28.3931H22.2801C25.4278 28.3931 27.8925 26.0233 27.8925 23.1601C27.8925 20.2969 25.4278 17.927 22.2801 17.927H6.8907ZM0 23.1601C0 19.5169 3.11375 16.6487 6.8907 16.6487H22.2801C26.057 16.6487 29.1708 19.5165 29.1708 23.1601C29.1708 26.8037 26.057 29.6714 22.2801 29.6714H6.8907C3.11375 29.6714 0 26.8032 0 23.1601Z" fill="#36C5F1" />
	<path fillRule="evenodd" clipRule="evenodd" d="M31.468 53.9884C31.468 57.2418 34.0868 59.8606 37.3402 59.8606C40.5936 59.8606 43.2124 57.2418 43.2124 53.9884C43.2124 50.735 40.5936 48.1162 37.3402 48.1162H31.468V53.9884Z" fill="#ECB22D" />
	<path fillRule="evenodd" clipRule="evenodd" d="M30.8289 47.4771H37.3402C40.9466 47.4771 43.8516 50.382 43.8516 53.9884C43.8516 57.5948 40.9466 60.4998 37.3402 60.4998C33.7338 60.4998 30.8289 57.5948 30.8289 53.9884V47.4771ZM32.1072 48.7554V53.9884C32.1072 56.8888 34.4398 59.2215 37.3402 59.2215C40.2406 59.2215 42.5733 56.8888 42.5733 53.9884C42.5733 51.088 40.2406 48.7554 37.3402 48.7554H32.1072Z" fill="#ECB22D" />
	<path fillRule="evenodd" clipRule="evenodd" d="M53.4887 29.0323H47.6165V23.1601C47.6165 19.9071 50.2353 17.2879 53.4887 17.2879C56.7421 17.2879 59.3609 19.9067 59.3609 23.1601C59.3609 26.4135 56.7421 29.0323 53.4887 29.0323Z" fill="#2FB67C" />
	<path fillRule="evenodd" clipRule="evenodd" d="M53.4887 17.927C50.5883 17.927 48.2556 20.26 48.2556 23.1601V28.3931H53.4887C56.3891 28.3931 58.7217 26.0605 58.7217 23.1601C58.7217 20.2597 56.3891 17.927 53.4887 17.927ZM46.9773 23.1601C46.9773 19.5541 49.8822 16.6487 53.4887 16.6487C57.0951 16.6487 60 19.5537 60 23.1601C60 26.7665 57.0951 29.6714 53.4887 29.6714H46.9773V23.1601Z" fill="#2FB67C" />
	<path fillRule="evenodd" clipRule="evenodd" d="M6.51137 31.9682H12.3836V37.8404C12.3836 41.0934 9.76477 43.7126 6.51137 43.7126C3.25797 43.7126 0.63916 41.0934 0.63916 37.8404C0.63916 34.5874 3.25797 31.9682 6.51137 31.9682Z" fill="#E01E5A" />
	<path fillRule="evenodd" clipRule="evenodd" d="M6.51137 32.6073C3.611 32.6073 1.27832 34.9403 1.27832 37.8404C1.27832 40.7404 3.611 43.0734 6.51137 43.0734C9.41174 43.0734 11.7444 40.7404 11.7444 37.8404V32.6073H6.51137ZM0 37.8404C0 34.2344 2.90494 31.329 6.51137 31.329H13.0227V37.8404C13.0227 41.4464 10.1178 44.3518 6.51137 44.3518C2.90495 44.3518 0 41.4464 0 37.8404Z" fill="#E01E5A" />
	<path fillRule="evenodd" clipRule="evenodd" d="M28.532 7.01155V12.8838H22.6598C19.4068 12.8838 16.7876 10.2649 16.7876 7.01155C16.7876 3.75815 19.4064 1.13934 22.6598 1.13934C25.9132 1.13934 28.532 3.75815 28.532 7.01155Z" fill="#36C5F1" />
	<path fillRule="evenodd" clipRule="evenodd" d="M22.6598 1.7785C19.7594 1.7785 17.4268 4.11115 17.4268 7.01155C17.4268 9.91192 19.7598 12.2446 22.6598 12.2446H27.8929V7.01155C27.8929 4.11115 25.5602 1.7785 22.6598 1.7785ZM16.1484 7.01155C16.1484 3.40515 19.0534 0.500175 22.6598 0.500175C26.2662 0.500175 29.1712 3.40515 29.1712 7.01155V13.5229H22.6598C19.0538 13.5229 16.1484 10.618 16.1484 7.01155Z" fill="#36C5F1" />
</svg>;

const COMING_SOON_LOGO = <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path fillRule="evenodd" clipRule="evenodd" d="M16.7876 37.7196C16.7876 34.2573 19.4064 31.468 22.6598 31.468C25.9132 31.468 28.532 34.2573 28.532 37.7196V53.1082C28.532 56.5713 25.9132 59.3605 22.6598 59.3605C19.4064 59.3605 16.7876 56.5713 16.7876 53.109V37.7196Z" fill="#B7C2D2" />
	<path fillRule="evenodd" clipRule="evenodd" d="M22.6598 32.1072C19.7966 32.1072 17.4268 34.5719 17.4268 37.7196V53.109C17.4268 56.2566 19.7966 58.7214 22.6598 58.7214C25.523 58.7214 27.8929 56.2567 27.8929 53.1082V37.7196C27.8929 34.5719 25.523 32.1072 22.6598 32.1072ZM16.1484 37.7196C16.1484 33.9426 19.0162 30.8289 22.6598 30.8289C26.3034 30.8289 29.1712 33.9426 29.1712 37.7196V53.1082C29.1712 56.8858 26.3034 59.9997 22.6598 59.9997C19.0162 59.9997 16.1484 56.8859 16.1484 53.109V37.7196Z" fill="#B7C2D2" />
	<path fillRule="evenodd" clipRule="evenodd" d="M37.7196 43.2124C34.2573 43.2124 31.468 40.5936 31.468 37.3402C31.468 34.0868 34.2573 31.468 37.7196 31.468H53.109C56.5713 31.468 59.3605 34.0868 59.3605 37.3402C59.3605 40.5936 56.5713 43.2124 53.109 43.2124H37.7196Z" fill="#576474" />
	<path fillRule="evenodd" clipRule="evenodd" d="M37.7196 32.1072C34.5719 32.1072 32.1072 34.477 32.1072 37.3402C32.1072 40.2034 34.5719 42.5733 37.7196 42.5733H53.109C56.2566 42.5733 58.7214 40.2034 58.7214 37.3402C58.7214 34.477 56.2566 32.1072 53.109 32.1072H37.7196ZM30.8289 37.3402C30.8289 33.6966 33.9426 30.8289 37.7196 30.8289H53.109C56.8859 30.8289 59.9997 33.6967 59.9997 37.3402C59.9997 40.9838 56.8859 43.8516 53.109 43.8516H37.7196C33.9426 43.8516 30.8289 40.9838 30.8289 37.3402Z" fill="#576474" />
	<path fillRule="evenodd" clipRule="evenodd" d="M31.468 6.8907C31.468 3.42841 34.0868 0.63916 37.3402 0.63916C40.5936 0.63916 43.2124 3.42841 43.2124 6.8907V22.2801C43.2124 25.7424 40.5936 28.5317 37.3402 28.5317C34.0868 28.5317 31.468 25.7424 31.468 22.2801V6.8907Z" fill="#B7C2D2" />
	<path fillRule="evenodd" clipRule="evenodd" d="M37.3402 1.27832C34.477 1.27832 32.1072 3.74304 32.1072 6.8907V22.2801C32.1072 25.4278 34.477 27.8925 37.3402 27.8925C40.2034 27.8925 42.5733 25.4278 42.5733 22.2801V6.8907C42.5733 3.74304 40.2034 1.27832 37.3402 1.27832ZM30.8289 6.8907C30.8289 3.11378 33.6966 0 37.3402 0C40.9838 0 43.8516 3.11378 43.8516 6.8907V22.2801C43.8516 26.057 40.9838 29.1708 37.3402 29.1708C33.6966 29.1708 30.8289 26.057 30.8289 22.2801V6.8907Z" fill="#B7C2D2" />
	<path fillRule="evenodd" clipRule="evenodd" d="M6.8907 28.5321C3.42841 28.5321 0.63916 25.9129 0.63916 22.6599C0.63916 19.4069 3.42841 16.7877 6.8907 16.7877H22.2801C25.7424 16.7877 28.5317 19.4065 28.5317 22.6599C28.5317 25.9133 25.7424 28.5321 22.2801 28.5321H6.8907Z" fill="#576474" />
	<path fillRule="evenodd" clipRule="evenodd" d="M6.8907 17.4269C3.74307 17.4269 1.27832 19.7971 1.27832 22.6599C1.27832 25.5228 3.74307 27.893 6.8907 27.893H22.2801C25.4278 27.893 27.8925 25.5231 27.8925 22.6599C27.8925 19.7967 25.4278 17.4269 22.2801 17.4269H6.8907ZM0 22.6599C0 19.0168 3.11375 16.1486 6.8907 16.1486H22.2801C26.057 16.1486 29.1708 19.0163 29.1708 22.6599C29.1708 26.3035 26.057 29.1713 22.2801 29.1713H6.8907C3.11375 29.1713 0 26.3031 0 22.6599Z" fill="#576474" />
	<path fillRule="evenodd" clipRule="evenodd" d="M31.468 53.4883C31.468 56.7417 34.0868 59.3605 37.3402 59.3605C40.5936 59.3605 43.2124 56.7417 43.2124 53.4883C43.2124 50.2349 40.5936 47.6161 37.3402 47.6161H31.468V53.4883Z" fill="#576474" />
	<path fillRule="evenodd" clipRule="evenodd" d="M30.8289 46.9769H37.3402C40.9466 46.9769 43.8516 49.8819 43.8516 53.4883C43.8516 57.0947 40.9466 59.9997 37.3402 59.9997C33.7338 59.9997 30.8289 57.0947 30.8289 53.4883V46.9769ZM32.1072 48.2552V53.4883C32.1072 56.3887 34.4398 58.7213 37.3402 58.7213C40.2406 58.7213 42.5733 56.3887 42.5733 53.4883C42.5733 50.5879 40.2406 48.2552 37.3402 48.2552H32.1072Z" fill="#576474" />
	<path fillRule="evenodd" clipRule="evenodd" d="M53.4887 28.5321H47.6165V22.6599C47.6165 19.4069 50.2353 16.7877 53.4887 16.7877C56.7421 16.7877 59.3609 19.4065 59.3609 22.6599C59.3609 25.9133 56.7421 28.5321 53.4887 28.5321Z" fill="#B7C2D2" />
	<path fillRule="evenodd" clipRule="evenodd" d="M53.4887 17.4269C50.5883 17.4269 48.2556 19.7599 48.2556 22.6599V27.893H53.4887C56.3891 27.893 58.7217 25.5603 58.7217 22.6599C58.7217 19.7595 56.3891 17.4269 53.4887 17.4269ZM46.9773 22.6599C46.9773 19.054 49.8822 16.1486 53.4887 16.1486C57.0951 16.1486 60 19.0535 60 22.6599C60 26.2663 57.0951 29.1713 53.4887 29.1713H46.9773V22.6599Z" fill="#B7C2D2" />
	<path fillRule="evenodd" clipRule="evenodd" d="M6.51137 31.468H12.3836V37.3402C12.3836 40.5932 9.76477 43.2124 6.51137 43.2124C3.25797 43.2124 0.63916 40.5932 0.63916 37.3402C0.63916 34.0872 3.25797 31.468 6.51137 31.468Z" fill="#B7C2D2" />
	<path fillRule="evenodd" clipRule="evenodd" d="M6.51137 32.1072C3.611 32.1072 1.27832 34.4402 1.27832 37.3402C1.27832 40.2403 3.611 42.5733 6.51137 42.5733C9.41174 42.5733 11.7444 40.2403 11.7444 37.3402V32.1072H6.51137ZM0 37.3402C0 33.7343 2.90494 30.8289 6.51137 30.8289H13.0227V37.3402C13.0227 40.9462 10.1178 43.8516 6.51137 43.8516C2.90495 43.8516 0 40.9462 0 37.3402Z" fill="#B7C2D2" />
	<path fillRule="evenodd" clipRule="evenodd" d="M28.532 6.51137V12.3836H22.6598C19.4068 12.3836 16.7876 9.76477 16.7876 6.51137C16.7876 3.25797 19.4064 0.63916 22.6598 0.63916C25.9132 0.63916 28.532 3.25797 28.532 6.51137Z" fill="#576474" />
	<path fillRule="evenodd" clipRule="evenodd" d="M22.6598 1.27832C19.7594 1.27832 17.4268 3.61097 17.4268 6.51137C17.4268 9.41174 19.7598 11.7444 22.6598 11.7444H27.8929V6.51137C27.8929 3.61097 25.5602 1.27832 22.6598 1.27832ZM16.1484 6.51137C16.1484 2.90497 19.0534 0 22.6598 0C26.2662 0 29.1712 2.90497 29.1712 6.51137V13.0227H22.6598C19.0538 13.0227 16.1484 10.1178 16.1484 6.51137Z" fill="#576474" />
</svg>;

const Slack = ({ comingSoon, appId }) => {
	const integrationHook = useIntegration();
	const slackRef = useRef(true);
	const { state, dispatch } = useStore();
	const [enableRevokeIcon, setEnableRevokeIcon] = useState(false);
	const [openPicker, setOpenPicker] = useState(false);
	const [channelsData, setChannelsData] = useState({
		logo: DEFAULT_LOGO,
		title: 'Channels',
		contents: []
	})

	useEffect(() => {
		setOpenPicker(channelsData && channelsData.contents && (channelsData.contents.length > 0));
	}, [channelsData])

	const onClick = () => {
		if (state.integrations && state.integrations['slack']) {
			getChannels();
		} else {
			slackRef.current = true;
			window.open(`https://slack.com/oauth/v2/authorize?client_id=${INTEGRATION_CREDENTIALS.slack.clientId}&scope=channels:history,channels:read&redirect_uri=${INTEGRATION_CREDENTIALS.slack.redirectURL}`, 'AuthWindow', getPopupDimension());

			const messageListener = (event) => {
				if (event.target.origin !== window.location.origin) {
					return;
				}

				const data = event.data;
				if (slackRef.current && data) {
					slackRef.current = false;
					if (data.error) {
						dispatch({
							type: "SET_MESSAGE",
							payload: {
								type: "fail",
								title: "Authorization Failed",
								subtitle: "Please try again!"
							}
						})
					}

					if (data.code) {
						const req = {
							integration: 'slack',
							url: 'https://slack.com/api/oauth.v2.access',
							data: {
								code: data.code,
								client_id: INTEGRATION_CREDENTIALS.slack.clientId,
								client_secret: INTEGRATION_CREDENTIALS.slack.clientSecret,
								redirect_uri: INTEGRATION_CREDENTIALS.slack.redirectURL,
							},
							headers: {
								"content-type": 'application/x-www-form-urlencoded'
							}
						}
						integrationHook.saveOauth(req);
					}
				}
				window.removeEventListener('message', messageListener);
			};

			window.addEventListener('message', messageListener);
		}
	}

	const getChannels = () => {
		const config = state.integrations['slack'];
		dispatch({
			type: "LOADING",
			payload: true
		})
		axios.get(`${API_URL}/integration/slack/list-channels`, { headers: { Authorization: Cookies.get("token"), apikey: API_KEY, oauth_key: config.oauth_key } })
			.then(res => {
				console.log(res.data)
				const data = {
					...channelsData,
					contents: res.data && res.data.channels ? res.data.channels : []
				}
				setChannelsData(data);
				dispatch({
					type: "LOADING",
					payload: false
				})
			})
			.catch(() => {
				dispatch({
					type: "LOADING",
					payload: false
				})
			})
	}

	const handlePick = (pickedContents) => {
		const config = state.integrations['slack'];
		const req = {
			appId: appId,
			channel_ids: pickedContents
		}
		integrationHook.storeIntegrationData('slack', config.oauth_key, req);
		onPickerClose();
	}

	const onPickerClose = () => {
		setChannelsData({
			logo: DEFAULT_LOGO,
			title: 'Channels',
			contents: []
		});
	}

	const handleRevoke = () => {
		const config = state.integrations['slack'];
		const req = {
			hasRevokeAPI: true,
			integration: 'slack',
			url: 'https://slack.com/api/auth.revoke',
			data: {
				token: config.oauth_key
			},
			headers: {
				"content-type": 'application/x-www-form-urlencoded'
			}
		}
		integrationHook.revokeIntegrationData(req, setEnableRevokeIcon);
	}

	return (
		<div>
			<button disabled={comingSoon} onClick={() => !enableRevokeIcon && onClick()} className={`flex flex-col relative justify-center items-center w-[150px] h-[150px] rounded-xl px-2 py-5 gap-5 ${comingSoon ? 'bg-white-gray dark:bg-gray-light-200' : 'bg-white-gray-600 dark:bg-black'}`}>
				{state.integrations && state.integrations['slack'] &&
					<div className='w-[150px] h-[150px] absolute flex justify-end items-start pr-2 pt-2'>
						<div className='dark:text-gray-dark text-white' onClick={() => enableRevokeIcon && handleRevoke()} onMouseOver={() => setEnableRevokeIcon(true)} onMouseLeave={() => setEnableRevokeIcon(false)}>
							{enableRevokeIcon ?
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="-4 4 48 48">
									<path fill="#e75670" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path>
									<path fill="currentColor" d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z"></path>
									<path fill="currentColor" d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z"></path>
								</svg>
								:
								<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<rect width="20" height="20" rx="10" fill="#2BB86C" />
									<path d="M15.0859 6.91406C15.2969 7.14844 15.2969 7.5 15.0859 7.71094L8.89844 13.8984C8.66406 14.1328 8.3125 14.1328 8.10156 13.8984L4.91406 10.7109C4.67969 10.5 4.67969 10.1484 4.91406 9.9375C5.125 9.70312 5.47656 9.70312 5.6875 9.9375L8.47656 12.7266L14.2891 6.91406C14.5 6.70312 14.8516 6.70312 15.0625 6.91406H15.0859Z" fill="currentColor" />
								</svg>
							}
						</div>
					</div>
				}
				{comingSoon ? COMING_SOON_LOGO : DEFAULT_LOGO}
				<div className='flex flex-col justify-center items-center'>
					<p className={`${comingSoon ? 'text-gray-light' : 'text-gray-dark dark:text-white'} font-medium`}>Slack</p>
					{comingSoon && <p className='text-gray-dark dark:text-white text-xs font-normal mt-1'>Coming Soon</p>}
				</div>
			</button>
			<ContentPicker integrationId={'slack'} fieldId={'id'} fieldName={'name'} data={channelsData} handlePick={handlePick} isOpen={openPicker} onClose={onPickerClose} />
		</div>
	)
}

export default Slack;
