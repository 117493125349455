import React, { useEffect, useState } from 'react';
import { InputWrapper } from '../InputWrapper';

const LDAP = ({ containerStyle, authConfig, setAuthConfig, setCanSave, setShowToast }) => {
    const [ldapData, setLdapData] = useState(authConfig && authConfig.value ? authConfig.value : {});
    const [disabledFields, setDisabledFileds] = useState(false);

    useEffect(() => {
        setDisabledFileds(authConfig && authConfig.type && (authConfig.type !== 'ldap'));
    }, [authConfig])

    useEffect(() => {
        if (Object.keys(ldapData).length !== 0) {
            if (ldapData.server || ldapData.port || ldapData.binddn || ldapData.password)
                setAuthConfig({ type: 'ldap', label: 'LDAP', value: ldapData });
            else
                setAuthConfig({ type: null, label: null, value: null });
        }

        setCanSave(ldapData.server && ldapData.port && ldapData.binddn && ldapData.password);
    }, [ldapData])

    const onChange = (e) => {
        setLdapData((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
    }

    return (
        <div className={`bg-white-gray-500 dark:bg-gray-black p-6 rounded-2xl items-center self-stretch ${containerStyle}`}>
            <p className="mb-3 py-3 text-gray-dark dark:text-white text-lg font-semibold text-center">LDAP</p>
            <form onChange={onChange} onClick={() => disabledFields && setShowToast(true)}>
                <InputWrapper value={authConfig && authConfig.value && authConfig.value.server ? authConfig.value.server : ''} label={"Server"} name={"server"} type={"text"} disabled={disabledFields} containerStyle={"mb-4"} />
                <InputWrapper value={authConfig && authConfig.value && authConfig.value.port ? authConfig.value.port : ''} label={"Port"} name={"port"} type={"text"} disabled={disabledFields} containerStyle={"mb-4"} />
                <InputWrapper value={authConfig && authConfig.value && authConfig.value.binddn ? authConfig.value.binddn : ''} label={"Bind DN"} name={"binddn"} type={"text"} disabled={disabledFields} containerStyle={"mb-4"} />
                <InputWrapper value={authConfig && authConfig.value && authConfig.value.password ? authConfig.value.password : ''} label={"Password"} name={"password"} type={"password"} disabled={disabledFields} containerStyle={"mb-4"} autoComplete={"new-password"} />
            </form>
        </div>
    )
}

export default LDAP;
