import React from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Button = ({ handleFunction, name, className, disabled, type }) => {
    return (
        <button
            type="button"
            onClick={handleFunction}
            disabled={disabled}
            className={`${type === 'outline' ? `border-2 ${disabled ? "border-gray-light bg-gray-light text-white" : "bg-none border-blue-dark text-blue-dark hover:bg-blue hover:text-white "}` : "bg-blue border-2 border-blue text-white hover:bg-blue-400 hover:border-blue-400 disabled:border-gray-light hover:disabled:border-gray-light"} font-bold px-6 py-3 rounded-lg focus:outline-none disabled:bg-gray-light ${className}`}
        >
            {name}
        </button>
    )
}

export const AddButton = ({ label, addHandleFunction }) => {
    return (
        <button className='flex justify-center items-center text-blue my-3 hover:border-b-2 hover:border-blue pb-1.5' onClick={addHandleFunction}>
            <div className='rounded-full relative flex justify-center items-center border-2 h-4 w-4 text-xs border-blue'>
                <FontAwesomeIcon icon={faPlus} className="w-2 h-2 font-bold" />
            </div>
            <div className='ml-2 text-base font-bold'>
                {label}
            </div>
        </button>
    )
}

export const TextButton = ({ label, icon, addHandleFunction, className, labelClassName }) => {
    return (
        <button className={`text-gray-dark-500 dark:text-white flex justify-center items-center ${className}`} onClick={addHandleFunction}>
            {icon && icon}
            <div className={`text-blue ml-2 text-base font-bold ${labelClassName}`}>
                {label}
            </div>
        </button>
    )
}


export const IconButton = ({ icon, label, addHandleFunction, className }) => {
    return (
        <button className={`flex justify-center items-center bg-none round border-blue border-2 hover:bg-blue hover:text-white text-blue font-bold px-6 py-3 rounded-lg focus:outline-none focus:border-blue disabled:bg-gray ${className}`} onClick={addHandleFunction}>
            <div className='rounded-full relative flex justify-center items-center border-2 h-4 w-4 text-xs border-blue hover:border-blue-700'>
                <FontAwesomeIcon icon={icon} className="w-2 h-2 font-bold" />
            </div>
            <div className='ml-2 text-base font-bold'>
                {label}
            </div>
        </button>
    )
}
