import React from 'react';

const TooltipWrapper = ({ content, children, tootTipStyle, className, autoHide, isShowArrow, onTop }) => {
	const [isTooltipVisible, setIsTooltipVisible] = React.useState(false);

	const handleMouseEnter = () => {
		setIsTooltipVisible(true);
		autoHide && setTimeout(() => {
			setIsTooltipVisible(false);
		}, 1500);

	};

	const handleMouseDown = () => {
		setIsTooltipVisible(false);
	};

	const handleMouseLeave = () => {
		setIsTooltipVisible(false);
	};

	return (
		<div className={`relative ${className}`}>
			<div
				onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}
				onMouseDown={handleMouseDown}
				data-tooltip-target={isShowArrow ? "tooltip-default" : "cursor-help"}
			>
				{children}
			</div>
			{isTooltipVisible && (
				<>
					<div className={`absolute z-40 bg-white-gray-500 dark:bg-gray-dark border border-gray-800 dark:border-gray text-gray-dark dark:text-white p-2 text-xs rounded-md shadow-md ${isShowArrow ? (onTop ? '-mt-20 -left-4' : '-left-4 mt-4') : (onTop ? '-mt-20' : '')} ${tootTipStyle}`}>
						{content}
					</div>
					{isShowArrow &&
						<>
							{onTop ?
								<>
									<div className="w-0 h-0
										absolute -mt-8
										border-l-[15px] border-l-transparent
										border-t-[16px] border-t-gray-800 dark:border-t-gray
										z-40
										border-r-[16px] border-r-transparent"/>
									<div className="w-0 h-0
										absolute -mt-8
										border-l-[15px] border-l-transparent
										border-t-[15px] border-t-white-gray-500 dark:border-t-gray-dark
										z-40
										border-r-[15px] border-r-transparent"/>
								</>
								:
								<>
									<div className="w-0 h-0
										absolute -left-1 mt-0.4
										border-l-[15px] border-l-transparent
										border-b-[16px] border-b-gray-800 dark:border-b-gray
										z-40
										border-r-[16px] border-r-transparent"/>
									<div className="w-0 h-0
										absolute -left-1 mt-0.5
										border-l-[15px] border-l-transparent
										border-b-[15px] border-b-white-gray-500 dark:border-b-gray-dark
										z-40
										border-r-[15px] border-r-transparent"/>
								</>
							}
						</>
					}
				</>
			)}
		</div>
	);
};

export default TooltipWrapper;
