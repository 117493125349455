import axios from "axios";
import Cookies from "js-cookie";
import { API_URL, API_KEY } from "../config";
import { useStore } from '../store/AppContext';

export const useCoPilot = () => {
    const { dispatch } = useStore();

    return {
        getEmails: (pageNum, pageSize) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })

            axios
                .get(
                    `${API_URL}/application/co_polit/emails?pageNum=${pageNum}&pageSize=${pageSize}`,
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        }
                    }
                )
                .then((res) => {
                    dispatch({
                        type: "SET_CO_PILOT_EMAILS",
                        payload: res.data
                    })

                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Emails fetch Failed",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                })
        },
        fetchSummary_streaming: async (data) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })
            dispatch({
                type: "SET_CO_PILOT_STREAMING",
                payload: true,
            })
            try {
                const response = await fetch(`${API_URL}/application/co_polit/summary`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: Cookies.get("token"),
                        apikey: API_KEY,
                    },
                    body: JSON.stringify(data),
                })
                const reader = response.body.getReader();

                let text = '';
                let resData = '';
                while (true) {
                    const { done, value } = await reader.read();
                    resData = String.fromCharCode.apply(null, value);

                    if (done) {
                        dispatch({
                            type: "SET_CO_PILOT_INFERENCE",
                            payload: 'suggested_reply'
                        })
                        break;
                    }

                    text += resData
                    dispatch({
                        type: "SET_CO_PILOT_SUMMARY",
                        payload: { summary_response: text }
                    })
                }
                dispatch({
                    type: "LOADING",
                    payload: false,
                })
            } catch (err) {
                dispatch({
                    type: "LOADING",
                    payload: false
                })
                dispatch({
                    type: "SET_CO_PILOT_STREAMING",
                    payload: false,
                })
                dispatch({
                    type: "SET_MESSAGE",
                    payload: {
                        type: "fail",
                        title: "Failed to get response from server",
                        subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                    }
                })
            }
        },
        fetchSuggestedReply_streaming: async (data) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })
            dispatch({
                type: "SET_CO_PILOT_STREAMING",
                payload: true,
            })
            try {
                const response = await fetch(`${API_URL}/application/email_copilot_new`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: Cookies.get("token"),
                        apikey: API_KEY,
                    },
                    body: JSON.stringify(data),
                })
    
                const reader = response.body.getReader();
                
                let highlights_text = '';
                let resData = ''
    
                while (true) {
                    const { done, value } = await reader.read();
                    resData = String.fromCharCode.apply(null, value);
    
                    if (done) {
                        dispatch({
                            type: "SET_CO_PILOT_STREAMING",
                            payload: false,
                        })
                        dispatch({
                            type: "SET_CO_PILOT_INFERENCE",
                            payload: null
                        })
                        break;
                    }
    
                    highlights_text += resData
                    dispatch({
                        type: "SET_CO_PILOT_RESPONSE_EMAIL_RESPONSE",
                        payload: highlights_text
                    })
                }
                dispatch({
                    type: "LOADING",
                    payload: false,
                })
            } catch (err) {
                dispatch({
                    type: "LOADING",
                    payload: false
                })
                dispatch({
                    type: "SET_CO_PILOT_STREAMING",
                    payload: false,
                })
                dispatch({
                    type: "SET_MESSAGE",
                    payload: {
                        type: "fail",
                        title: "Failed to get response from server",
                        subtitle: "Please try again"
                    }
                })
            }
        },
        sendEmail: (data, navigate, navLink) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })
            axios
                .post(
                    `${API_URL}/application/co_polit/send_email`,
                    data,
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        }
                    }
                )
                .then((res) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })

                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "success",
                            title: "Sent Successfully",
                            subtitle: "Your mail have been sent successfully"
                        }
                    })

                    navLink && navigate(navLink);
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Failed on send mail",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })

                })
        },
        clearData: () => {
            dispatch({
                type: "CLEAR_CO_PILOT_DATA",
            })
        },
        getDocResourcesByUser: () => {
            dispatch({
                type: "LOADING",
                payload: true,
            })
            axios
                .get(
                    `${API_URL}/application/co-pilot/get_doc_data`,
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        }
                    }
                )
                .then((res) => {
                    if (res.data) {
                        dispatch({
                            type: "SET_CO_PILOT_RESOURCES_BY_USER",
                            payload: res.data,
                        })
                    }
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Failed on get documents",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                })
        },
        updateDocUserPermission: (request) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })
            axios
                .post(
                    `${API_URL}/application/co-pilot/update_doc`,
                    request,
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        }
                    }
                )
                .then((res) => {
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "success",
                            title: "Permission Updated!",
                            subtitle: "Document permission updated successfully"
                        }
                    })
                    dispatch({
                        type: "UPDATE_CO_PILOT_DOC_USER_PERMISSION",
                        payload: res.data,
                    })
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Failed to update user group",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                })
        },
        deleteDocuments: (request) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })
            axios
                .post(`${API_URL}/application/co-pilot/doc_delete`,
                    request,
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        }
                    }
                )
                .then((res) => {
                    if (res.data && res.data.status === 'success') {
                        dispatch({
                            type: "SET_MESSAGE",
                            payload: {
                                type: "success",
                                title: "Delete Success",
                                subtitle: "Your document deleted successfully"
                            }
                        })
                        dispatch({
                            type: "DELETE_CO_PILOT_DOC_RESOURCES",
                            payload: res.data.file_info,
                        })
                    } else {
                        dispatch({
                            type: "SET_MESSAGE",
                            payload: {
                                type: "fail",
                                title: "Failed to delete file",
                                subtitle: res.data && res.data.error ? res.data.error : "Please try again"
                            }
                        })
                    }

                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Failed to delete file",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                })
        }
    }
}