import React, { useEffect, useState } from 'react';
import { useStore } from '../../store/AppContext';
import AuthInfo from './AuthInfo';
import ContentPicker from './ContentPicker';
import axios from 'axios';
import { useIntegration } from '../../hooks/useIntegration';

const DEFAULT_LOGO = <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
	<g clipPath="url(#clip0_3124_2354)">
		<path d="M43.1443 43.6134V58.9227C43.1443 59.7268 42.4948 60.3763 41.6907 60.3763H1.45361C0.649484 60.3763 0 59.7268 0 58.9227V18.7166C0 17.9124 0.649484 17.2629 1.45361 17.2629H16.7319V42.1598C16.7319 42.9639 17.3814 43.6134 18.1855 43.6134H43.1443Z" fill="#4C00FF" />
		<path d="M59.9072 22.0258C59.9072 34.8918 52.3918 43.5825 43.1443 43.6134V27.4073C43.1443 26.9433 42.9588 26.4794 42.6186 26.1702L34.2062 17.7578C33.866 17.4176 33.433 17.232 32.9691 17.232H16.7629V1.95369C16.7629 1.14957 17.4124 0.500083 18.2165 0.500083H40.8866C51.6495 0.469155 59.9072 9.12894 59.9072 22.0258Z" fill="#FF5252" />
		<path d="M42.6186 26.1392C42.9588 26.4794 43.1443 26.9124 43.1443 27.3763V43.5825H18.2165C17.4124 43.5825 16.7629 42.933 16.7629 42.1289V17.232H32.9691C33.433 17.232 33.8969 17.4176 34.2062 17.7578L42.6186 26.1392Z" fill="black" />
	</g>
	<defs>
		<clipPath id="clip0_3124_2354">
			<rect width="59.9999" height="60" fill="white" transform="translate(0 0.5)" />
		</clipPath>
	</defs>
</svg>;

const COMING_SOON_LOGO = <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
	<g clipPath="url(#clip0_3256_5986)">
		<path d="M43.1443 43.6132V58.9225C43.1443 59.7266 42.4948 60.3761 41.6907 60.3761H1.45361C0.649484 60.3761 0 59.7266 0 58.9225V18.7163C0 17.9122 0.649484 17.2627 1.45361 17.2627H16.732V42.1596C16.732 42.9637 17.3814 43.6132 18.1856 43.6132H43.1443Z" fill="#B7C2D2" />
		<path d="M59.9073 22.0259C59.9073 34.8918 52.3918 43.5826 43.1444 43.6135V27.4073C43.1444 26.9434 42.9588 26.4795 42.6186 26.1702L34.2062 17.7578C33.866 17.4176 33.433 17.232 32.9691 17.232H16.7629V1.95369C16.7629 1.14957 17.4124 0.500083 18.2165 0.500083H40.8867C51.6495 0.469155 59.9073 9.12895 59.9073 22.0259Z" fill="#B7C2D2" />
		<path d="M42.6186 26.1396C42.9588 26.4798 43.1444 26.9128 43.1444 27.3768V43.5829H18.2165C17.4124 43.5829 16.7629 42.9335 16.7629 42.1293V17.2324H32.9691C33.433 17.2324 33.897 17.418 34.2062 17.7582L42.6186 26.1396Z" fill="#576474" />
	</g>
	<defs>
		<clipPath id="clip0_3256_5986">
			<rect width="60" height="60" fill="white" transform="translate(0 0.5)" />
		</clipPath>
	</defs>
</svg>;

const DEFAULT_DATA = {
	logo: DEFAULT_LOGO,
	title: 'Enter Account Id',
	fields: [
		{
			key: 'accountId',
			label: 'Account Id',
			value: '',
			type: 'text'
		}
	]
};

const Docusign = ({ comingSoon }) => {
	const integrationHook = useIntegration();
	const { state, dispatch } = useStore();
	const [enableRevokeIcon, setEnableRevokeIcon] = useState(false);
	const [isOpen, setOpen] = useState(false);
	const [data, setData] = useState(DEFAULT_DATA);
	const [openPicker, setOpenPicker] = useState(false);
	const [workspacesData, setWorkspacesData] = useState({
		logo: DEFAULT_LOGO,
		title: 'Workspaces',
		contents: []
	});

	useEffect(() => {
		if (state.config && state.config.integrations && state.config.integrations['docusign']) {
			let currentData = state.config.integrations['docusign'];

			let newData = data;
			newData.fields = data.fields.map(f => {
				f.value = currentData[f.key];
				return f;
			})

			setData(newData);
		}
	}, [state.config && state.config.integrations])

	useEffect(() => {
		setOpenPicker(workspacesData && workspacesData.contents && (workspacesData.contents.length > 0));
	}, [workspacesData])

	const onClick = () => {
		if (state.config && state.config.integrations && state.config.integrations['docusign']) {
			getAccessToken();
		} else {
			setOpen(true);
		}
	}

	const getAccessToken = () => {
		const clientId = '58bb098a-683c-4f21-aa7d-7e0545e459b4';
		const clientSecret = 'c9628164-d904-43e1-ab47-9c579a030cb2';
		const defaultRefreshToken = 'eyJ0eXAiOiJNVCIsImFsZyI6IlJTMjU2Iiwia2lkIjoiNjgxODVmZjEtNGU1MS00Y2U5LWFmMWMtNjg5ODEyMjAzMzE3In0.AQoAAAABAAgABwAAaSac6obcSAgAAOmKlH2e3EgCAPrpN8kekuJBpatvCvKwH40VAAEAAAAYAAIAAAAFAAAAAQEAAA0AJAAAADU4YmIwOThhLTY4M2MtNGYyMS1hYTdkLTdlMDU0NWU0NTliNCIAJAAAADU4YmIwOThhLTY4M2MtNGYyMS1hYTdkLTdlMDU0NWU0NTliNDAAgJc1k-qG3Eg3ABrfV4FLwYRHjwpwwOX7Ia8.zMtkIGEFpTPJuXdBh7L--ba0OQrmJTV9jyT-LqsnitjhL-O0ZTT8vUy1aeZOXI6FqhusdaJ-zJy29PhaeDVAV3NBbFfwJs3B9HtylR7iAdNUsjSrN7uoY3urMd_ifYSxa__ri0902yIOyF3OlQHEiPUqu9Ub3jKKx0eohw7MHpjKKHDy-Z9-y8RFLJdLJPezQPg8TpQ-Nevo6CacqwAycx41YJA4so5vnSAIkgeQQlc4PXpBqyY59HJl1zwQjIYLEHK0TgLP51KY2OWbDdXAM3z79YDAKrKAc-48NdipTzPqtkQbSiyxUr-p3VjrvHwIVA6NjKpn4KQNx-BZKqIiSg';
		const refreshToken = localStorage.docusignRefreshToken ? localStorage.docusignRefreshToken : defaultRefreshToken;

		dispatch({
			type: "LOADING",
			payload: true
		})

		const tokenUrl = 'https://account-d.docusign.com/oauth/token';
		axios.post(tokenUrl, new URLSearchParams({
			grant_type: 'refresh_token',
			refresh_token: refreshToken,
			client_id: clientId,
			client_secret: clientSecret
		}), {
			headers: {
				'Accept': '*/*',
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': true
			}
		})
			.then(res => {
				const { access_token, refresh_token } = res.data;
				localStorage.setItem('docusignRefreshToken', refresh_token);
				getWorkspaces(access_token);
			})
			.catch(() => {
				dispatch({
					type: "LOADING",
					payload: false
				})
			})
	}

	const getWorkspaces = (accesstoken) => {
		const config = state.config.integrations['docusign'];
		axios.get(`https://demo.docusign.net/restapi/v2.1/accounts/${config.accountId}/workspaces`, {
			headers: {
				'Authorization': `Bearer ${accesstoken}`,
				'Accept': '*/*',
				'Content-Type': 'application/json'
			}
		})
			.then(res => {
				const data = {
					...workspacesData,
					contents: res.data && res.data.workspaces ? res.data.workspaces : []
				}
				setWorkspacesData(data);
				dispatch({
					type: "LOADING",
					payload: false
				})
			})
			.catch(() => {
				dispatch({
					type: "LOADING",
					payload: false
				})
			})
	}

	const handlePick = (pickedContents) => {
		const config = state.config.integrations['docusign'];
		const req = {
			workspace_ids: pickedContents,
			access_token: config.accessToken
		}
		onPickerClose();
	}

	const onClose = () => {
		setOpen(false);
	}

	const onPickerClose = () => {
		setWorkspacesData({
			logo: DEFAULT_LOGO,
			title: 'Workspaces',
			contents: []
		});
	}

	const handleRevoke = () => {
		integrationHook.revokeIntegrationData('docusign', setEnableRevokeIcon);
	}

	return (
		<div>
			<button disabled={comingSoon} onClick={() => !enableRevokeIcon && onClick()} className={`flex flex-col relative justify-center items-center w-[150px] h-[150px] rounded-xl px-2 py-5 gap-5 ${comingSoon ? 'bg-white-gray dark:bg-gray-light-200' : 'bg-white-gray-600 dark:bg-black'}`}>
				{state.config && state.config.integrations && state.config.integrations['docusign'] &&
					<div className='w-[150px] h-[150px] absolute flex justify-end items-start pr-2 pt-2'>
						<div className='dark:text-gray-dark text-white' onClick={() => enableRevokeIcon && handleRevoke()} onMouseOver={() => setEnableRevokeIcon(true)} onMouseLeave={() => setEnableRevokeIcon(false)}>
							{enableRevokeIcon ?
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="-4 4 48 48">
									<path fill="#e75670" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path>
									<path fill="currentColor" d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z"></path>
									<path fill="currentColor" d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z"></path>
								</svg>
								:
								<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<rect width="20" height="20" rx="10" fill="#2BB86C" />
									<path d="M15.0859 6.91406C15.2969 7.14844 15.2969 7.5 15.0859 7.71094L8.89844 13.8984C8.66406 14.1328 8.3125 14.1328 8.10156 13.8984L4.91406 10.7109C4.67969 10.5 4.67969 10.1484 4.91406 9.9375C5.125 9.70312 5.47656 9.70312 5.6875 9.9375L8.47656 12.7266L14.2891 6.91406C14.5 6.70312 14.8516 6.70312 15.0625 6.91406H15.0859Z" fill="currentColor" />
								</svg>
							}
						</div>
					</div>
				}
				{comingSoon ? COMING_SOON_LOGO : DEFAULT_LOGO}
				<div className='flex flex-col justify-center items-center'>
					<p className={`${comingSoon ? 'text-gray-light' : 'text-gray-dark dark:text-white'} font-medium`}>Docusign</p>
					{comingSoon && <p className='text-gray-dark dark:text-white text-xs font-normal mt-1'>Coming Soon</p>}
				</div>
			</button>
			<AuthInfo integrationId={'docusign'} data={data} isOpen={isOpen} onClose={onClose} />
			<ContentPicker integrationId={'docusign'} fieldId={'workspaceId'} fieldName={'workspaceName'} data={workspacesData} handlePick={handlePick} isOpen={openPicker} onClose={onPickerClose} />
		</div>
	)
}

export default Docusign;
