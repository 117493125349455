import React, { useEffect, useRef, useState } from 'react'
import { ChatInput } from '../../components/InputWrapper';
import { useStore } from '../../store/AppContext';
import { ChatLoading, ChatMessage } from '../../components/DocSearch/ChatMessage';
import { SidePanelDocSearch } from '../../components/DocSearch/SidePanel';
import AskQuestionModal from '../../components/DocSearch/AskQuestionModal';
import { useDocSearch } from '../../hooks/useDocSearch';
import { scrollToBottom } from '../../components/DocSearch/utils';
import { CheckBox } from '../../components/CheckBox';
import TooltipWrapper from '../../components/TooltipWrapper';
import SourceViewModal from '../../components/DocSearch/SourceViewModal';
import { useNavigate } from "react-router-dom";
import { ROUTES_ID, USER_ROLE } from "../../config";
import VideoPlayModal from "../../components/VideoPlayModal";
import InstructionsModal from "../../components/DocSearch/InstructionsModal";
import FileUpload from '../../components/DocSearch/FileUpload';
import Workflow from '../../components/Workflow';
import ConfirmPopup from '../../components/ConfirmPopup'

const DocSearch = ({ isSidebarOpen, activeLLM }) => {
    const navigate = useNavigate();
    const chatContainerRef = useRef(null);
    const docSearchHook = useDocSearch();
    const { state, dispatch } = useStore();
    const [selectedChatId, setSelectedChatId] = useState(null);
    const [ChatHistory, setChatHistory] = useState([]);
    const [isOpen, setisOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isRulesOpen, setIsRulesOpen] = useState(false);
    const [isIncludeGoogleSearch, setIsIncludeGoogleSearch] = useState(false)
    const [modalData, setModalData] = useState([])
    const [viewDoc, setViewDoc] = useState(false);
    const [viewDocUrl, setViewDocUrl] = useState(null);
    const [openVideoPlayer, setOpenVideoPlayer] = useState(false);
    const [isChathistoryDeleteConfirmOpen, setChathistoryDeleteConfirmOpen] = useState(false);
    const [editChatIndex, setEditChatIndex] = useState(null)

    useEffect(() => {
        if (state.isChatLoading) {
            dispatch({
                type: 'CHAT_LOADING',
                payload: false,
            })
        }

        return () => {
            handleNewChat();
        }
    }, [])

    useEffect(() => {
        if (state.docSearchChatHistory !== null) {
            setChatHistory(state.docSearchChatHistory);
            scrollToBottom(chatContainerRef);
        }
    }, [state.docSearchChatHistory])

    useEffect(() => {
        (ChatHistory.length > 0) && scrollToBottom(chatContainerRef);
    }, [ChatHistory])

    useEffect(() => {
        if (state.docSearchChat && state.docSearchCurrentConversation) {
            let index = state.docSearchChat.totalChat + 1
            setChatHistory(prevData => {
                if (index >= 0 && index < prevData.length) {
                    let newData = prevData.map((item, i) => (i === index && item.bot ? { ...item, bot: { ...item.bot, mssg: state.docSearchChat.mssg, isStreaming: state.docSearchChat.isStreaming, sources: state.docSearchChat.sources ? state.docSearchChat.sources : [] } } : item));

                    return newData;
                } else {
                    return [...prevData, { bot: state.docSearchChat }];
                }
            });
            scrollToBottom(chatContainerRef);
        }
    }, [state.docSearchChat])

    useEffect(() => {
        if (state.docSearchSummary && state.docSearchCurrentConversation) {
            let index = state.docSearchSummary.totalChat + 1
            setChatHistory(prevData => {
                if (index >= 0 && index < prevData.length) {
                    let newData = prevData.map((item, i) => (i === index && item.bot ? { ...item, bot: { ...item.bot, mssg: state.docSearchSummary.mssg, isStreaming: state.docSearchSummary.isStreaming } } : item));

                    return newData;
                } else {
                    return [...prevData, { bot: state.docSearchSummary }];
                }
            });

            scrollToBottom(chatContainerRef);
        }
    }, [state.docSearchSummary])

    const onResult = ({ output_format, result }) => {
        if (output_format === 'file') {
            const chat = {
                mssg: `Here is your result \n\n File saved in ${result}`,
                showRevealInFolder: true,
                path: result,
                type: 'workflow'
            }
            setChatHistory([{ bot: chat }]);
        }
        else {
            const chat = {
                mssg: `Here is your result \n\n ${result}`,
                type: 'workflow'
            }
            setChatHistory([{ bot: chat }]);
        }
        setEditChatIndex(-1)
    }

    const handleNewChat = () => {
        setChatHistory([]);
        dispatch({
            type: "SET_DOC_CHAT_HISTORY",
            payload: {
                chatArray: null,
                convID: null
            }
        })
        dispatch({
            type: "SET_DOC_CHAT",
            payload: null
        })
        dispatch({
            type: "SET_DOC_SUMMERY",
            payload: null
        })
        dispatch({
            type: "SET_DOC_SEARCH_STREAMING",
            payload: false
        })
    }

    const handleMsgSend = (chatData) => {
        try {
            dispatch({
                type: "CHAT_LOADING",
                payload: true
            })
            const totalChat = ChatHistory.length;
            let chat = { mssg: chatData.text }
            setChatHistory((prevChats) => {
                return [...prevChats, { user: chat }]
            })

            const req = {
                text: chatData.text,
                user_id: state.user && state.user.username ? state.user.username : null,
                username: state.user && state.user.username ? state.user.username : null,
                userGroup: state.user && state.user.user_groups ? state.user.user_groups : null,
                conversation_id: state.docSearchCurrentConversation,
                web_search: isIncludeGoogleSearch,
                streaming: true,
                integration: state.selectedIntegration[ROUTES_ID.DOCUMENT_SEARCH].id
            }
            docSearchHook.getGeneralQA(req, totalChat);
        } catch (error) {
            console.error('Error sending message:', error);
        }
        setEditChatIndex(-1)
    }

    const openModal = (data) => {
        setModalData(data);
        setIsModalOpen(true);
    }

    const closeModal = () => {
        setIsModalOpen(false);
    }

    const openRulesModal = (data) => {
        setModalData(data);
        setIsRulesOpen(true);
    }

    const closeRulesModal = () => {
        setIsRulesOpen(false);
    }

    const closeViewDocModal = () => {
        setViewDocUrl(null);
        setViewDoc(false);
    }

    const deleteChatHistory = (chatId) => {
        setSelectedChatId(chatId)
        setChathistoryDeleteConfirmOpen(true);
    }

    const onDeleteChathistoryConfirm = () => {
        setChathistoryDeleteConfirmOpen(false);
		docSearchHook.deleteChatHistory(selectedChatId);
        handleNewChat()
        setSelectedChatId(null)
	}

    const onCloseConfirm = () => {
		setChathistoryDeleteConfirmOpen(false);
        setSelectedChatId(null)
	}

    const EASY_FILE_UPLOAD = <div className='flex p-1 w-8 h-8 text-gray-dark dark:text-white items-center justify-center'>
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="35" height="35" rx="7.5" stroke="#D3DBE6" />
            <path d="M23.3594 11.1406C22.3398 10.1211 20.6875 10.1211 19.668 11.1406L12.918 17.8906C11.2656 19.543 11.2656 22.1797 12.918 23.832C14.5703 25.4844 17.207 25.4844 18.8594 23.832L24.2031 18.4883C24.4141 18.2773 24.8008 18.2773 25.0117 18.4883C25.2227 18.6992 25.2227 19.0859 25.0117 19.2969L19.668 24.6406C17.5938 26.7148 14.1836 26.7148 12.1094 24.6406C10.0352 22.5312 10.0352 19.1562 12.1094 17.082L18.8594 10.332C20.3359 8.89062 22.6914 8.89062 24.168 10.332C25.6094 11.8086 25.6094 14.1641 24.168 15.6406L17.6992 22.1094C16.7148 23.0938 15.0625 22.9883 14.1836 21.8984C13.4453 20.9492 13.5156 19.6133 14.3594 18.7695L19.7031 13.4258C19.9141 13.2148 20.3008 13.2148 20.5117 13.4258C20.7227 13.6367 20.7227 14.0234 20.5117 14.2344L15.168 19.5781C14.7109 20 14.6758 20.7031 15.0625 21.1953C15.5195 21.7578 16.3984 21.8281 16.8906 21.3008L23.3594 14.832C24.3789 13.8125 24.3789 12.1602 23.3594 11.1406Z" fill="currentColor" />
        </svg>
    </div>

    return (
        <div>
            <div className="flex relative chat-container ">
                <SidePanelDocSearch handleRules={openRulesModal} handleModal={openModal} handleNewChat={handleNewChat} chatContainerRef={chatContainerRef} isOpen={isOpen} setIsOpen={setisOpen} setChatHistory={setChatHistory} ChatHistory={ChatHistory} setViewDoc={setViewDoc} setViewDocUrl={setViewDocUrl} deleteChatHistory={deleteChatHistory} selectedChatId={selectedChatId} setSelectedChatId={setSelectedChatId}/>
                <div className={`${isOpen ? 'w-0' : 'w-16'} flex items-start mt-4`}>
                    <button className={`text-gray-dark dark:text-white mx-auto ${isOpen && 'hidden'}`} onClick={() => setisOpen(!isOpen)}>
                        <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.75 2H7.875V15.5H15.75C16.3477 15.5 16.875 15.0078 16.875 14.375V3.125C16.875 2.52734 16.3477 2 15.75 2ZM2.25 2C1.61719 2 1.125 2.52734 1.125 3.125V14.375C1.125 15.0078 1.61719 15.5 2.25 15.5H6.75V2H2.25ZM2.25 0.875H15.75C16.9805 0.875 18 1.89453 18 3.125V14.375C18 15.6406 16.9805 16.625 15.75 16.625H2.25C0.984375 16.625 0 15.6406 0 14.375V3.125C0 1.89453 0.984375 0.875 2.25 0.875ZM2.8125 3.125H5.0625C5.34375 3.125 5.625 3.40625 5.625 3.6875C5.625 4.00391 5.34375 4.25 5.0625 4.25H2.8125C2.49609 4.25 2.25 4.00391 2.25 3.6875C2.25 3.40625 2.49609 3.125 2.8125 3.125ZM2.25 5.9375C2.25 5.65625 2.49609 5.375 2.8125 5.375H5.0625C5.34375 5.375 5.625 5.65625 5.625 5.9375C5.625 6.25391 5.34375 6.5 5.0625 6.5H2.8125C2.49609 6.5 2.25 6.25391 2.25 5.9375ZM2.8125 7.625H5.0625C5.34375 7.625 5.625 7.90625 5.625 8.1875C5.625 8.50391 5.34375 8.75 5.0625 8.75H2.8125C2.49609 8.75 2.25 8.50391 2.25 8.1875C2.25 7.90625 2.49609 7.625 2.8125 7.625Z" fill="currentColor" />
                        </svg>
                    </button>
                </div>
                <main className="w-full relative transition-all  px-4">
                    <div ref={chatContainerRef} className='w-full chat-content-container overflow-y-auto'>
                        {!ChatHistory.length > 0 ?
                            <div className='w-full relative'>
                                <div className={`grid grid-cols-1 items-center justify-center ${isOpen ? "px-20" : "px-40"}`}>
                                    <p className='flex text-gray-dark dark:text-white text-2xl font-bold my-6 justify-center' >Ask Generate Anything</p>
                                    <div className='flex flex-col'>
                                        <p className='text-white-gray-300 dark:text-white-gray-400 text-base font-normal justify-center px-32 text-center'>Connect your file system or document repository and ask a question powered by generative AI.</p>
                                        <div className='w-full flex flex-row items-center py-3 pt-6 justify-evenly'>
                                            {[USER_ROLE.SUPER_ADMIN, USER_ROLE.ADMIN].includes(state.userRole) &&
                                                <div className='flex flex-row items-center text-blue dark:text-blue-dark cursor-pointer' onClick={() => navigate("/doc-search/config?tabId=settings")}>
                                                    <svg width="15" height="17" viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M8.03125 1.21875L12 5.21875C12.3125 5.53125 12.3125 6 12 6.28125C11.7188 6.59375 11.25 6.59375 10.9375 6.28125L8.21875 3.5625V11.25C8.21875 11.6875 7.90625 12 7.46875 12C7.0625 12 6.71875 11.6875 6.71875 11.25V3.5625L4 6.28125C3.71875 6.59375 3.25 6.59375 2.96875 6.28125C2.65625 6 2.65625 5.53125 2.96875 5.21875L6.96875 1.21875C7.25 0.9375 7.71875 0.9375 8.03125 1.21875ZM2 11.75V14.25C2 14.9688 2.53125 15.5 3.25 15.5H11.75C12.4375 15.5 13 14.9688 13 14.25V11.75C13 11.3438 13.3125 11 13.75 11C14.1562 11 14.5 11.3438 14.5 11.75V14.25C14.5 15.7812 13.25 17 11.75 17H3.25C1.71875 17 0.5 15.7812 0.5 14.25V11.75C0.5 11.3438 0.8125 11 1.25 11C1.65625 11 2 11.3438 2 11.75Z" fill="currentColor" />
                                                    </svg>
                                                    <p className='text-blue dark:text-blue-dark text-base font-semibold text-center ml-2'>Upload files</p>
                                                </div>
                                            }
                                            <div className='flex flex-row items-center text-blue dark:text-blue-dark cursor-pointer' onClick={() => setOpenVideoPlayer(true)}>
                                                <svg width="13" height="16" viewBox="0 0 13 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M2 13.5L11 8L2 2.5V13.5ZM1.25 1.21875C1.71875 0.9375 2.3125 0.9375 2.78125 1.21875L11.7812 6.71875C12.2188 7 12.5 7.5 12.5 8C12.5 8.53125 12.2188 9.03125 11.7812 9.28125L2.78125 14.7812C2.3125 15.0938 1.71875 15.0938 1.25 14.8125C0.78125 14.5625 0.5 14.0625 0.5 13.5V2.5C0.5 1.96875 0.78125 1.46875 1.25 1.21875Z" fill="#336FE4" />
                                                </svg>
                                                <p className='text-blue dark:text-blue-dark text-base font-semibold text-center ml-2'>Watch tutorial</p>
                                            </div>
                                            <a href="https://interplay.helpjuice.com/generate" target="_blank">
                                                <div className='flex flex-row items-center text-blue dark:text-blue-dark cursor-pointer'>
                                                    <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M9.16675 1.5C7.10425 1.5 5.448 2.4375 4.16675 3.625C2.948 4.75 2.1355 6.0625 1.698 7C2.1355 7.9375 2.948 9.28125 4.16675 10.4062C5.448 11.5938 7.10425 12.5 9.16675 12.5C11.198 12.5 12.8542 11.5938 14.1355 10.4062C15.3542 9.28125 16.198 7.9375 16.6042 7C16.198 6.0625 15.3542 4.75 14.1667 3.625C12.8542 2.4375 11.198 1.5 9.16675 1.5ZM3.1355 2.53125C4.60425 1.15625 6.6355 0 9.16675 0C11.6667 0 13.698 1.15625 15.1667 2.53125C16.6355 3.90625 17.6042 5.5 18.073 6.625C18.1667 6.875 18.1667 7.15625 18.073 7.40625C17.6042 8.5 16.6355 10.125 15.1667 11.5C13.698 12.875 11.6667 14 9.16675 14C6.6355 14 4.60425 12.875 3.1355 11.5C1.66675 10.125 0.697998 8.5 0.229248 7.40625C0.135498 7.15625 0.135498 6.875 0.229248 6.625C0.697998 5.5 1.66675 3.875 3.1355 2.53125ZM9.16675 9.5C10.5417 9.5 11.6667 8.40625 11.6667 7C11.6667 5.625 10.5417 4.5 9.16675 4.5C9.1355 4.5 9.10425 4.5 9.10425 4.5C9.1355 4.6875 9.16675 4.84375 9.16675 5C9.16675 6.125 8.2605 7 7.16675 7C6.97925 7 6.823 7 6.66675 6.9375C6.66675 6.96875 6.66675 7 6.66675 7C6.66675 8.40625 7.7605 9.5 9.16675 9.5ZM9.16675 3C10.573 3 11.8855 3.78125 12.6042 5C13.323 6.25 13.323 7.78125 12.6042 9C11.8855 10.25 10.573 11 9.16675 11C7.72925 11 6.41675 10.25 5.698 9C4.97925 7.78125 4.97925 6.25 5.698 5C6.41675 3.78125 7.72925 3 9.16675 3Z" fill="#336FE4" />
                                                    </svg>
                                                    <p className='text-blue dark:text-blue-dark text-base font-semibold text-center ml-2'>View documentation</p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div className='w-full mt-2 px-4'>
                                {ChatHistory.map((item, index) => {
                                    return (
                                        <ChatMessage ChatArray={ChatHistory} setChatArray={setChatHistory} prevChat={ChatHistory[index - 1]} chatContainerRef={chatContainerRef} data={item} chatID={index} isSidebarOpen={isSidebarOpen} isSidePanelOpen={isOpen} handleMsgSend={handleMsgSend} editChatIndex={editChatIndex} setEditChatIndex={setEditChatIndex}/>
                                    )
                                })}
                                {state.isChatLoading && <ChatLoading />}
                            </div>
                        }
                        <div className='w-full relative'>
                            <Workflow showWorkFlow={ChatHistory && ChatHistory.length === 0} appId={"DOCUMENT_SEARCH"} isSidePanelOpen={isOpen} isSidebarOpen={isSidebarOpen} activeLLM={activeLLM} onResult={onResult} />
                        </div> 
                    </div>
                    <div className='absolute bottom-0 left-1/2 z-50 -translate-x-1/2 w-full px-6'>
                        <div className={`flex items-center`}>
                            <div className='flex mr-4'>
                                <TooltipWrapper content={`Uplload file/s`} tootTipStyle={'w-[114px] -mt-16'} autoHide={false} isShowArrow={true} onTop={false}>
                                    <FileUpload children={EASY_FILE_UPLOAD} appId={ROUTES_ID.DOCUMENT_SEARCH} allowMultiple={true} folder={false} path={'doc_search'} />
                                </TooltipWrapper>
                            </div>
                            <ChatInput name={"Doc_chat"} disabled={state.isDocSearchStreaming} handleSend={handleMsgSend} placeholder={"Ask Generate anything..."} type={"text"} />
                        </div>
                        <div className='flex items-center mb-3'>
                            <CheckBox
                                value={isIncludeGoogleSearch}
                                setValue={setIsIncludeGoogleSearch}
                                name={"isIncludeGoogleSearch"}
                                label={'Include Web Search'}
                            />
                            <TooltipWrapper content={"Include web browsing capabilities as additional context for responses."} tootTipStyle={'w-60 -mt-16'} autoHide={false} isShowArrow={true} onTop={true}>
                                <div className='flex ml-1 items-center justify-center'>
                                    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M9 2C6.65625 2 4.53125 3.25 3.34375 5.25C2.1875 7.28125 2.1875 9.75 3.34375 11.75C4.53125 13.7812 6.65625 15 9 15C11.3125 15 13.4375 13.7812 14.625 11.75C15.7812 9.75 15.7812 7.28125 14.625 5.25C13.4375 3.25 11.3125 2 9 2ZM9 16.5C6.125 16.5 3.5 15 2.0625 12.5C0.625 10.0312 0.625 7 2.0625 4.5C3.5 2.03125 6.125 0.5 9 0.5C11.8438 0.5 14.4688 2.03125 15.9062 4.5C17.3438 7 17.3438 10.0312 15.9062 12.5C14.4688 15 11.8438 16.5 9 16.5ZM7.75 11H8.5V9H7.75C7.3125 9 7 8.6875 7 8.25C7 7.84375 7.3125 7.5 7.75 7.5H9.25C9.65625 7.5 10 7.84375 10 8.25V11H10.25C10.6562 11 11 11.3438 11 11.75C11 12.1875 10.6562 12.5 10.25 12.5H7.75C7.3125 12.5 7 12.1875 7 11.75C7 11.3438 7.3125 11 7.75 11ZM9 6.5C8.4375 6.5 8 6.0625 8 5.5C8 4.96875 8.4375 4.5 9 4.5C9.53125 4.5 10 4.96875 10 5.5C10 6.0625 9.53125 6.5 9 6.5Z" fill="#788493" />
                                    </svg>
                                </div>
                            </TooltipWrapper>
                        </div>
                    </div>
                </main>
                <AskQuestionModal isOpen={isModalOpen} onClose={closeModal} data={modalData} setChatHistory={setChatHistory} ChatHistory={ChatHistory} />
                <InstructionsModal isOpen={isRulesOpen} onClose={closeRulesModal} data={modalData} />
                <VideoPlayModal isOpen={openVideoPlayer} data={[]} onClose={() => setOpenVideoPlayer(false)} />
                <SourceViewModal isOpen={viewDoc} onClose={closeViewDocModal} fileUrl={viewDocUrl} />
                <ConfirmPopup id={'delete_popup_div'} title={"Are you sure you want to delete this chat history?"} confirmLabel={"Delete"} isOpen={isChathistoryDeleteConfirmOpen} onConfirm={onDeleteChathistoryConfirm} onCancel={onCloseConfirm} onClose={onCloseConfirm} />
            </div>
        </div>
    )
}
export default DocSearch