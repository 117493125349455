import React, { useEffect, useState } from 'react';
import LoadingBar from 'react-top-loading-bar';
import { useStore } from '../store/AppContext';

const Loading = () => {
	const { state } = useStore();
	const [loadingValue, setLoadingValue] = useState(0);

	useEffect(() => {
		const timer = setInterval(() => {
			setLoadingValue((oldProgress) => {
				if (100 == oldProgress) return 0;
				return Math.min(oldProgress + Math.random() * 10, 100);
			})
		}, 500)

		!state.loading && clearInterval(timer);

		return () => {
			clearInterval(timer);
		}
	}, [state.loading])


	return (
		<div className="flex flex-col h-screen bg-white-gray dark:bg-gray-dark">
			{state.loading &&
				<LoadingBar
					color='#336FE4'
					height={5}
					progress={loadingValue}
				/>
			}
		</div>
	)
}

export default Loading;
