import { CONFLUENCE_ICON, DOCUSIGN_ICON, GOOGLE_DRIVE_ICON, JIRA_ICON, LOCAL_ICON, MONDAY_DOT_COM_ICON, ONEDRIVE_ICON, QUICKBOOKS_ICON, SCRAPINGBEE_ICON, SLACK_ICON } from "./images";

export const BASE_URL = (window.origin === 'http://localhost:3000') ? "http://52.40.226.174:2499" : window.origin;

export const API_URL = BASE_URL + "/api/v1";

export const API_KEY = "bGxtX21hbmFnZXI6bGxtX21hbmFnZXJAYXBpX2tleQ==";

export const ROUTES_ID = {
    DASHBOARD: "DASHBOARD",
    SERVICE_PILOT: "CO_PILOT",
    DATABASE_SEARCH: "DB_SEARCH",
    DOCUMENT_SEARCH: "DOCUMENT_SEARCH",
    SETTINGS: "SETTINGS",
    INTEGRATIONS: "INTEGRATIONS",
    AUTHENTICATION: "AUTHENTICATION",
    USER_ACCESS: "USER_ACCESS"
}

export const ROUTES = {
    "/dashboard": ROUTES_ID.DASHBOARD,
    "/service-pilot/app": ROUTES_ID.SERVICE_PILOT,
    "/service-pilot/email": ROUTES_ID.SERVICE_PILOT,
    "/service-pilot/config": ROUTES_ID.SERVICE_PILOT,
    "/service-pilot/manage-documents": ROUTES_ID.SERVICE_PILOT,
    "/db-search/app": ROUTES_ID.DATABASE_SEARCH,
    "/db-search/config": ROUTES_ID.DATABASE_SEARCH,
    "/db-search/manage-databases": ROUTES_ID.DATABASE_SEARCH,
    "/doc-search/app": ROUTES_ID.DOCUMENT_SEARCH,
    "/doc-search/config": ROUTES_ID.DOCUMENT_SEARCH,
    "/doc-search/manage-documents": ROUTES_ID.DOCUMENT_SEARCH
}

export const APPS_ID = [ROUTES_ID.SERVICE_PILOT, ROUTES_ID.DATABASE_SEARCH, ROUTES_ID.DOCUMENT_SEARCH];

export const APPS = {
    [ROUTES_ID.SERVICE_PILOT]: 'Service Pilot',
    [ROUTES_ID.DATABASE_SEARCH]: 'Database Search',
    [ROUTES_ID.DOCUMENT_SEARCH]: 'Document Search'
}

export const USER_ROLE = {
    SUPER_ADMIN: 'super_admin',
    ADMIN: 'admin',
    USER: 'user'
}

export const USER_ROLE_LABEL = {
    [USER_ROLE.SUPER_ADMIN]: 'Super Admin',
    [USER_ROLE.ADMIN]: 'Admin',
    [USER_ROLE.USER]: 'User'
}

export const SUB_PAGE_APPS = {
    [ROUTES_ID.SETTINGS]: {
        [USER_ROLE.SUPER_ADMIN]: [ROUTES_ID.SERVICE_PILOT, ROUTES_ID.DATABASE_SEARCH, ROUTES_ID.DOCUMENT_SEARCH],
        [USER_ROLE.ADMIN]: [ROUTES_ID.SERVICE_PILOT, ROUTES_ID.DATABASE_SEARCH, ROUTES_ID.DOCUMENT_SEARCH],
        [USER_ROLE.USER]: [ROUTES_ID.SERVICE_PILOT, ROUTES_ID.DOCUMENT_SEARCH]
    },
    [ROUTES_ID.INTEGRATIONS]: {
        [USER_ROLE.SUPER_ADMIN]: [ROUTES_ID.SERVICE_PILOT, ROUTES_ID.DOCUMENT_SEARCH],
        [USER_ROLE.ADMIN]: [ROUTES_ID.SERVICE_PILOT, ROUTES_ID.DOCUMENT_SEARCH],
        [USER_ROLE.USER]: [ROUTES_ID.SERVICE_PILOT, ROUTES_ID.DOCUMENT_SEARCH]
    }
}

export const INTEGRATION_TYPES = {
    'local': { id: 'local', label: 'Local', icon: LOCAL_ICON },
    'google': { id: 'google', label: 'Google Drive', icon: GOOGLE_DRIVE_ICON, comingSoon: false },
    'onedrive': { id: 'onedrive', label: 'OneDrive', icon: ONEDRIVE_ICON, comingSoon: false },
    'mondaydotcom': { id: 'mondaydotcom', label: 'Monday.com', icon: MONDAY_DOT_COM_ICON, comingSoon: false },
    'scrapingbee': { id: 'scrapingbee', label: 'ScrapingBee', icon: SCRAPINGBEE_ICON, comingSoon: true },
    'confluence': { id: 'confluence', label: 'Confluence', icon: CONFLUENCE_ICON, comingSoon: true },
    'jira': { id: 'jira', label: 'Jira', icon: JIRA_ICON, comingSoon: true },
    'slack': { id: 'slack', label: 'Slack', icon: SLACK_ICON, comingSoon: false },
    'quickbooks': { id: 'quickbooks', label: 'Quickbooks', icon: QUICKBOOKS_ICON, comingSoon: true },
    'docusign': { id: 'docusign', label: 'Docusign', icon: DOCUSIGN_ICON, comingSoon: true },
}

export const INTEGRATION_CREDENTIALS = {
    'google': { clientId: '561575696175-0jhv79k78b0662kqv8afajjq4kil1hn9.apps.googleusercontent.com', clientSecret: 'AIzaSyCctBt6H725dp_1IFnEcU69paKEhJSC_rM', redirectURL: 'https://generatehelp.iterate.ai/integration/access_code' },
    'onedrive': { clientId: '08ded917-2450-4fed-acfe-889216be77a2', clientSecret: '', redirectURL: window.origin },
    'mondaydotcom': { clientId: 'c73046111c56e5ef10305e0d59e9526e', clientSecret: '064f3eb01e806e0dfc4565016ffe0f24', redirectURL: 'https://generatehelp.iterate.ai/integration/access_code' },
    'scrapingbee': { clientId: '', clientSecret: '', redirectURL: 'https://generatehelp.iterate.ai/integration/access_code' },
    'confluence': { clientId: '', clientSecret: '', redirectURL: 'https://generatehelp.iterate.ai/integration/access_code' },
    'jira': { clientId: '', clientSecret: '', redirectURL: 'https://generatehelp.iterate.ai/integration/access_code' },
    'slack': { clientId: '7232927498548.7366123528772', clientSecret: '7fefb489ae2b33994cdd1e9d244a9a24', redirectURL: 'https://generatehelp.iterate.ai/integration/access_code' },
    'quickbooks': { clientId: '', clientSecret: '', redirectURL: 'https://generatehelp.iterate.ai/integration/access_code' },
    'docusign': { clientId: '', clientSecret: '', redirectURL: 'https://generatehelp.iterate.ai/integration/access_code' },
}

export const APP_SUPPORT_INTEGRATION_TYPES = {
    [ROUTES_ID.SERVICE_PILOT]: [INTEGRATION_TYPES.local, INTEGRATION_TYPES.google, INTEGRATION_TYPES.onedrive],
    [ROUTES_ID.DOCUMENT_SEARCH]: [INTEGRATION_TYPES.local, INTEGRATION_TYPES.google, INTEGRATION_TYPES.onedrive, INTEGRATION_TYPES.slack, INTEGRATION_TYPES.mondaydotcom],
}

export const LLM_TYPES = {
    'gpt_4': { id: 'gpt-4', value: 'gpt-4', label: 'GPT-4 (Public Cloud)', displayName: 'GPT-4' },
    'gpt3_5': { id: 'gpt-3.5', value: 'gpt-3.5', label: 'GPT-3.5 (Public Cloud)', displayName: 'GPT-3.5' },
    'mistral_7b': { id: 'mistral-7b', value: 'mistral-7b', label: 'Mistral-7b (Private Cloud)', displayName: 'Mistral-7b' },
    'llama_8b': { id: 'llama-8b', value: 'llama-8b', label: 'Interplay Rag Pro (Private Cloud)', displayName: 'Interplay Rag Pro' },
}

export const APP_SUPPORT_LLM_TYPES = {
    [ROUTES_ID.SERVICE_PILOT]: [LLM_TYPES.gpt3_5, LLM_TYPES.gpt_4, LLM_TYPES.llama_8b],
    [ROUTES_ID.DATABASE_SEARCH]: [LLM_TYPES.gpt3_5, LLM_TYPES.gpt_4, LLM_TYPES.llama_8b],
    [ROUTES_ID.DOCUMENT_SEARCH]: [LLM_TYPES.gpt_4, LLM_TYPES.gpt3_5, LLM_TYPES.llama_8b],
}

export const VECTOR_DB_TYPES = {
    'redis': { id: 'redis', label: 'Redis' },
    'chroma_db': { id: 'chroma', label: 'Chroma DB' }
}

export const APP_SUPPORT_VECTOR_DB_TYPES = {
    [ROUTES_ID.SERVICE_PILOT]: [VECTOR_DB_TYPES.redis, VECTOR_DB_TYPES.chroma_db],
    [ROUTES_ID.DOCUMENT_SEARCH]: [VECTOR_DB_TYPES.redis, VECTOR_DB_TYPES.chroma_db],
}

export const WORKFLOW_CARD_BUNDLE_ID = "Consumer"

export const PAGE_SIZES = [100, 50, 25, 10];
