import React from 'react';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";

const SourceViewModal = ({ isOpen, onClose, fileUrl }) => {
    const handleClose = () => {
        onClose();
    }

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
            <div className="fixed inset-0 bg-white-gray dark:bg-black opacity-60" onClick={handleClose} />
            <div className='absolute flex items-center justify-center'>
                <DocViewer documents={[{ uri: fileUrl }]} style={{ width: window.innerWidth * 0.5, height: window.innerHeight, overflowY: 'auto' }} pluginRenderers={DocViewerRenderers} />
            </div>
        </div>
    )
}

export default SourceViewModal;