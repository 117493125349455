import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { useLocation, useNavigate } from "react-router-dom";
import { PrivateRoute } from '../components/PrivateRoute';
import Login from '../pages/Login';
import Home from '../components/Home';
import AuthConfig from '../components/AuthConfig';
import { useStore } from '../store/AppContext';
import { ROUTES, USER_ROLE } from '../config';
import ApplicationsPermission from '../pages/ApplicationsPermission';

function AppRoute() {
    const location = useLocation();
    const navigate = useNavigate();
    const { state } = useStore();

    useEffect(() => {
        let pathName = location.pathname;

        if (location.pathname.endsWith('/') && location.pathname !== '/')
            pathName = location.pathname.slice(0, -1);

        if ((pathName !== '/dashboard') && ![USER_ROLE.SUPER_ADMIN].includes(state.userRole) && state.isLoggedIn && (state.user && !state.user.allowed_applications.includes(ROUTES[pathName])))
            navigate('/dashboard');
    }, [location])

    return (
        <div>
            <Routes>
                <Route
                    path="/login"
                    element={
                        <Login />
                    }
                />
                <Route
                    path="/auth-config"
                    element={
                        <AuthConfig />
                    }
                />
                <Route
                    path="/user-access"
                    element={
                        <ApplicationsPermission />
                    }
                />
                <Route
                    path='/*'
                    element={
                        <PrivateRoute>
                            <Home />
                        </PrivateRoute>
                    }
                />
            </Routes>
            <div className='fixed flex right-0 bottom-0 p-3 w-40 justify-center items-center'>
                <img src={state.isDarkMode ? "/assets/images/logo-poweredbyiterate-dark.svg" : "/assets/images/logo-poweredbyiterate-light.svg"} alt="interplay-logo" className='w-60 mb-0' />
            </div>
        </div>
    )
}

export default AppRoute;
