import axios from "axios";
import Cookies from "js-cookie";
import { API_URL, API_KEY } from "../config";
import { useStore } from '../store/AppContext';

export const useAuth = () => {
    const { dispatch } = useStore();

    return {
        login: (username, password, authType) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })
            axios
                .post(
                    `${API_URL}/user/login`,
                    { username: username, password: password, authType: authType },
                    { headers: { apikey: API_KEY } }
                )
                .then((res) => {
                    let response = res.data ? res.data : {};
                    Cookies.set("user", JSON.stringify(response.user));
                    Cookies.set("token", response.token);

                    dispatch({
                        type: "LOGIN",
                        payload: response
                    })
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Login faild",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                })
        },
        saveAuth0User: (auth0User, token) => {
            let user = {
                username: auth0User.email,
                first_name: auth0User.nickname,
                last_name: auth0User.last_name,
                user_image: auth0User.picture,
                user_role: auth0User.generate_roles.length > 0 ? auth0User.generate_roles[0] : null,
                user_groups: auth0User.generate_app_metadata && auth0User.generate_app_metadata.authorization && auth0User.generate_app_metadata.authorization.groups && auth0User.generate_app_metadata.authorization.groups.length > 0 ? auth0User.genpilot_app_metadata.authorization.groups[0] : null,
                auth_method: 'auth0'
            }

            dispatch({
                type: "LOADING",
                payload: true,
            })

            axios
                .post(
                    `${API_URL}/user`,
                    user,
                    { headers: { Authorization: token, apikey: API_KEY } }
                )
                .then((res) => {
                    let response = res.data ? res.data : {};

                    Cookies.set("user", JSON.stringify(response.user));
                    Cookies.set("token", token);

                    dispatch({
                        type: "LOGIN",
                        payload: {
                            user: response.user,
                            token: token
                        }
                    })
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                })
                .catch((err) => {
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "User save faild",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Unable to save the user informations to the database!"
                        }
                    })
                })
        }
    }
}
