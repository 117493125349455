const Pagination = ({ pagesizesDropdownRef, pageSizesDropdownOptionsRef, pageSizes, pageNum, setPageNum, pageSize, setPageSize, total, showPageSizes, setShowPageSizes }) => {
    return (
        <div className='flex relative items-center justify-end py-[6px] px-4 text-gray-dark dark:text-white'>
            <p className='pr-2'>Rows per page:</p>
            <p className='pr-2'>{pageSize}</p>
            <div className='flex relative'>
                <button ref={pagesizesDropdownRef} onClick={() => setShowPageSizes(!showPageSizes)} className='pr-10'>
                    <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.28125 6.21875L1.28125 2.21875C1 1.9375 0.90625 1.5 1.0625 1.125C1.21875 0.75 1.59375 0.5 2 0.5H10C10.4062 0.5 10.75 0.75 10.9062 1.125C11.0625 1.5 10.9688 1.9375 10.6875 2.21875L6.6875 6.21875C6.3125 6.625 5.65625 6.625 5.28125 6.21875Z" fill="currentColor" />
                    </svg>
                </button>
                {showPageSizes &&
                    <div ref={pageSizesDropdownOptionsRef} className={`p-2 absolute z-10 bottom-4 right-[40px] rounded-lg bg-gray-900 dark:bg-gray-black overflow-auto`}>
                        {pageSizes.map((option, index) =>
                            <div id={option} onClick={() => { setPageSize(option); setShowPageSizes(false); }} className={`flex cursor-pointer text-xs text-gray-dark dark:text-white w-full items-center justify-start p-1 hover:bg-white-gray-900 hover:dark:bg-gray-200`} key={index} value={option}>
                                {option}
                            </div>
                        )}
                    </div>
                }
            </div>
            <p className='pr-10'>{`${((pageNum - 1) * pageSize) + 1} - ${((pageNum * pageSize) > total) ? total : (pageNum * pageSize)} of ${total ? total : 0}`}</p>
            <button disabled={pageNum <= 1} onClick={() => setPageNum(prev => prev - 1)} className='p-3'>
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.5016 15.2203C11.3422 15.2203 11.1828 15.1672 11.0766 15.0344L5.07343 8.92499C4.83437 8.68593 4.83437 8.31405 5.07343 8.07499L11.0766 1.96561C11.3156 1.72655 11.6875 1.72655 11.9266 1.96561C12.1656 2.20468 12.1656 2.57655 11.9266 2.81561L6.34843 8.49999L11.9531 14.1844C12.1922 14.4234 12.1922 14.7953 11.9531 15.0344C11.7937 15.1406 11.6609 15.2203 11.5016 15.2203Z" fill="currentColor" />
                </svg>
            </button>
            <button disabled={((pageNum * pageSize) >= total)} onClick={() => setPageNum(prev => prev + 1)} className='p-3'>
                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.49843 15.2203C5.33906 15.2203 5.20624 15.1672 5.07343 15.0609C4.83437 14.8219 4.83437 14.45 5.07343 14.2109L10.6516 8.49999L5.07343 2.81561C4.83437 2.57655 4.83437 2.20468 5.07343 1.96561C5.31249 1.72655 5.68437 1.72655 5.92343 1.96561L11.9266 8.07499C12.1656 8.31405 12.1656 8.68593 11.9266 8.92499L5.92343 15.0344C5.81718 15.1406 5.65781 15.2203 5.49843 15.2203Z" fill="currentColor" />
                </svg>
            </button>
        </div>
    )
}

export default Pagination;