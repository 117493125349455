import React from 'react';
import GoogleDrive from './GoogleDrive';
import OneDrive from './OneDrive';
import MondayDotCom from './MondayDotCom';
import ScrapingBee from './ScrapingBee';
import Confluence from './Confluence';
import Jira from './Jira';
import Slack from './Slack';
import Quickbooks from './Quickbooks';
import Docusign from './Docusign';

const Integrations = ({ appId, integrationsList = [] }) => {
    return (
        <div className="integrations-content-container overflow-auto hide-scrollbar flex justify-center items-start">
            <div className="grid grid-cols-3 gap-6 py-1">
                {integrationsList.map((integration, index) =>
                    <div key={index} className='grid grid-cols-1'>
                        {(integration.id === 'google') && <GoogleDrive appId={appId} comingSoon={integration.comingSoon} />}
                        {(integration.id === 'onedrive') && <OneDrive appId={appId} comingSoon={integration.comingSoon} />}
                        {(integration.id === 'mondaydotcom') && <MondayDotCom appId={appId} comingSoon={integration.comingSoon} />}
                        {(integration.id === 'scrapingbee') && <ScrapingBee appId={appId} comingSoon={integration.comingSoon} />}
                        {(integration.id === 'confluence') && <Confluence appId={appId} comingSoon={integration.comingSoon} />}
                        {(integration.id === 'jira') && <Jira appId={appId} comingSoon={integration.comingSoon} />}
                        {(integration.id === 'slack') && <Slack appId={appId} comingSoon={integration.comingSoon} />}
                        {(integration.id === 'quickbooks') && <Quickbooks appId={appId} comingSoon={integration.comingSoon} />}
                        {(integration.id === 'docusign') && <Docusign appId={appId} comingSoon={integration.comingSoon} />}
                    </div>
                )}
            </div>
        </div>
    )
}

export default Integrations;
