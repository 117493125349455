import axios from "axios";
import Cookies from "js-cookie";
import { API_KEY, API_URL } from "../config";
import { useStore } from '../store/AppContext';

export const useWorkflow = () => {
    const { dispatch } = useStore();

    return {
        getCards: (req) => {
            dispatch({
                type: "LOADING",
                payload: false,
            })
            axios.post(`${API_URL}/application/workflow/get-cards`, req,
                {
                    headers: {
                        Authorization: Cookies.get("token"),
                        apikey: API_KEY,
                    }
                }
            )
                .then((res) => {
                    dispatch({
                        type: "SET_WORKFLOW_CARDS",
                        payload: res.data
                    })
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Data fetch Failed",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                })
        }
    }
}