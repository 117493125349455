import { faFile } from '@fortawesome/free-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'
import TooltipWrapper from '../TooltipWrapper'
import { useStore } from '../../store/AppContext'
import { useDocSearch } from '../../hooks/useDocSearch'
import { scrollToBottom } from './utils'
import { BASE_URL, ROUTES_ID } from '../../config'

export const SourceButton = ({ source, handleModal, chatContainerRef, setChatHistory, ChatHistory, setViewDoc, setViewDocUrl }) => {
    const { state } = useStore()
    const docSearchHook = useDocSearch()
    const sourceRef = useRef(null);
    const sourceOptionsRef = useRef(null);
    const [OptionList, setOptionList] = useState()

    let rect = sourceRef.current && sourceRef.current.getBoundingClientRect();
    let clickedPosition = (document.getElementById('doc_search_sources_view').clientHeight + 226) * 0.65;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sourceRef.current && !sourceRef.current.contains(event.target) && sourceOptionsRef.current && !sourceOptionsRef.current.contains(event.target)) {
                handleLeave();
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [sourceRef, sourceOptionsRef])

    const handleClick = () => {
        setOptionList(!OptionList)
    }

    const handleLeave = () => {
        setOptionList(false)
    }

    const handleView = () => {
        handleLeave();
        let fileUrl = `${BASE_URL}/llm_manager/files/doc_search/${source.integration}/${source.fileName}`;
        setViewDocUrl(fileUrl);
        setViewDoc(true);
    }

    const handleSummarize = (pdf_path, pdf_name) => {
        handleLeave();
        let chat = { mssg: `Give me the summary for the ${pdf_name}` }
        setChatHistory((prevChats) => {
            return [...prevChats, { user: chat }]
        })
        scrollToBottom(chatContainerRef)
        let totalChat = ChatHistory.length;

        const req = {
            text: chat.mssg,
            conversation_id: state.docSearchCurrentConversation,
            user_id: state.user && state.user.username ? state.user.username : null,
            username: state.user && state.user.username ? state.user.username : null,
            file_paths: pdf_path,
            file_name: pdf_name,
            streaming: true,
            integration: state.selectedIntegration[ROUTES_ID.DOCUMENT_SEARCH].id
        }
        docSearchHook.getSummary(req, totalChat);
    }

    const handleAskQuestion = (data) => {
        handleLeave();
        handleModal(data)
    }

    return (
        <TooltipWrapper content={source.fileName} className={'w-max'} tootTipStyle={'flex w-[240px] z-50 overflow-x-auto'} autoHide={true} onTop={rect && (clickedPosition < rect.top)}>
            <div>
                <button ref={sourceRef} onClick={handleClick} className={`flex w-full items-center`}>
                    <div className={`w-[240px] py-3 px-2 font-light overflow-hidden text-ellipsis text-nowrap outline-none rounded-lg text-left text-sm text-gray-dark dark:text-white hover:bg-white-gray-500 hover:dark:bg-gray-black ${OptionList ? 'bg-white-gray-500 dark:bg-gray-black' : ''}`}>
                        <FontAwesomeIcon className='pr-3' icon={faFile} />
                        {source.fileName}
                    </div>
                </button>
                {OptionList &&
                    <div ref={sourceOptionsRef} className={`flex absolute flex-col z-50 left-0 ${clickedPosition < rect.top ? '-top-28' : 'top-11'} text-gray-dark-500 dark:text-white bg-white-gray-600 dark:bg-gray-black px-2 text-xs py-2 rounded-lg`}>
                        <button disabled={state.isDocSearchStreaming} onClick={() => handleSummarize(source.filePath, source.fileName)} className='py-2 px-4 text-left hover:bg-white-gray-400 hover:dark:bg-gray-dark-600 rounded-md disabled:text-gray-light'>
                            Summarize
                        </button>
                        <button disabled={state.isDocSearchStreaming} onClick={() => handleAskQuestion(source)} className='py-2 px-4 text-left hover:bg-white-gray-400 hover:dark:bg-gray-dark-600 rounded-md disabled:text-gray-light'>
                            Ask Question
                        </button>
                        <button onClick={handleView} className='py-2 px-4 text-left hover:bg-white-gray-400 hover:dark:bg-gray-dark-600 rounded-md disabled:text-gray-light'>
                            View
                        </button>
                    </div>
                }
            </div>
        </TooltipWrapper>
    )
}
