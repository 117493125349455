import React from 'react';
import { StoreProvider } from './store/AppContext';
import AppWrapper from './AppWrapper';
import RootRoute from './routes/RootRoute';
import Notification from './components/Notification';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCoffee, faCheckSquare, faTimes } from '@fortawesome/free-solid-svg-icons';
import Loading from './components/Loading';
library.add(faCoffee, faCheckSquare, faTimes);

const App = () => {
	return (
		<StoreProvider>
			<AppWrapper>
				<RootRoute />
				<Notification />
				<Loading />
			</AppWrapper>
		</StoreProvider>
	)
}

export default App;
