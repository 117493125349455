import React, { useCallback } from 'react'
import { useDropzone } from 'react-dropzone';
import { useApplication } from './../hooks/useApplication';
import { Button } from './Button';

const KeyUpload = ({ path, name, keyType, setFilePath, disabled }) => {
    const applicationHook = useApplication();
    const acceptFiles = { 'application/x-pem-file': ['.pem'] };

    const onDrop = useCallback(acceptedFiles => {
        if (acceptedFiles.length > 0) {
            setFilePath && setFilePath(path + '/' + acceptedFiles[0].name)

            let formData = new FormData();
            formData.append('files', acceptedFiles[0]);
            formData.append('keyType', keyType);
            formData.append('path', path);
            applicationHook.uploadKey(formData);
        }
    }, [])

    const onDropRejected = useCallback(reject => {
        console.log(reject)
    }, [])

    const { getRootProps, getInputProps, open } = useDropzone({ onDrop, onDropRejected, noClick: true, accept: acceptFiles, multiple: false, maxFiles: 30 });

    return (
        <div className='w-full'>
            <div {...getRootProps()}>
                <input {...getInputProps()} />
                <Button name={`${name ? name : 'Upload Key'}`} type={"outline"} handleFunction={open} disabled={disabled} className={'w-full'} />
            </div>
        </div>
    )
}

export default KeyUpload