import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { useStore } from '../../store/AppContext';
import { BASE_URL } from '../../config';

const FileBased = ({ containerStyle, authConfig, setAuthConfig, setCanSave, setShowToast }) => {
    const { state } = useStore();
    const [selectedFile, setSelectedFile] = useState([]);
    const [isMouseOver, setMouseOver] = useState(false);
    const [disabledFields, setDisabledFileds] = useState(false);
    const acceptFiles = { 'text/csv': ['.csv'], 'application/vnd.ms-excel': ['.xls'], 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'] };
    const formData = new FormData();

    useEffect(() => {
        setDisabledFileds(authConfig && authConfig.type && (authConfig.type !== 'file_based'));
    }, [authConfig])

    useEffect(() => {
        if (selectedFile.length > 0) {
            formData.append('file', selectedFile[0]);
            setAuthConfig({ type: 'file_based', label: 'File Based', value: formData });
            setCanSave(true);
        }
    }, [selectedFile])

    const onDrop = useCallback(acceptedFiles => {
        setSelectedFile(acceptedFiles);
    }, [])

    const onDropRejected = useCallback(reject => {
        console.log(reject)
    }, [])

    const { getRootProps, getInputProps, open } = useDropzone({ onDrop, onDropRejected, noClick: true, accept: acceptFiles, multiple: false, maxFiles: 1, maxSize: 26214400, disabled: disabledFields }, [disabledFields]);

    const onClear = () => {
        setSelectedFile([]);
        setAuthConfig({ type: null, label: null, value: null });
    }

    const formatBytes = (bytes, decimals = 2) => {
        if (!+bytes) return '0 Bytes'

        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB']

        const i = Math.floor(Math.log(bytes) / Math.log(k))

        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }

    return (
        <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div className={`h-full bg-white-gray-500 dark:bg-gray-black p-6 rounded-2xl items-center self-stretch ${containerStyle}`}>
                <p className="mb-4 py-3 text-gray-dark dark:text-white text-lg font-semibold text-center">File Based</p>
                <div className='flex'>
                    <p className='text-gray-dark dark:text-white font-bold mr-3'>1</p>
                    <div className="flex flex-col mb-4 items-start w-full">
                        <button className="text-gray-dark dark:text-white font-bold px-6 py-3 bg-blue rounded-lg cursor-pointer"><a href={`${BASE_URL}/llm_manager/assets/template/Generate Enterprise Filebased Template.xlsx`} rel='noreferrer' target='_blank' download={'Generate Enterprise Filebased Template.xlsx'}>Download Excel Template</a></button>
                        <p className="text-gray-light mt-1">Download and complete spreadsheet with user credentials. Please don't use "<span className='font-black'>,</span>" in any fileds.</p>
                    </div>
                </div>
                <div className='flex'>
                    <p className='text-gray-dark dark:text-white font-bold mr-3'>2</p>
                    <div onClick={() => disabledFields && setShowToast(true)} onMouseEnter={() => !disabledFields && setMouseOver(true)} onMouseLeave={() => !disabledFields && setMouseOver(false)} className={`flex justify-center items-center border-2 rounded-lg border-gray-light border-dashed px-6 py-4 ${disabledFields ? 'text-gray-light-600 border-gray-light-600' : 'hover:bg-white-gray hover:dark:bg-gray-dark hover:border-blue-dark hover:dark:border-blue cursor-pointer'} `}>
                        <img onClick={open} src={isMouseOver ? "/assets/images/icon-dark-add-file-Hover.svg" : (state.isDarkMode ? "/assets/images/icon-dark-add-file-default.svg" : "/assets/images/icon-dark-add-file-default-light-mode.svg")} className={`w-[40px] mr-3 ${disabledFields ? '' : 'cursor-pointer'}`} alt="SVG as an img" />
                        <p onClick={open} className={`text-gray-dark dark:text-white text-lg font-semibold ${disabledFields ? '' : 'cursor-pointer'}`}>Drop the completed Excel template here or <button className="text-blue-dark dark:text-blue disabled:text-gray-light-600 disabled:dark:text-gray-light-600" onClick={open} disabled={disabledFields}>browse</button></p>
                    </div>
                </div>
                {(selectedFile.length !== 0) &&
                    selectedFile.map((file, index) => (
                        <div key={index} className={`flex flex-row mt-4 ml-[22px] border-2 border-gray-medium px-6 py-3 rounded-lg`}>
                            <div className="flex w-10 h-10 p-2 items-center justify-center border border-gray-medium rounded-lg mr-3">
                                <FontAwesomeIcon className="text-gray-dark dark:text-white w-6" icon={(file.type === 'text/csv') ? faFileCsv : faFileExcel} />
                            </div>
                            <div className="file-based-file-name">
                                <p className="text-gray-dark dark:text-white text-nowrap overflow-auto mr-3">{file.name}</p>
                                <p className="text-gray-light">{formatBytes(file.size)}</p>
                            </div>
                            <div className="flex justify-end items-start w-4 h-full">
                                <button onClick={onClear} className="text-gray-dark dark:text-white text-base font-bold">X</button>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default FileBased;
