import React, { useEffect } from 'react';
import { BASE_URL } from '../config';

const VideoPlayModal = ({ link, isOpen, onClose }) => {
    useEffect(() => {
        const popupDiv = document.getElementById('video_player_popup_div');
        if (isOpen) {
            popupDiv.style.display = 'flex';
        } else {
            setTimeout(() => {
                popupDiv.style.display = 'none';
            }, 500);
        }
    }, [isOpen])

    const handleClose = () => {
        onClose();
    }

    return (
        <div id={'video_player_popup_div'} className={`fixed inset-0 z-50 hidden items-center justify-center`}>
            <div className={`fixed inset-0 bg-white dark:bg-black ${isOpen ? 'popup-ease-in-opacity ' : 'popup-ease-out-opacity '} opacity-50`} onClick={handleClose} />
            <div className={`${isOpen ? 'popup-ease-in ' : 'popup-ease-out '} bg-white dark:bg-gray-dark border-2 border-white-gray-400 dark:border-gray  drop-shadow-xl p-6 rounded-lg z-10, justify-center`}>
                <video className='mt-5' src={ link ? link : `${BASE_URL}/llm_manager/guide/video.mp4`} width="750" height='500' controls />
            </div>
        </div>
    )
}

export default VideoPlayModal;