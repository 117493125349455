import React, { useCallback, useEffect, useState } from 'react';
import { InputWrapper } from './InputWrapper';
import { useStore } from '../store/AppContext';
import { Button, TextButton } from './Button';
import { useDropzone } from 'react-dropzone';
import { BASE_URL } from '../config';
import { useUser } from '../hooks/useUser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TooltipWrapper from './TooltipWrapper';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const ProfileModal = ({ isOpen, onClose }) => {
	const { state } = useStore();
	const userHook = useUser();
	const [canSave, setCanSave] = useState(false);
	const [fullName, setFullName] = useState((state.user.first_name ? state.user.first_name : '') + ' ' + (state.user.last_name ? state.user.last_name : ''));
	const [userInfo, setUserInfo] = useState({ fullName: fullName.trim() });
	const [validPassword, setValidPassword] = useState(true);
	const [viewOldPassword, setViewOldPassword] = useState(false);
	const [viewNewPassword, setViewNewPassword] = useState(false);

	const acceptFiles = { 'image/jpeg': ['.jpeg', '.jpg'], 'image/png': ['.png'] };
	const passwordValidateString = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/;
	const validationMessage = <div>
		<p>Your password must meet the following criteria:</p>
		<ul className='ml-2'>
			<li>1. Length must be between <span className='font-semibold'>8</span> and <span className='font-semibold'>16</span> characters</li>
			<li>2. Include at least <span className='font-semibold'>one uppercase</span> letter (A-Z)</li>
			<li>3. Include at least <span className='font-semibold'>one lowercase</span> letter (a-z)</li>
			<li>4. Include at least <span className='font-semibold'>one digit</span> (0-9)</li>
			<li>5. Include at least <span className='font-semibold'>one special</span> character (e.g., !@#$%^&*)</li>
		</ul>
	</div>

	useEffect(() => {
		const popupDiv = document.getElementById('profile_popup_div');
		if (isOpen) {
			popupDiv.style.display = 'flex';
		} else {
			setTimeout(() => {
				popupDiv.style.display = 'none';
			}, 500);
		}
	}, [isOpen])

	useEffect(() => {
		setFullName((state.user.first_name ? state.user.first_name : '') + ' ' + (state.user.last_name ? state.user.last_name : ''));
	}, [state.user])

	useEffect(() => {
		setUserInfo({ fullName: fullName.trim() });
	}, [fullName])

	useEffect(() => {
		setCanSave(((userInfo.fullName !== fullName.trim()) && userInfo.newPassword && userInfo.newPassword.match(passwordValidateString) && userInfo.oldPassword) || (userInfo.newPassword && userInfo.newPassword.match(passwordValidateString) && userInfo.oldPassword) || (!userInfo.newPassword && (userInfo.fullName !== fullName.trim())));
		setValidPassword(userInfo.newPassword ? userInfo.newPassword.match(passwordValidateString) : true)
	}, [userInfo])

	const onDrop = useCallback(acceptedFiles => {
		if (acceptedFiles.length > 0) {
			let formData = new FormData();
			formData.append('files', acceptedFiles[0]);
			formData.append('baseUrl', BASE_URL);
			userHook.uploadProfileImage(formData, handleClose);
		}
	}, [])

	const onDropRejected = useCallback(reject => {
		console.log(reject)
	}, [])

	const { getRootProps, getInputProps, open } = useDropzone({ onDrop, onDropRejected, noClick: true, accept: acceptFiles, multiple: false, maxFiles: 1 });

	const handleClose = () => {
		onClose();
	}
	const handleChange = (e) => {
		setUserInfo((prevData) => ({ ...prevData, [e.target.name]: e.target.value }));
	}

	const handleSave = () => {
		userHook.updateUser(userInfo, handleClose);
	}

	const onRemoveProfileImage = () => {
		userHook.removeProfileImage(handleClose);
	}

	return (
		<div id={'profile_popup_div'} className={`fixed inset-0 z-[100] hidden items-center justify-center`}>
			<div className={`fixed inset-0 bg-white dark:bg-black ${isOpen ? 'popup-ease-in-opacity ' : 'popup-ease-out-opacity '} opacity-50`} onClick={handleClose} />
			<div className={`${isOpen ? 'popup-ease-in ' : 'popup-ease-out '} text-gray-dark dark:text-white bg-white-500 dark:bg-gray-dark border border-white-gray-400 dark:border-gray w-[600px] drop-shadow-xl p-6 rounded-2xl z-10`}>
				<div className='font-light overflow-y-auto '>
					<div className='flex flex-row justify-between mb-4'>
						<h4 className='text-xl'>Profile</h4>
						<button className="flex items-start text-2xl focus:outline-none" onClick={handleClose} >
							<FontAwesomeIcon icon="xmark" />
						</button>
					</div>
					<div className='grid grid-cols-5 gap-8'>
						<form className='col-span-3' onChange={handleChange}>
							<p className='font-medium'>Full Name</p>
							<InputWrapper containerStyle={"mt-1 mb-4"} value={userInfo.fullName} name={"fullName"} label={userInfo.fullName ? userInfo.fullName : "Full Name"} type={"text"} />
							<p className='font-medium'>Old Password <span className='text-red'>{userInfo.newPassword ? '*' : ''}</span></p>
							<InputWrapper containerStyle={"mt-1 mb-4"} value={userInfo.oldPassword} name={"oldPassword"} label={"Old Password"} type={viewOldPassword ? "text" : "password"} rightIcon={userInfo.oldPassword && <FontAwesomeIcon icon={viewOldPassword ? faEye : faEyeSlash} onClick={() => setViewOldPassword(!viewOldPassword)} className="text-gray-dark dark:text-white cursor-pointer" />} autoComplete={"new-password"}/>
							<div className='flex flex-row items-center font-medium'>
								New Password
								<TooltipWrapper content={validationMessage} tootTipStyle={'w-[360px] -mt-16'} autoHide={false} isShowArrow={true} onTop={false}>
									<div className='flex ml-1 items-center justify-center'>
										<svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
											<path d="M9 2C6.65625 2 4.53125 3.25 3.34375 5.25C2.1875 7.28125 2.1875 9.75 3.34375 11.75C4.53125 13.7812 6.65625 15 9 15C11.3125 15 13.4375 13.7812 14.625 11.75C15.7812 9.75 15.7812 7.28125 14.625 5.25C13.4375 3.25 11.3125 2 9 2ZM9 16.5C6.125 16.5 3.5 15 2.0625 12.5C0.625 10.0312 0.625 7 2.0625 4.5C3.5 2.03125 6.125 0.5 9 0.5C11.8438 0.5 14.4688 2.03125 15.9062 4.5C17.3438 7 17.3438 10.0312 15.9062 12.5C14.4688 15 11.8438 16.5 9 16.5ZM7.75 11H8.5V9H7.75C7.3125 9 7 8.6875 7 8.25C7 7.84375 7.3125 7.5 7.75 7.5H9.25C9.65625 7.5 10 7.84375 10 8.25V11H10.25C10.6562 11 11 11.3438 11 11.75C11 12.1875 10.6562 12.5 10.25 12.5H7.75C7.3125 12.5 7 12.1875 7 11.75C7 11.3438 7.3125 11 7.75 11ZM9 6.5C8.4375 6.5 8 6.0625 8 5.5C8 4.96875 8.4375 4.5 9 4.5C9.53125 4.5 10 4.96875 10 5.5C10 6.0625 9.53125 6.5 9 6.5Z" fill="#788493" />
										</svg>
									</div>
								</TooltipWrapper>
							</div>
							<InputWrapper containerStyle={"mt-1"} value={userInfo.newPassword} name={"newPassword"} label={"New Password"} type={viewNewPassword ? "text" : "password"} rightIcon={userInfo.newPassword && <FontAwesomeIcon icon={viewNewPassword ? faEye : faEyeSlash} onClick={() => setViewNewPassword(!viewNewPassword)} className="text-gray-dark dark:text-white cursor-pointer" />} autoComplete={"new-password"} />
							<p className='font-medium text-xs mt-1 h-2 text-red'>{!validPassword ? "Please enter a valid password" : ""}</p>
						</form>
						<div className='col-span-2 flex align-center flex-col gap-2'>
							<div className='flex align-center flex-col' {...getRootProps()}>
								<input {...getInputProps()} />
								{state.user && state.user.user_image && state.user.user_image !== 'null' ?
									<div className='flex justify-center items-center mb-4'>
										<img onClick={open} className='flex w-36 h-36 justify-center items-center rounded-full cursor-pointer mb-1' src={state.user.user_image} />
									</div>
									:
									<div className='flex justify-center items-center w-full mb-4' >
										<div onClick={open} className='flex w-40 h-40 justify-center items-center text-gray dark:text-white-gray-dark bg-white-gray-600 dark:bg-gray-black rounded-full p-2 cursor-pointer'>
											<svg xmlns="http://www.w3.org/2000/svg" width="176" height="160" viewBox="0 0 20 20" className='pl-7 pt-4' fill="none">
												<path fillRule="evenodd" clipRule="evenodd" d="M10.7945 4.36364C10.7945 5.97028 9.49202 7.27273 7.88538 7.27273C6.27873 7.27273 4.97629 5.97028 4.97629 4.36364C4.97629 2.75699 6.27873 1.45455 7.88538 1.45455C9.49202 1.45455 10.7945 2.75699 10.7945 4.36364ZM12.249 4.36364C12.249 6.77361 10.2953 8.72727 7.88538 8.72727C5.47541 8.72727 3.52174 6.77361 3.52174 4.36364C3.52174 1.95367 5.47541 0 7.88538 0C10.2953 0 12.249 1.95367 12.249 4.36364ZM14.3396 15.5539C14.4784 15.8203 14.7471 16 15.0474 16C15.569 16 15.9317 15.4835 15.6988 15.0168C14.2681 12.1505 11.3067 10.1818 7.88538 10.1818C4.4641 10.1818 1.50263 12.1505 0.0719854 15.0168C-0.160924 15.4835 0.20177 16 0.723307 16C1.02368 16 1.29237 15.8203 1.43114 15.5539C2.64377 13.226 5.07894 11.6364 7.88538 11.6364C10.6918 11.6364 13.127 13.226 14.3396 15.5539Z" fill="currentColor" />
											</svg>
										</div>
									</div>
								}
								<Button className={"mt-1 py-[8px] text-gray-dark dark:text-white border-gray-dark dark:border-white hover:border-blue hover:text-blue"} handleFunction={open} type={'outline'} name={"Upload Photo"} />
							</div>
							<TextButton addHandleFunction={onRemoveProfileImage} label={"Remove Photo"} labelClassName={'hover:border-b-2 hover:border-blue hover:-mb-[2px]'} />
						</div>
					</div>
					<div className='border-t border-gray w-full mt-8 gap-4 pt-4 px-4 flex justify-end'>
						<Button className={"w-[120px] py-[8px]"} handleFunction={handleClose} type={'outline'} name={"Cancel"} />
						<Button className={"w-[120px] py-[8px]"} handleFunction={handleSave} disabled={!canSave} name={"Save"} />
					</div>
				</div>
			</div>
		</div>
	)
}

export default ProfileModal;
