import React, { useEffect, useRef, useState } from 'react';
import { useStore } from '../../store/AppContext';
import ContentPicker from './ContentPicker';
import axios from 'axios';
import { API_KEY, API_URL, INTEGRATION_CREDENTIALS } from '../../config';
import { getPopupDimension } from './utils';
import { useIntegration } from '../../hooks/useIntegration';
import Cookies from "js-cookie";

const DEFAULT_LOGO = <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
	<g clipPath="url(#clip0_3124_1485)">
		<path d="M7.46387 47.9739C4.76354 47.9797 2.26892 46.5322 0.934161 44.1848C-0.381361 41.8518 -0.302475 38.9834 1.13928 36.7263L14.5862 15.6102C15.9873 13.2997 18.5232 11.9218 21.2241 12.0034C23.9231 12.0681 26.378 13.5813 27.6484 15.9634C28.902 18.3337 28.7453 21.1855 27.2439 23.4105L13.8056 44.5267C12.4205 46.6849 10.0283 47.9853 7.46387 47.9739Z" fill="#F62B54" />
		<path d="M30.5287 47.9739C27.8165 47.9739 25.3208 46.5237 24.0103 44.1933C22.6977 41.8667 22.7766 39.0056 24.2155 36.7548L37.6367 15.6871C39.0176 13.3435 41.5611 11.9332 44.2804 12.0034C47.0153 12.0633 49.4939 13.5931 50.7474 15.9862C51.9924 18.3793 51.8101 21.2482 50.2631 23.4675L36.8447 44.5352C35.4641 46.6841 33.0828 47.9805 30.5287 47.9739Z" fill="#FFCC00" />
		<path d="M53.0779 48.1505C56.9013 48.1505 60.0008 45.1161 60.0008 41.3729C60.0008 37.6298 56.9013 34.5953 53.0779 34.5953C49.2545 34.5953 46.155 37.6298 46.155 41.3729C46.155 45.1161 49.2545 48.1505 53.0779 48.1505Z" fill="#00CA72" />
	</g>
	<defs>
		<clipPath id="clip0_3124_1485">
			<rect width="60" height="60" fill="white" transform="translate(0 0.5)" />
		</clipPath>
	</defs>
</svg>;

const COMING_SOON_LOGO = <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
	<g clipPath="url(#clip0_3203_77781)">
		<path d="M7.46387 47.9739C4.76354 47.9797 2.26892 46.5322 0.934161 44.1848C-0.381361 41.8518 -0.302475 38.9834 1.13928 36.7263L14.5862 15.6102C15.9873 13.2997 18.5232 11.9218 21.2241 12.0034C23.9231 12.0681 26.378 13.5813 27.6484 15.9634C28.902 18.3337 28.7453 21.1855 27.2439 23.4105L13.8056 44.5267C12.4205 46.6849 10.0283 47.9853 7.46387 47.9739Z" fill="#B7C2D2" />
		<path d="M30.5287 47.9739C27.8165 47.9739 25.3208 46.5238 24.0103 44.1934C22.6977 41.8668 22.7766 39.0056 24.2155 36.7549L37.6367 15.6871C39.0176 13.3435 41.5611 11.9333 44.2804 12.0035C47.0153 12.0633 49.4939 13.5932 50.7474 15.9863C51.9924 18.3794 51.8101 21.2482 50.2631 23.4675L36.8447 44.5352C35.4641 46.6841 33.0828 47.9805 30.5287 47.9739Z" fill="#B7C2D2" />
		<path d="M53.0779 48.1505C56.9013 48.1505 60.0008 45.1161 60.0008 41.3729C60.0008 37.6298 56.9013 34.5953 53.0779 34.5953C49.2545 34.5953 46.155 37.6298 46.155 41.3729C46.155 45.1161 49.2545 48.1505 53.0779 48.1505Z" fill="#B7C2D2" />
	</g>
	<defs>
		<clipPath id="clip0_3203_77781">
			<rect width="60" height="60" fill="white" />
		</clipPath>
	</defs>
</svg>;

const MondayDotCom = ({ comingSoon, appId }) => {
	const integrationHook = useIntegration();
	const mondaydotcomRef = useRef(true);
	const { state, dispatch } = useStore();
	const [enableRevokeIcon, setEnableRevokeIcon] = useState(false);
	const [openPicker, setOpenPicker] = useState(false);
	const [boardsData, setBoardsData] = useState({
		logo: DEFAULT_LOGO,
		title: 'Boards',
		contents: []
	});

	useEffect(() => {
		setOpenPicker(boardsData && boardsData.contents && (boardsData.contents.length > 0));
	}, [boardsData])

	const onClick = () => {
		if (state.integrations && state.integrations['mondaydotcom']) {
			getBoards();
		} else {
			mondaydotcomRef.current = true;
			window.open(`https://auth.monday.com/oauth2/authorize?client_id=${INTEGRATION_CREDENTIALS.mondaydotcom.clientId}&redirect_uri=${INTEGRATION_CREDENTIALS.mondaydotcom.redirectURL}`, 'AuthWindow', getPopupDimension());

			const messageListener = (event) => {
				if (event.target.origin !== window.location.origin) {
					return;
				}

				const data = event.data;
				if (mondaydotcomRef.current && data) {
					mondaydotcomRef.current = false;
					if (data.error) {
						dispatch({
							type: "SET_MESSAGE",
							payload: {
								type: "fail",
								title: "Authorization Failed",
								subtitle: "Please try again!"
							}
						})
					}

					if (data.code) {
						const req = {
							integration: 'mondaydotcom',
							url: 'https://auth.monday.com/oauth2/token',
							data: {
								code: data.code,
								client_id: INTEGRATION_CREDENTIALS.mondaydotcom.clientId,
								client_secret: INTEGRATION_CREDENTIALS.mondaydotcom.clientSecret,
								redirect_uri: INTEGRATION_CREDENTIALS.mondaydotcom.redirectURL,
								grant_type: 'authorization_code'
							},
							headers: {
								"content-type": 'application/json'
							}
						}
						integrationHook.saveOauth(req);
					}
				}
				window.removeEventListener('message', messageListener);
			};

			window.addEventListener('message', messageListener);
		}
	}

	const getBoards = () => {
		const config = state.integrations['mondaydotcom'];
		dispatch({
			type: "LOADING",
			payload: true
		})
		axios.get(`${API_URL}/integration/mondaydotcom/list-boards`, { headers: { Authorization: Cookies.get("token"), apikey: API_KEY, oauth_key: config.oauth_key } })
			.then(res => {
				const data = {
					...boardsData,
					contents: res.data && res.data.boards ? res.data.boards : []
				}
				setBoardsData(data);
				dispatch({
					type: "LOADING",
					payload: false
				})
			})
			.catch(() => {
				dispatch({
					type: "LOADING",
					payload: false
				})
			})

	}

	const handlePick = (pickedContents) => {
		const config = state.integrations['mondaydotcom'];
		const req = {
			appId: appId,
			board_ids: pickedContents
		}
		integrationHook.storeIntegrationData('mondaydotcom', config.oauth_key, req);
		onPickerClose();
	}

	const onPickerClose = () => {
		setBoardsData({
			logo: DEFAULT_LOGO,
			title: 'Boards',
			contents: []
		});
	}

	const handleRevoke = () => {
		const req = {
			hasRevokeAPI: false,
			integration: 'mondaydotcom',
		}
		integrationHook.revokeIntegrationData(req, setEnableRevokeIcon);
	}

	return (
		<div>
			<button disabled={comingSoon} onClick={() => !enableRevokeIcon && onClick()} className={`flex flex-col relative justify-center items-center w-[150px] h-[150px] rounded-xl px-2 py-5 gap-5 ${comingSoon ? 'bg-white-gray dark:bg-gray-light-200' : 'bg-white-gray-600 dark:bg-black'}`}>
				{state.integrations && state.integrations['mondaydotcom'] &&
					<div className='w-[150px] h-[150px] absolute flex justify-end items-start pr-2 pt-2'>
						<div className='dark:text-gray-dark text-white' onClick={() => enableRevokeIcon && handleRevoke()} onMouseOver={() => setEnableRevokeIcon(true)} onMouseLeave={() => setEnableRevokeIcon(false)}>
							{enableRevokeIcon ?
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="-4 4 48 48">
									<path fill="#e75670" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path>
									<path fill="currentColor" d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z"></path>
									<path fill="currentColor" d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z"></path>
								</svg>
								:
								<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<rect width="20" height="20" rx="10" fill="#2BB86C" />
									<path d="M15.0859 6.91406C15.2969 7.14844 15.2969 7.5 15.0859 7.71094L8.89844 13.8984C8.66406 14.1328 8.3125 14.1328 8.10156 13.8984L4.91406 10.7109C4.67969 10.5 4.67969 10.1484 4.91406 9.9375C5.125 9.70312 5.47656 9.70312 5.6875 9.9375L8.47656 12.7266L14.2891 6.91406C14.5 6.70312 14.8516 6.70312 15.0625 6.91406H15.0859Z" fill="currentColor" />
								</svg>
							}
						</div>
					</div>
				}
				{comingSoon ? COMING_SOON_LOGO : DEFAULT_LOGO}
				<div className='flex flex-col justify-center items-center'>
					<p className={`${comingSoon ? 'text-gray-light' : 'text-gray-dark dark:text-white'} font-medium`}>Monday.com</p>
					{comingSoon && <p className='text-gray-dark dark:text-white text-xs font-normal mt-1'>Coming Soon</p>}
				</div>
			</button>
			<ContentPicker integrationId={'mondaydotcom'} fieldId={'id'} fieldName={'name'} data={boardsData} handlePick={handlePick} isOpen={openPicker} onClose={onPickerClose} />
		</div>
	)
}

export default MondayDotCom;
