import axios from "axios";
import Cookies from "js-cookie";
import { API_URL, API_KEY } from "../config";
import { useStore } from '../store/AppContext';

export const useConfig = () => {
    const { dispatch } = useStore();

    return {
        getConfig: (configKey) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })
            axios
                .get(`${API_URL}/config/${configKey}`,
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        },
                    })
                .then((res) => {
                    dispatch({
                        type: `SET_${configKey}_CONFIG`,
                        payload: res.data,
                    })
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                })
                .catch((err) => {
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Configuration Retrieval Failed",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                })
        },
        updateConfig: (configKey, configValue, showmessage = true, navigate = null, navLink = null, message) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })
            axios
                .post(`${API_URL}/config/save/${configKey}`, { configValue: configValue },
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        },
                    })
                .then((res) => {
                    dispatch({
                        type: `SET_${configKey}_CONFIG`,
                        payload: configValue,
                    })
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    showmessage && dispatch({
                        type: "SET_MESSAGE",
                        payload: message ? message : {
                            type: "success",
                            title: "Saved Successfully",
                            subtitle: "Your changes have been saved successfully"
                        }
                    })
                    navLink && navigate(navLink);
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    showmessage && dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Save Failed",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                })
        },
        deleteConfig: (configKey, configValue, showmessage = true, navigate = null, navLink = null) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })
            axios
                .post(`${API_URL}/config/delete/${configKey}`, { configValue: configValue },
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        },
                    })
                .then((res) => {
                    dispatch({
                        type: `SET_${configKey}_CONFIG`,
                        payload: res.data,
                    })
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    showmessage && dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "success",
                            title: "Saved Successfully",
                            subtitle: "Your changes have been saved successfully"
                        }
                    })
                    navLink && navigate(navLink);
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    showmessage && dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Delete Failed",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                })
        },
        uploadFileBased: (formData, navigate, navLink) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })
            axios
                .post(`${API_URL}/user/file_based`, formData,
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                            'Content-Type': 'multipart/form-data',
                        },
                    })
                .then((res) => {
                    if (res.data) {
                        dispatch({
                            type: `UPDATE_AUTH_CONFIG`,
                            payload: { type: 'file_based', label: 'File Based', value: {} },
                        })
                        dispatch({
                            type: "LOADING",
                            payload: false,
                        })
                        dispatch({
                            type: "SET_MESSAGE",
                            payload: ['success', 'warning'].includes(res.data.status) ?
                            {
                                type: "success",
                                title: "Saved Successfully",
                                subtitle: res.data.message,
                                close: (res.data.status === 'warning') ? "manual" : ""
                            }
                            :
                            {
                                type: "fail",
                                title: "Save Failed",
                                subtitle: res.data.message
                            }
                        })
                        navLink && navigate(navLink);
                    }
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Save Failed",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                })
        }
    }
}
