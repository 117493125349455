import React, { useState } from 'react';
import { InputWrapper } from './InputWrapper';
import Select from "react-select";
import clsx from "clsx";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

const SelectLLM = (props) => {
    const [viewApiKey, setViewApiKey] = useState(false);

    const controlStyles = {
        base: "p-[14px] py-[4px] border rounded-lg bg-red hover:cursor-pointer dark:bg-transparent",
        focus: "border-primary-600 ring-1 ring-primary-500 bg-red dark:bg-transparent",
        nonFocus: "border-gray-300 hover:border-primary-400 bg-red dark:bg-transparent",
    };
    const valueContainerStyles = "p-1 gap-1 bg-transparent";
    const menuList = "dark:bg-gray-700 border-white"
    const noOptionsMessageStyles = "text-white dark:bg-gray-700 dark:text-gray-200";

    return (
        <>
            <h5 className="text-gray-dark dark:text-white font-bold mb-4">Select a LLM</h5>
            <Select
                value={!props.isMulti ? props.llm : props.llmList ? props.llmList : []}
                closeMenuOnSelect={false}
                isMulti={props.isMulti}
                options={props.options}
                onChange={props.isMulti ? props.setLlmList : props.setLLM}
                classNames={{
                    control: ({ isFocused }) =>
                        clsx(
                            isFocused ? controlStyles.focus : controlStyles.nonFocus,
                            controlStyles.base,
                        ),
                    valueContainer: () => valueContainerStyles,
                    noOptionsMessage: () => noOptionsMessageStyles,
                    menuList: () => menuList,
                }}
            />
            <div className='mt-3' />
            {props.llmList && (props.llmList.some(llm => llm.id === 'gpt-4') || props.llmList.some(llm => llm.id === 'gpt-3.5')) &&
                <InputWrapper value={props.gptApiKey ? props.gptApiKey : ''} setValue={props.setGptApiKey} label={"Enter API key here (gpt 4 / 3.5)"} name={"location1"} type={viewApiKey ? "text" : "password"} rightIcon={props.gptApiKey && <FontAwesomeIcon icon={viewApiKey ? faEye : faEyeSlash} onClick={() => setViewApiKey(!viewApiKey)} className="text-gray-dark dark:text-white cursor-pointer" />} containerStyle={'mt-6'} autoComplete={"new-password"} />
            }
        </>
    )
}

export default SelectLLM;