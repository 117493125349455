import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Button } from '../Button';
import { InputWrapper } from '../InputWrapper';
import DropdownWrapper from '../DropdownWrapper';

const WriteFile = ({ parameters, loading, isOpen, onClose, onSubmit }) => {
    const [filename, setFilename] = useState(null);
    const [fileType, setFileType] = useState({});
    const fileTypes = [{ id: 'txt', label: 'txt' }, { id: 'pdf', label: 'pdf' }, { id: 'docx', label: 'docx' }];

    useEffect(() => {
        const popupDiv = document.getElementById('workflow_write_file_popup_div');
        if (isOpen) {
            popupDiv.style.display = 'flex';
        } else {
            setTimeout(() => {
                popupDiv.style.display = 'none';
            }, 500);
        }
    }, [isOpen])

    const onEnter = () => {
        parameters.directory = "C:\\Users\\HP\\Documents";
        parameters.filename = filename;
        parameters.file_type = `.${fileType.id}`;
        onSubmit(parameters, null);
    }

    const handleClose = () => {
        onClose();
    }

    return (
        <div id={'workflow_write_file_popup_div'} className={`absolute inset-0 z-[100] hidden items-center justify-center`}>
            <div className={`fixed inset-0 bg-white dark:bg-black ${isOpen ? 'popup-ease-in-opacity ' : 'popup-ease-out-opacity '} opacity-50`} />
            <div className={`${isOpen ? 'popup-ease-in ' : 'popup-ease-out '} bg-white-500 dark:bg-gray-dark w-2/3 border-2 border-white-gray-400 dark:border-gray drop-shadow-xl p-8 rounded-3xl z-10`}>
                <div className='flex flex-row justify-between'>
                    <div className='flex max-h-60 overflow-auto border border-gray rounded-lg p-4 mr-4'>
                        <p className='text-gray dark:text-gray'>{parameters.content}</p>
                    </div>
                    <button disabled={loading} className="flex text-gray-dark dark:text-white" onClick={handleClose} >
                        <FontAwesomeIcon className='w-6 h-6' icon={faClose} />
                    </button>
                </div>
                <div className='w-full pt-8 pl-1 pr-10'>
                    <div className='flex w-full justify-stretch gap-10'>
                        <div className='w-2/3'>
                            <label className="block text-gray-dark dark:text-white mb-4 text-md font-bold" htmlFor="textarea">
                                {'File name'}
                            </label>
                            <InputWrapper value={filename ? filename : ""} setValue={setFilename} label={"File name"} name={"filename"} type={"text"} />
                        </div>
                        <div className='w-1/3'>
                            <DropdownWrapper value={fileType.id} setValue={setFileType} placeholder={'Select file type'} options={fileTypes} name={'fileType'} label={"File type"} dropdownClassName={'max-h-16'} dropdownContainerClassName={'absolute'} />
                        </div>
                    </div>
                    <div className='flex w-full justify-end'>
                        <Button loading={loading} className={"self-center py-[8px] flex flex-row items-center justify-center content-center"} handleFunction={onEnter} name={"Save"} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WriteFile;