import axios from "axios";
import Cookies from "js-cookie";
import { API_URL, API_KEY } from "../config";
import { useStore } from '../store/AppContext';

export const useDbSearch = () => {
    const { dispatch } = useStore();

    return {
        getDbSearchResponse: (request) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })

            axios
                .post(
                    `${API_URL}/application/db_search/response`, request,
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        }
                    }
                )
                .then((res) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })

                    if (res.data && (res.data.status === 'success')) {
                        dispatch({
                            type: "SET_DB_SEARCH_RESPONSE",
                            payload: res.data
                        })
                    } else {
                        dispatch({
                            type: "SET_MESSAGE",
                            payload: {
                                type: "fail",
                                title: "Faild on get results",
                                subtitle: "Please try again"
                            }
                        })
                        dispatch({
                            type: "SET_DB_SEARCH_STREAMING",
                            payload: false
                        })
                    }
                })
                .catch(() => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_DB_SEARCH_STREAMING",
                        payload: false
                    })
                })
        },
        connectDatabase: (showStatusMessage, request) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })

            axios
                .post(
                    `${API_URL}/application/db_search/connect`, request,
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        }
                    }
                )
                .then((res) => {
                    
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    if (res.data && (res.data.status === 'error')) {
                        showStatusMessage && dispatch({
                            type: "SET_MESSAGE",
                            payload: {
                                type: "fail",
                                title: "Failed to connect database",
                                subtitle: "Please try again"
                            }
                        })
                    } else {
                        dispatch({
                            type: "SET_DB_SEARCH_CONNECT",
                            payload: res.data
                        })

                        showStatusMessage && dispatch({
                            type: "SET_MESSAGE",
                            payload: {
                                type: "success",
                                title: "Database Connected!",
                                subtitle: "Database connected successfully"
                            }
                        })
                    }
                })
                .catch(() => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    showStatusMessage && dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Failed to connect database",
                            subtitle: "Please try again"
                        }
                    })
                })
        },
        getDbResourcesByUser: () => {
            dispatch({
                type: "LOADING",
                payload: true,
            })
            axios
                .get(
                    `${API_URL}/application/db_search/get_docs`,
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        }
                    }
                )
                .then((res) => {
                    if (res.data) {
                        dispatch({
                            type: "SET_DB_RESOURCES_BY_USER",
                            payload: res.data,
                        })
                    }
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                })
                .catch((res) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                })
        },
        updateDbUserPermission: (request) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })
            axios
                .post(
                    `${API_URL}/application/db_search/update_document_permission`,
                    request,
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        }
                    }
                )
                .then((res) => {
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "success",
                            title: "Permission Updated!",
                            subtitle: "Document permission updated successfully"
                        }
                    })
                    dispatch({
                        type: "UPDATE_DB_USER_PERMISSION",
                        payload: res.data,
                    })
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                })
                .catch((res) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })

                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Failed to update user group",
                            subtitle: "Please try again"
                        }
                    })
                })
        },
        deleteDatabases: (request) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })
            axios
                .post(`${API_URL}/application/db_search/delete_document`,
                    request,
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        }
                    }
                )
                .then((res) => {
                    if (res.data && res.data.status === 'success') {
                        dispatch({
                            type: "SET_MESSAGE",
                            payload: {
                                type: "success",
                                title: "Delete Success",
                                subtitle: "Your document deleted successfully"
                            }
                        })
                        dispatch({
                            type: "DELETE_DB_RESOURCES",
                            payload: res.data.file_info,
                        })
                    } else {
                        dispatch({
                            type: "SET_MESSAGE",
                            payload: {
                                type: "fail",
                                title: "Failed to delete file",
                                subtitle: res.data && res.data.error ? res.data.error : "Please try again"
                            }
                        })
                    }

                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                })
                .catch(() => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Failed to delete file",
                            subtitle: "Please try again"
                        }
                    })
                })
        }
    }
}
