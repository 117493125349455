import React, { useEffect, useState } from 'react';
import useDrivePicker from "react-google-drive-picker";
import { useStore } from '../../store/AppContext';
import { useIntegration } from '../../hooks/useIntegration';
import { INTEGRATION_CREDENTIALS } from '../../config';

const DEFAULT_LOGO = <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
	<g clipPath="url(#clip0_3203_77826)">
		<path d="M4.53608 48.4449L7.18213 53.0154C7.73196 53.9776 8.52234 54.7336 9.45017 55.2834L18.9003 38.926H0C0 39.9913 0.274914 41.0566 0.824742 42.0188L4.53608 48.4449Z" fill="#0066DA" />
		<path d="M30 19.6821L20.5498 3.32471C19.622 3.87454 18.8316 4.63055 18.2818 5.59275L0.824742 35.8333C0.285026 36.7747 0.0007186 37.8409 0 38.9261H18.9003L30 19.6821Z" fill="#00AC47" />
		<path d="M50.5499 55.2834C51.4778 54.7336 52.2681 53.9776 52.818 53.0154L53.9176 51.1253L59.1754 42.0188C59.7252 41.0566 60.0001 39.9913 60.0001 38.926H41.0984L45.1204 46.8298L50.5499 55.2834Z" fill="#EA4335" />
		<path d="M30 19.6821L39.4501 3.32474C38.5223 2.77491 37.457 2.5 36.3574 2.5H23.6426C22.5429 2.5 21.4776 2.80928 20.5498 3.32474L30 19.6821Z" fill="#00832D" />
		<path d="M41.0997 38.926H18.9004L9.4502 55.2834C10.378 55.8332 11.4433 56.1082 12.543 56.1082H47.4571C48.5567 56.1082 49.622 55.7989 50.5499 55.2834L41.0997 38.926Z" fill="#2684FC" />
		<path d="M50.4467 20.713L41.7182 5.59275C41.1684 4.63055 40.378 3.87454 39.4502 3.32471L30 19.6821L41.0997 38.9261H59.9656C59.9656 37.8608 59.6907 36.7955 59.1409 35.8333L50.4467 20.713Z" fill="#FFBA00" />
	</g>
	<defs>
		<clipPath id="clip0_3203_77826">
			<rect width="60" height="60" fill="white" />
		</clipPath>
	</defs>
</svg>;

const COMING_SOON_LOGO = <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
	<g clipPath="url(#clip0_3203_77825)">
		<path d="M4.53608 48.4449L7.18213 53.0154C7.73196 53.9776 8.52234 54.7336 9.45017 55.2834L18.9003 38.926H0C0 39.9913 0.274914 41.0566 0.824742 42.0188L4.53608 48.4449Z" fill="#576474" />
		<path d="M30 19.6821L20.5498 3.32471C19.622 3.87454 18.8316 4.63055 18.2818 5.59275L0.824742 35.8333C0.285026 36.7747 0.0007186 37.8409 0 38.9261H18.9003L30 19.6821Z" fill="#B7C2D2" />
		<path d="M50.5499 55.2834C51.4778 54.7336 52.2681 53.9776 52.818 53.0154L53.9176 51.1253L59.1754 42.0188C59.7252 41.0566 60.0001 39.9913 60.0001 38.926H41.0984L45.1204 46.8298L50.5499 55.2834Z" fill="#576474" />
		<path d="M30 19.6821L39.4501 3.32474C38.5223 2.77491 37.457 2.5 36.3574 2.5H23.6426C22.5429 2.5 21.4776 2.80928 20.5498 3.32474L30 19.6821Z" fill="#576474" />
		<path d="M41.0997 38.926H18.9004L9.4502 55.2834C10.378 55.8332 11.4433 56.1082 12.543 56.1082H47.4571C48.5567 56.1082 49.622 55.7989 50.5499 55.2834L41.0997 38.926Z" fill="#B7C2D2" />
		<path d="M50.4467 20.713L41.7182 5.59275C41.1684 4.63055 40.378 3.87454 39.4502 3.32471L30 19.6821L41.0997 38.9261H59.9656C59.9656 37.8608 59.6907 36.7955 59.1409 35.8333L50.4467 20.713Z" fill="#B7C2D2" />
	</g>
	<defs>
		<clipPath id="clip0_3203_77825">
			<rect width="60" height="60" fill="white" />
		</clipPath>
	</defs>
</svg>;

const GoogleDrive = ({ appId, comingSoon }) => {
	const integrationHook = useIntegration();
	const { state } = useStore();
	const [enableRevokeIcon, setEnableRevokeIcon] = useState(false);
	const [openPicker, authResponse] = useDrivePicker();
	const [fileDoc, setFileDoc] = useState([]);
	const VIEW_ID = {
		DOCUMENT_SEARCH: "DOCS",
		DB_SEARCH:'SPREADSHEETS'
	}

	const onClick = async () => {
		const SCOPES = [
			'https://www.googleapis.com/auth/drive.metadata',
			'https://www.googleapis.com/auth/drive.metadata.readonly',
			'https://www.googleapis.com/auth/drive.file',
			'https://www.googleapis.com/auth/drive.readonly'
		];

		openPicker({
			clientId: INTEGRATION_CREDENTIALS.google.clientId,
			developerKey: INTEGRATION_CREDENTIALS.google.clientSecret,
			viewId: VIEW_ID[appId],
			showUploadView: false,
			showUploadFolders: false,
			supportDrives: true,
			multiselect: true,
			customScopes: SCOPES,
            callbackFunction: (data) => {
				if (data.action === 'cancel') return;
	
				if (data.action !== 'picked') return;

				if (data.action === 'picked' && data.docs) {
					if(appId === "DB_SEARCH"){
						let hasInvalidFiles = false;

						data.docs.forEach(file => {
							if (file.mimeType !== 'text/csv') {
								hasInvalidFiles = true;
							}
						});
		
						if (hasInvalidFiles) {
							alert('Please select only CSV files.');
							return;
						}
					}
					setFileDoc(data.docs); 
				}
            }
		})
	}

	useEffect(() => {
		if (authResponse) {
			updateConfig(authResponse);
		}

		if (authResponse && fileDoc && fileDoc.length > 0) {
			let file_ids = [];

			fileDoc.forEach(file => {
				file_ids.push(file.id);
			})
			const req = {
				appId: appId,
				file_ids: file_ids
			}

			integrationHook.storeIntegrationData('google', authResponse.access_token, req);
			setFileDoc([]);
		}
	}, [authResponse, fileDoc])

	const updateConfig = (authResponse) => {
		if (state.integrations && !state.integrations['google']) {
			let integrations = {
				...state.integrations,
				google: {
					oauth_key: authResponse.access_token
				}
			}

			integrationHook.updateIntegration(integrations);
		}
	}

	const handleRevoke = () => {
		const req = {
			hasRevokeAPI: false,
			integration: 'google',
		}
		integrationHook.revokeIntegrationData(req, setEnableRevokeIcon);
	}

	return (
		<div>
			<button disabled={comingSoon} onClick={() => !enableRevokeIcon && onClick()} className={`flex flex-col relative justify-center items-center w-[150px] h-[150px] rounded-xl px-2 py-5 gap-5 ${comingSoon ? 'bg-white-gray dark:bg-gray-light-200' : 'bg-white-gray-600 dark:bg-black'}`}>
				{state.integrations && state.integrations['google'] &&
					<div className='w-[150px] h-[150px] absolute flex justify-end items-start pr-2 pt-2'>
						<div className='dark:text-gray-dark text-white' onClick={() => enableRevokeIcon && handleRevoke()} onMouseOver={() => setEnableRevokeIcon(true)} onMouseLeave={() => setEnableRevokeIcon(false)}>
							{enableRevokeIcon ?
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="-4 4 48 48">
									<path fill="#e75670" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path>
									<path fill="currentColor" d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z"></path>
									<path fill="currentColor" d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z"></path>
								</svg>
								:
								<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<rect width="20" height="20" rx="10" fill="#2BB86C" />
									<path d="M15.0859 6.91406C15.2969 7.14844 15.2969 7.5 15.0859 7.71094L8.89844 13.8984C8.66406 14.1328 8.3125 14.1328 8.10156 13.8984L4.91406 10.7109C4.67969 10.5 4.67969 10.1484 4.91406 9.9375C5.125 9.70312 5.47656 9.70312 5.6875 9.9375L8.47656 12.7266L14.2891 6.91406C14.5 6.70312 14.8516 6.70312 15.0625 6.91406H15.0859Z" fill="currentColor" />
								</svg>
							}
						</div>
					</div>
				}
				{comingSoon ? COMING_SOON_LOGO : DEFAULT_LOGO}
				<div className='flex flex-col justify-center items-center'>
					<p className={`${comingSoon ? 'text-gray-light' : 'text-gray-dark dark:text-white'} font-medium`}>Google Drive</p>
					{comingSoon && <p className='text-gray-dark dark:text-white text-xs font-normal mt-1'>Coming Soon</p>}
				</div>
			</button>
		</div>
	)
}

export default GoogleDrive;
