import React from 'react';
import { Route, Routes } from 'react-router-dom';
import DocSearchConfigPage from './DocSearchConfigPage';
import DocSearch from './DocSearch';
import ManageDocuments from './ManageDocuments';

const DocSearchPage = ({isSidebarOpen, activeLLM}) => {
	return (
		<Routes>
			<Route path='/config' element={<DocSearchConfigPage />} />
			<Route path='/app' element={<DocSearch isSidebarOpen={isSidebarOpen} activeLLM={activeLLM}/>} />
			<Route path='/manage-documents' element={<ManageDocuments />} />
		</Routes>
	)
}

export default DocSearchPage;
