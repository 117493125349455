import axios from "axios";
import Cookies from "js-cookie";
import { API_URL, API_KEY, ROUTES_ID } from "../config";
import { useStore } from '../store/AppContext';
import { useConfig } from "./useConfig";
import { useDbSearch } from "./useDbSearch";
import { useNavigate } from "react-router-dom";

export const useApplication = () => {
    const {state, dispatch } = useStore();
    const configHook = useConfig();
    const dbSearchHook = useDbSearch();
    const navigate = useNavigate();

    return {
        addPermissionsToApplications: async (data, navigate, navLink) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })

            axios
                .post(
                    `${API_URL}/application/set_permission`,
                    data,
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        }
                    }
                )
                .then((res) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "success",
                            title: "Saved Successfully",
                            subtitle: "Your changes have been saved successfully"
                        }
                    })
                    navLink && navigate(navLink);
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Update Failed",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                })
        },
        getAppsPermission: () => {
            dispatch({
                type: "LOADING",
                payload: true,
            })
            axios
                .get(
                    `${API_URL}/application/get_permission`,
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        }
                    }
                )
                .then((res) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    res.data && dispatch({
                        type: "SET_APPS_PERMISSION",
                        payload: res.data
                    })
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Fetch Apps Permission Failed",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                })
        },
        getUsersOfApplications: () => {
            dispatch({
                type: "LOADING",
                payload: true,
            })
            axios
                .get(
                    `${API_URL}/application/get_users`,
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                        }
                    }
                )
                .then((res) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_APPLICATIONS_USERS",
                        payload: res.data
                    })
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Data fetch Failed",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                })
        },
        uploadFile: async (data, dbConfig, setDBConfig) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })

            axios
                .post(
                    `${API_URL}/application/file/upload`,
                    data,
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                            'Content-Type': 'multipart/form-data',
                        }
                    }
                )
                .then(resp => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "success",
                            title: "Upload Successfully",
                            subtitle: "Your file have been uploaded successfully"
                        }
                    })
                    if(data.get('appId') === ROUTES_ID.DATABASE_SEARCH && resp && resp.data && resp.data.docs_paths && resp.data.docs_paths.length > 0 ){
                        const updateConf = dbConfig
                        updateConf['database'][0].doc_path = resp.data.docs_paths[0]
                        setDBConfig({...updateConf})
                        configHook.updateConfig(ROUTES_ID.DATABASE_SEARCH, updateConf, true, navigate, '/db-search/app');
                        if (updateConf && updateConf.database.length > 0)
                            dbSearchHook.connectDatabase(true, { 
                                database_name: updateConf.database[0].databaseName, 
                                database_type: updateConf.database[0].databaseType.id,
                                project_name: updateConf.database[0].projectName, 
                                service_account_file: updateConf.database[0].serviceAccountFile, 
                                table_name: updateConf.database[0].databaseName, 
                                csv_file: updateConf.database[0].doc_path,
                                username: state.user.username,
                                integration:'local'
                            })
                    }
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Upload Failed",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                })
        },
        uploadKey: async (data) => {
            dispatch({
                type: "LOADING",
                payload: true,
            })

            axios
                .post(
                    `${API_URL}/application/key/upload`,
                    data,
                    {
                        headers: {
                            Authorization: Cookies.get("token"),
                            apikey: API_KEY,
                            'Content-Type': 'multipart/form-data',
                        }
                    }
                )
                .then((res) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "success",
                            title: "Upload Successfully",
                            subtitle: "Your file have been uploaded successfully"
                        }
                    })
                })
                .catch((err) => {
                    dispatch({
                        type: "LOADING",
                        payload: false,
                    })
                    dispatch({
                        type: "SET_MESSAGE",
                        payload: {
                            type: "fail",
                            title: "Upload Failed",
                            subtitle: err.response && err.response.data && err.response.data.message ? err.response.data.message : "Please try again"
                        }
                    })
                })
        }
    }
}
