import React from 'react'
import { Link } from 'react-router-dom'
import { useStore } from '../../store/AppContext';

export const NavigationLink = ({ link, title, icon, lightModeIcon, setSection, sectionTitle, selectedIcon, disabled }) => {
    const { state } = useStore();
    const isSelected = sectionTitle === title;

    return (
        <Link to={!disabled && link} onClick={() => !disabled && setSection(title)} className={`flex p-4 mb-2 font-medium hover:bg-white-gray-700 hover:dark:bg-gray-medium rounded-lg transition-colors duration-200 ${disabled && 'cursor-default'} ${isSelected ? 'bg-white-gray-500 dark:bg-gray-black text-black dark:text-white' : 'text-gray dark:text-white'}`}>
            <img src={isSelected ? selectedIcon : (state.isDarkMode ? icon : lightModeIcon)} className='w-6 mr-2 left-0' alt="" />
            <p className={`ml-2 ${disabled && 'text-gray-light dark:text-gray-light cursor-default'}`}>
                {title}
            </p>
        </Link>
    )
}

export const NavigationButton = ({ handleClick, title, icon }) => {
    return (
        <div onClick={handleClick} className="text-gray dark:text-white font-medium flex p-4 mb-2 cursor-pointer hover:bg-white-gray-700 hover:dark:bg-gray-medium active:bg-gray-black  rounded-lg transition-colors duration-200">
            <img src={icon} className='w-6 mx-2' alt="" />
            <span className='ml-2'>
                {title}
            </span>
        </div>
    )
}
