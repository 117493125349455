import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Button } from '../Button';
import DropdownWrapper from '../DropdownWrapper';
import { useDocSearch } from '../../hooks/useDocSearch';
import { useStore } from '../../store/AppContext';

const PickFile = ({ parameters, loading, isOpen, onClose, onSubmit }) => {
    const { state, dispatch } = useStore();
    const docSearchHook = useDocSearch();

    const [file, setFile] = useState({});
    const [files, setFiles] = useState([]);
    const [totalFiles, setTotalFiles] = useState(0);
    const [pageSize, setpageSize] = useState(20);
    const [pageNum, setPageNum] = useState(1);

    useEffect(() => {
        const popupDiv = document.getElementById('workflow_pick_file_popup_div');
        if (isOpen) {
            popupDiv.style.display = 'flex';
        } else {
            setTimeout(() => {
                popupDiv.style.display = 'none';
            }, 500);
        }
    }, [isOpen])

    useEffect(() => {
        if (state.resourcesByUser && state.resourcesByUser.length > 0) {
            JSON.stringify(state.resourcesByUser)
            const mappedFiles = state.resourcesByUser
                .filter(f => f.integration === parameters.integration)
                .map(f => ({
                    id: f.fileName,
                    label: f.fileName,
                    path: f.filePath,
                    integration: f.integration,
                }));

            setFiles(mappedFiles);
        }
        console.log(parameters.integration)
    }, [state.resourcesByUser, parameters.integration]);

    useEffect(() => {
        if (!state.resourcesByUser || state.resourcesByUser.length === 0) {
            docSearchHook.getDocResourcesByUser();
        }
    }, [state.user])

    // useEffect(() => {
    //     let filesList = [];
    //     axios
    //         .get(`${API_URL}/web/documents/get?doc_type=docs&status=Completed&integration=${parameters.integration}&page=${pageNum}&page_size=${pageSize}`)
    //         .then((res) => {
    //             setTotalFiles(res.data.total_count ? res.data.total_count : 0);
    //             res.data && res.data.documents && res.data.documents.map(f => {
    //                 filesList.push({ id: f.filename, label: f.filename, path: f.file_path, integration: f.integration });
    //                 return f;
    //             })
    //             setFiles(filesList);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //         })
    // }, [pageNum, parameters])

    const onEnter = () => {
        parameters.file_path = file.path;
        onSubmit(parameters, 'file_path');
        setTimeout(() => {
            setFile({})
        }, 500);
    }

    const handleClose = () => {
        onClose();
        setFile({})
    }

    return (
        <div id={'workflow_pick_file_popup_div'} className={`absolute inset-0 z-[100] hidden items-center justify-center`}>
            <div className={`fixed inset-0 bg-white dark:bg-black ${isOpen ? 'popup-ease-in-opacity ' : 'popup-ease-out-opacity '} opacity-50`} />
            <div className={`${isOpen ? 'popup-ease-in ' : 'popup-ease-out '} relative bg-white-500 dark:bg-gray-dark w-2/3 border-2 border-white-gray-400 dark:border-gray drop-shadow-xl p-8 rounded-3xl z-10`}>
                <div className='flex flex-row justify-between'>
                    <p className='font-medium text-gray-dark dark:text-white pb-2'>Pick your file</p>
                    <button disabled={loading} className="flex text-gray-dark dark:text-white" onClick={handleClose} >
                        <FontAwesomeIcon className='w-6 h-6' icon={faClose} />
                    </button>
                </div>
                <div className='w-full relative'>
                    <DropdownWrapper value={file.id} setValue={setFile} placeholder={'Pick file'} options={files} name={'file'} dropdownClassName={'max-h-[210px]'} havePagination={true} pageNum={pageNum} pageSize={pageSize} setPageNum={setPageNum} total_count={totalFiles} />
                    <div className='flex w-full justify-end'>
                        <Button disabled={!file.path} loading={loading} className={"self-center mt-4 py-[8px] flex flex-row items-center justify-center content-center"} handleFunction={onEnter} name={"Next"} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PickFile;