import React from 'react';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router';
import { useStore } from '../store/AppContext';

const TableEmail = ({ data, columns, className }) => {
	const navigate = useNavigate();
	const { state } = useStore();

	const onLoadMail = (email) => {
		if (!state.isCoPilotStreaming || (state.isCoPilotStreaming && (localStorage.copilotEmail === JSON.stringify(email)))) {
			localStorage.copilotEmail = JSON.stringify(email);
			navigate("/service-pilot/email", { state: { copilotEmail: email } })
		}
	}

	return (
		<table className="min-w-full text-gray-dark-500 dark:text-white text-left text-sm font-light">
			<thead className="block border-b border-t font-medium dark:border-gray">
				<tr className='flex items-center px-2'>
					{columns.map((headerColumn, key) => (
						<th style={{ display: 'flex', flexDirection: 'row', flex: headerColumn.flex, textAlign: headerColumn.textAlign }} scope="col" className={`flex py-4 ${headerColumn.headerClassName}`} key={key}>{headerColumn.titleComponent ? headerColumn.titleComponent() : headerColumn.title}</th>
					))}
				</tr>
			</thead>
			<tbody className={`block w-full ${className ? className : 'content-container-with-top-bar'} overflow-y-auto`}>
				{data && data.map((data, key) => (
					<tr onClick={() => onLoadMail(data)} className={`w-full flex items-center border-b border-gray-light-700 dark:border-gray cursor-pointer hover:bg-white-gray-500 dark:hover:bg-gray-300`} key={key}>
						{columns.map((column, key1) => (
							<td style={{ flex: column.flex, textAlign: column.textAlign, paddingBottom: column.padding, paddingTop: column.padding }} className={`whitespace-nowrap p-3 ${data.status === 'unseen' ? "font-medium" : "font-normal"} ${(!state.isCoPilotStreaming || (state.isCoPilotStreaming && (localStorage.copilotEmail === JSON.stringify(data)))) ? "text-gray-dark dark:text-white cursor-pointer " : "text-gray-light dark:text-gray-light cursor-default "} overflow-y-auto`} key={key1}>
								{data.status === 'unseen' && column.id === "sender_name" ?
									<div className="flex flex-row items-center">
										<FontAwesomeIcon style={{ fontSize: "12px" }} className='mr-3 text-blue' icon={faCircle} />
										{data[column.id]}
									</div>
									:
									<div className={`${(key1 === 0) ? 'ml-6' : ''}`}>{data[column.id]} </div>
								}
							</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	)
}

export default TableEmail;
