import React, { useState } from 'react';
import { useStore } from '../../store/AppContext';
import { useIntegration } from '../../hooks/useIntegration';

const DEFAULT_LOGO = <svg width="60" height="61" viewBox="0 0 60 61" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M2.12719 46.091C1.50901 47.091 0.818098 48.2728 0.272643 49.2001C-0.254629 50.091 0.0362797 51.2364 0.909007 51.7819L13.2545 59.4001C14.1636 59.9637 15.3454 59.6728 15.8908 58.7637C16.3817 57.9273 17.0181 56.8546 17.709 55.7091C22.5999 47.6364 27.5272 48.6182 36.3999 52.8364L48.6545 58.6546C49.6181 59.1091 50.7817 58.691 51.1999 57.7091L57.0908 44.4182C57.509 43.4728 57.0908 42.3637 56.1636 41.9273C53.5817 40.7091 48.4363 38.291 43.8363 36.0546C27.0908 27.9637 12.9454 28.5091 2.12719 46.091Z" fill="url(#paint0_linear_3124_1646)" />
	<path d="M57.509 15.9091C58.1272 14.9091 58.8181 13.7273 59.3636 12.8001C59.8909 11.9091 59.5999 10.7637 58.7272 10.2182L46.3818 2.60005C45.4727 2.03642 44.2909 2.32733 43.7454 3.2546C43.2545 4.09096 42.6181 5.14551 41.9272 6.30914C37.0363 14.3819 32.109 13.4001 23.2363 9.18187L10.9818 3.34551C10.0181 2.89096 8.8545 3.30914 8.43631 4.29096L2.54541 17.5819C2.12722 18.5273 2.54541 19.6364 3.47268 20.0728C6.0545 21.291 11.1999 23.7091 15.7999 25.9455C32.5272 34.0364 46.6727 33.5091 57.509 15.9091Z" fill="url(#paint1_linear_3124_1646)" />
	<defs>
		<linearGradient id="paint0_linear_3124_1646" x1="56.7272" y1="63.3273" x2="19.3454" y2="41.8364" gradientUnits="userSpaceOnUse">
			<stop stopColor="#0055CC" />
			<stop offset="1" stopColor="#1D7AFC" />
		</linearGradient>
		<linearGradient id="paint1_linear_3124_1646" x1="2.90904" y1="-1.32722" x2="40.2909" y2="20.1637" gradientUnits="userSpaceOnUse">
			<stop stopColor="#0055CC" />
			<stop offset="1" stopColor="#1D7AFC" />
		</linearGradient>
	</defs>
</svg>;

const COMING_SOON_LOGO = <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M2.12719 45.1818C1.50901 46.1818 0.818098 47.3637 0.272643 48.2909C-0.254629 49.1818 0.0362797 50.3273 0.909007 50.8728L13.2545 58.4909C14.1636 59.0546 15.3454 58.7637 15.8908 57.8546C16.3817 57.0182 17.0181 55.9455 17.709 54.8C22.5999 46.7273 27.5272 47.7091 36.3999 51.9273L48.6545 57.7455C49.6181 58.2 50.7817 57.7818 51.1999 56.8L57.0908 43.5091C57.509 42.5637 57.0908 41.4546 56.1636 41.0182C53.5817 39.8 48.4363 37.3818 43.8363 35.1455C27.0908 27.0546 12.9454 27.6 2.12719 45.1818Z" fill="#B7C2D2" />
	<path d="M57.509 15C58.1272 14 58.8181 12.8181 59.3636 11.8909C59.8909 11 59.5999 9.85451 58.7272 9.30905L46.3818 1.69087C45.4727 1.12724 44.2909 1.41815 43.7454 2.34542C43.2545 3.18178 42.6181 4.23633 41.9272 5.39996C37.0363 13.4727 32.109 12.4909 23.2363 8.27269L10.9818 2.43633C10.0181 1.98178 8.8545 2.39996 8.43631 3.38178L2.54541 16.6727C2.12722 17.6181 2.54541 18.7272 3.47268 19.1636C6.0545 20.3818 11.1999 22.8 15.7999 25.0363C32.5272 33.1272 46.6727 32.6 57.509 15Z" fill="#B7C2D2" />
</svg>;

const Confluence = ({ comingSoon }) => {
	const integrationHook = useIntegration();
	const { state } = useStore();
	const [enableRevokeIcon, setEnableRevokeIcon] = useState(false);

	const handleRevoke = () => {
		integrationHook.revokeIntegrationData('confluence', setEnableRevokeIcon);
	}

	return (
		<div>
			<button disabled={comingSoon} className={`flex flex-col relative justify-center items-center w-[150px] h-[150px] rounded-xl px-2 py-5 gap-5 ${comingSoon ? 'bg-white-gray dark:bg-gray-light-200' : 'bg-white-gray-600 dark:bg-black'}`}>
				{state.config && state.config.integrations && state.config.integrations['confluence'] &&
					<div className='w-[150px] h-[150px] absolute flex justify-end items-start pr-2 pt-2'>
						<div className='dark:text-gray-dark text-white' onClick={() => enableRevokeIcon && handleRevoke()} onMouseOver={() => setEnableRevokeIcon(true)} onMouseLeave={() => setEnableRevokeIcon(false)}>
							{enableRevokeIcon ?
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="-4 4 48 48">
									<path fill="#e75670" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path>
									<path fill="currentColor" d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z"></path>
									<path fill="currentColor" d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z"></path>
								</svg>
								:
								<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<rect width="20" height="20" rx="10" fill="#2BB86C" />
									<path d="M15.0859 6.91406C15.2969 7.14844 15.2969 7.5 15.0859 7.71094L8.89844 13.8984C8.66406 14.1328 8.3125 14.1328 8.10156 13.8984L4.91406 10.7109C4.67969 10.5 4.67969 10.1484 4.91406 9.9375C5.125 9.70312 5.47656 9.70312 5.6875 9.9375L8.47656 12.7266L14.2891 6.91406C14.5 6.70312 14.8516 6.70312 15.0625 6.91406H15.0859Z" fill="currentColor" />
								</svg>
							}
						</div>
					</div>
				}
				{comingSoon ? COMING_SOON_LOGO : DEFAULT_LOGO}
				<div className='flex flex-col justify-center items-center'>
					<p className={`${comingSoon ? 'text-gray-light' : 'text-gray-dark dark:text-white'} font-medium`}>Confluence</p>
					{comingSoon && <p className='text-gray-dark dark:text-white text-xs font-normal mt-1'>Coming Soon</p>}
				</div>
			</button>
		</div>
	)
}

export default Confluence;
