import React, { useEffect } from 'react';

const ConfirmPopup = ({ id, isOpen, onClose, onConfirm, title, message, cancelLabel, confirmLabel }) => {
	const divId = id ? id : 'confirm_popup_div';

	useEffect(() => {
		const popupDiv = document.getElementById(divId);
		if (isOpen) {
			popupDiv.style.display = 'flex';
		} else {
			setTimeout(() => {
				popupDiv.style.display = 'none';
			}, 500);
		}
	}, [isOpen])

	const handleClose = () => {
		onClose();
	}

	return (
		<div id={divId} className={`fixed inset-0 z-[100] hidden items-center justify-center`}>
			<div className={`fixed inset-0 bg-black ${isOpen ? 'popup-ease-in-opacity ' : 'popup-ease-out-opacity '} opacity-50`} onClick={handleClose} />
			<div className={`${isOpen ? 'popup-ease-in ' : 'popup-ease-out '} bg-white-500 dark:bg-gray-dark border-2 border-white-gray-400 dark:border-gray max-w-[450px] drop-shadow-xl rounded-lg z-10`}>
				<div className='font-light my-6'>
					<h5 className='text-xl dark:text-white mt-4 px-12'>{title}</h5>
					<p className='text-gray-dark dark:text-white mt-4 px-12'>{message}</p>
					<div className="mt-4 w-full">
						<div className='flex gap-8 justify-center w-full p-2'>
							<button onClick={handleClose} className={`border border-gray font-bold px-6 py-2 rounded-lg dark:text-white hover:border-blue-dark hover:bg-blue`} >{cancelLabel || "Cancel"}</button>
							<button onClick={onConfirm} className={`border font-bold px-6 py-2 rounded-lg bg-blue border-blue text-white dark:text-white focus:outline-none hover:bg-blue-400 hover:border-blue-400`} >{confirmLabel || "Confirm"}</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default ConfirmPopup;