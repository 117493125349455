import React from 'react';
import { Route, Routes } from 'react-router-dom';
import EmailConfig from './EmailConfig';
import EmailCoPilot from './EmailCoPilot';
import SingleEmail from './SingleEmail';
import ManageDocuments from './ManageDocuments';

const CoPilotPage = () => {
	return (
		<Routes>
			<Route path='/config' element={<EmailConfig />} />
			<Route path='/app' element={<EmailCoPilot />} />
			<Route path='/email' element={<SingleEmail />} />
			<Route path='/manage-documents' element={<ManageDocuments />} />
		</Routes>
	)
}

export default CoPilotPage;
