import React, { useState } from 'react';
import { LamdaAuthenticate, LogNotifications, MSALAuthenticate, Picker, Popup } from "onedrive-picker-file";
import { PublicClientApplication } from "@azure/msal-browser";
import { useStore } from '../../store/AppContext';
import { useIntegration } from '../../hooks/useIntegration';
import { INTEGRATION_CREDENTIALS } from '../../config';

const DEFAULT_LOGO = <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M22.8796 21.6742L22.8802 21.6722L35.4758 29.2168L42.9813 26.0583C44.5064 25.399 46.151 25.0609 47.8125 25.0625C48.0893 25.0625 48.363 25.0751 48.6352 25.0932C47.7329 21.5746 45.8279 18.3939 43.1519 15.9375C40.476 13.4811 37.1441 11.8548 33.5613 11.2563C29.9785 10.6578 26.2989 11.1128 22.9698 12.566C19.6407 14.0192 16.8054 16.4081 14.8083 19.4423C14.8725 19.4415 14.9357 19.4375 15 19.4375C17.7837 19.4337 20.513 20.2085 22.8796 21.6742Z" fill="#0364B8" />
	<path d="M22.8802 21.6722L22.8796 21.6742C20.513 20.2085 17.7837 19.4337 15 19.4375C14.9357 19.4375 14.8724 19.4415 14.8083 19.4424C12.0838 19.4761 9.41989 20.2516 7.10298 21.6856C4.78608 23.1196 2.90372 25.1578 1.6582 27.5812C0.412671 30.0047 -0.148932 32.7217 0.0337371 35.4403C0.216406 38.159 1.13644 40.7764 2.69497 43.0115L13.8025 38.3372L18.7401 36.2594L29.7343 31.6329L35.4757 29.2168L22.8802 21.6722Z" fill="#0078D4" />
	<path d="M48.6353 25.0932C48.363 25.0751 48.0894 25.0625 47.8126 25.0625C46.151 25.0609 44.5068 25.4002 42.9817 26.0596L35.4758 29.2168L37.6523 30.5204L44.7865 34.7938L47.8992 36.6582L58.5423 43.0333C59.5094 41.2381 60.0105 39.2288 59.9999 37.1896C59.9892 35.1505 59.4671 33.1466 58.4813 31.3615C57.4956 29.5764 56.0776 28.0672 54.3575 26.9721C52.6373 25.877 50.6698 25.2309 48.6353 25.0932Z" fill="#1490DF" />
	<path d="M47.8992 36.6582L44.7865 34.7938L37.6523 30.5204L35.4758 29.2168L29.7344 31.6329L18.7402 36.2594L13.8026 38.3372L2.69507 43.0115C4.07538 44.996 5.91547 46.617 8.05823 47.736C10.201 48.855 12.5827 49.4388 15.0001 49.4375H47.8126C50.0126 49.4382 52.1718 48.8431 54.0609 47.7156C55.95 46.5881 57.4986 44.9701 58.5423 43.0333L47.8992 36.6582Z" fill="#28A8EA" />
</svg>;

const COMING_SOON_LOGO = <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M22.8796 21.6742L22.8802 21.6722L35.4758 29.2168L42.9813 26.0583C44.5064 25.399 46.151 25.0609 47.8125 25.0625C48.0893 25.0625 48.363 25.0751 48.6352 25.0932C47.7329 21.5746 45.8279 18.3939 43.1519 15.9375C40.476 13.4811 37.1441 11.8548 33.5613 11.2563C29.9785 10.6578 26.2989 11.1128 22.9698 12.566C19.6407 14.0192 16.8054 16.4081 14.8083 19.4423C14.8725 19.4415 14.9357 19.4375 15 19.4375C17.7837 19.4337 20.513 20.2085 22.8796 21.6742Z" fill="#576474" />
	<path d="M22.8802 21.6722L22.8796 21.6742C20.513 20.2085 17.7837 19.4337 15 19.4375C14.9357 19.4375 14.8724 19.4415 14.8083 19.4424C12.0838 19.4761 9.41989 20.2516 7.10298 21.6856C4.78608 23.1196 2.90372 25.1578 1.6582 27.5812C0.412671 30.0047 -0.148932 32.7217 0.0337371 35.4403C0.216406 38.159 1.13644 40.7764 2.69497 43.0115L13.8025 38.3372L18.7401 36.2594L29.7343 31.6329L35.4757 29.2168L22.8802 21.6722Z" fill="#B7C2D2" />
	<path d="M48.6353 25.0932C48.363 25.0751 48.0894 25.0625 47.8126 25.0625C46.151 25.0609 44.5068 25.4002 42.9817 26.0596L35.4758 29.2168L37.6523 30.5204L44.7865 34.7938L47.8992 36.6582L58.5423 43.0333C59.5094 41.2381 60.0105 39.2288 59.9999 37.1896C59.9892 35.1505 59.4671 33.1466 58.4813 31.3615C57.4956 29.5764 56.0776 28.0672 54.3575 26.9721C52.6373 25.877 50.6698 25.2309 48.6353 25.0932Z" fill="#B7C2D2" />
	<path d="M47.8992 36.6582L44.7865 34.7938L37.6523 30.5204L35.4758 29.2168L29.7344 31.6329L18.7402 36.2594L13.8026 38.3372L2.69507 43.0115C4.07538 44.996 5.91547 46.617 8.05823 47.736C10.201 48.855 12.5827 49.4388 15.0001 49.4375H47.8126C50.0126 49.4382 52.1718 48.8431 54.0609 47.7156C55.95 46.5881 57.4986 44.9701 58.5423 43.0333L47.8992 36.6582Z" fill="white" />
</svg>;

const OneDrive = ({ appId, comingSoon }) => {
	const integrationHook = useIntegration();
	const { state } = useStore();
	const [enableRevokeIcon, setEnableRevokeIcon] = useState(false);

	const msalParams = {
		auth: {
			authority: "https://login.microsoftonline.com/consumers",
			clientId: INTEGRATION_CREDENTIALS.onedrive.clientId,
			redirectUri: INTEGRATION_CREDENTIALS.onedrive.redirectURL
		},
	}

	let accessToken = ""

	const onClick = async () => {
		const pickerInitParams = {
			sdk: "8.0",
			entry: {
				oneDrive: {
					files: {},
				}
			},
			authentication: {
				enabled: true,
				tokens: {
					graph: true,
					sharePoint: true,
					substrate: true
				}
			},
			messaging: {
				origin: window.origin,
				channelId: "27"
			},
			typesAndSources: {
				mode: "files",
				filters: [],
				pivots: {
					oneDrive: true,
					recent: true,
				},
			},
			selection: {
				mode: 'multiple'
			}
		};

		const picker = Picker(window.open("", "Picker", "width=800,height=600")).using(
			Popup(),
			LogNotifications(),
			LamdaAuthenticate(getToken),
			MSALAuthenticate(msalParams, ["OneDrive.ReadWrite", "openid", "profile", "offline_access"])
		);

		picker.on.error(function (err) {
			this.log(`error: ${err}`);
		});

		const pickedItems = await picker.activate({
			baseUrl: "https://onedrive.live.com/picker",
			options: pickerInitParams,
		});

		if (!pickedItems || !pickedItems.items || pickedItems.items.length < 1) {
			console.log("No files picked");
			return;
		}

		let file_ids = [];
		pickedItems.items.forEach(file => {
			file_ids.push({
				name: file.name,
				file_id: file.id,
				drive_id: file.parentReference ? file.parentReference.driveId : null
			});
		})
		const req = {
			appId: appId,
			files: file_ids
		}

		integrationHook.storeIntegrationData('onedrive', accessToken, req);
	}

	async function getToken() {
		const config = state.integrations['mondaydotcom'];

		if (config.accessToken) {
			accessToken = config.accessToken;
		} else {
			const app = new PublicClientApplication(msalParams);
			const authParams = { scopes: ["OneDrive.ReadWrite"] }
			try {
				await app.initialize();
				const resp = await app.acquireTokenSilent(authParams);
				accessToken = resp.accessToken;
				updateConfig(accessToken);
			} catch (e) {
				console.log("ERROR : ", e)
			}
		}
		
		return accessToken;
	}

	const updateConfig = (accessToken) => {
		if (state.integrations && !state.integrations['onedrive']) {
			let integrations = {
				...state.integrations,
				onedrive: {
					clientId: "868e6d85-421e-44d0-a449-fcd5e9175107",
					accessToken: accessToken
				}
			}

			integrationHook.updateIntegration(integrations);
		}
	}

	const handleRevoke = () => {
		const req = {
			hasRevokeAPI: false,
			integration: 'onedrive',
		}
		integrationHook.revokeIntegrationData(req, setEnableRevokeIcon);
	}

	return (
		<div>
			<button disabled={comingSoon} onClick={() => !enableRevokeIcon && onClick()} className={`flex flex-col relative justify-center items-center w-[150px] h-[150px] rounded-xl px-2 py-5 gap-5 ${comingSoon ? 'bg-white-gray dark:bg-gray-light-200' : 'bg-white-gray-600 dark:bg-black'}`}>
				{state.integrations && state.integrations['onedrive'] &&
					<div className='w-[150px] h-[150px] absolute flex justify-end items-start pr-2 pt-2'>
						<div className='dark:text-gray-dark text-white' onClick={() => enableRevokeIcon && handleRevoke()} onMouseOver={() => setEnableRevokeIcon(true)} onMouseLeave={() => setEnableRevokeIcon(false)}>
							{enableRevokeIcon ?
								<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="-4 4 48 48">
									<path fill="#e75670" d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path>
									<path fill="currentColor" d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z"></path>
									<path fill="currentColor" d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z"></path>
								</svg>
								:
								<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
									<rect width="20" height="20" rx="10" fill="#2BB86C" />
									<path d="M15.0859 6.91406C15.2969 7.14844 15.2969 7.5 15.0859 7.71094L8.89844 13.8984C8.66406 14.1328 8.3125 14.1328 8.10156 13.8984L4.91406 10.7109C4.67969 10.5 4.67969 10.1484 4.91406 9.9375C5.125 9.70312 5.47656 9.70312 5.6875 9.9375L8.47656 12.7266L14.2891 6.91406C14.5 6.70312 14.8516 6.70312 15.0625 6.91406H15.0859Z" fill="currentColor" />
								</svg>
							}
						</div>
					</div>
				}
				{comingSoon ? COMING_SOON_LOGO : DEFAULT_LOGO}
				<div className='flex flex-col justify-center items-center'>
					<p className={`${comingSoon ? 'text-gray-light' : 'text-gray-dark dark:text-white'} font-medium`}>OneDrive</p>
					{comingSoon && <p className='text-gray-dark dark:text-white text-xs font-normal mt-1'>Coming Soon</p>}
				</div>
			</button>
		</div>
	)
}

export default OneDrive;
